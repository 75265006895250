import axios from "axios";
import authHeader from "@/Api/AuthHeader";


// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createCompany(data) {
      try {

        let formData = new FormData();
        formData.append('company_name', data.company_name);
        formData.append('company_email', data.company_email);
        formData.append('company_contact', data.company_contact);
        formData.append('company_optional_contact', data.company_optional_contact);
        formData.append('nuit', data.nuit);
        formData.append('company_address', data.company_address);
        formData.append('district_id', data.district_id);
        formData.append('is_teacher', data.is_teacher);
        formData.append('doc_file', data.doc_file);



        return axios.post(URL_API+"company", formData, { headers: authHeader() });
      } catch (error) {
        console.log(error);
      }
    },

    createCompanyInscription(data) {
      try {

        let formData = new FormData();
        formData.append('company_name', data.company_name);
        formData.append('company_email', data.company_email);
        formData.append('company_contact', data.company_contact);
        formData.append('company_optional_contact', data.company_optional_contact);
        formData.append('nuit', data.nuit);
        formData.append('company_address', data.company_address);
        formData.append('district_id', data.district_id);
        formData.append('is_teacher', data.is_teacher);
        formData.append('doc_file', data.doc_file);



        return axios.post(URL_API+"createCompanyInscription", formData);
      } catch (error) {
        console.log(error);
      }
    },


    getAllCompany() {
      try {
        return axios.get(URL_API+"company", { headers: authHeader() });
      } catch (error) {
        console.log(error);
      }
    },
    show(id) {
      try {
        return axios.get(URL_API+`company/${id}`, { headers: authHeader() });
      } catch (error) {
        console.log(error);
      }
    },

    requestVaga(data, email, name) {
      try {
        return axios.post(URL_API+"requestVaga", {title:data.title, numberOfTrain:data.numberOfTrain, course_name:data.course_name, message:data.message, email:email,name:name}, {
          headers: authHeader(),
        });
      } catch (error) {
        console.log(error);
      }
    },
    disableUser(id) {
      try {
        return axios.get(URL_API+`disableUser/${id}`, { headers: authHeader() });
      } catch (error) {}
    },
    activeUser(id) {
      try {
        return axios.get(URL_API+`activeUser/${id}`, { headers: authHeader() });
      } catch (error) {}
    },

    districtsByProvinceId(id) {
      try {
        return axios.get(URL_API+`district/${id}`, { headers: authHeader() });
      } catch (error) {
        return error;
      }
    },

    approve_company(company_id){
      try {
        return axios.get(URL_API+`approve_company/${company_id}`, {headers:authHeader()});
      } catch (error) {
        console.log(error);
      }
    },
    unapprove_company(company_id){
      try {
        return axios.get(URL_API+`unapprove_company/${company_id}`, {headers:authHeader()});
      } catch (error) {
        console.log(error);
      }
    },

    updateCompany(data) {
      try {
        return axios.post(URL_API+"updateCompany", data, {
          headers: authHeader(),
        });
      } catch (error) {
        return error;
      }
    },
    removeCompany(id) {
      try {
        return axios.get(URL_API+`remove/${id}`, { headers: authHeader() });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
