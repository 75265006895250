<template>
  <div class="left side-menu">
    <div class="slimscroll-menu" id="remove-scroll">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu" id="side-menu">
          <li class="menu-title">Menu</li>
          <li>
            <a href="/dashboard" class="waves-effect">
              <i class="icon-accelerator"></i
              ><span class="badge badge-success badge-pill float-right"></span>
              <span> Dashboard </span>
            </a>
          </li>

          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="mdi mdi-calendar"></i
              ><span>
                Actividades
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li>
                <router-link to="/add-activities">Adicionar </router-link>
              </li>
              <li>
                <router-link to="/all-activities"
                  >Todas Actividades</router-link
                >
              </li>
            </ul>
          </li>

            <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-book"></i
              ><span>
                Cursos
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/add-course">Adicionar</router-link></li>
              <li><router-link to="/all-courses">Listar</router-link></li>
            </ul>
          </li>

          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="mdi mdi-chair-school"></i
              ><span>
                Turmas
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/classmates">Adicionar</router-link></li>
              <li><router-link to="/all-classmates">Listar</router-link></li>
            </ul>
          </li>

        

          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-chalkboard-teacher"></i>
              <span>
                Professores
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span>
              </span>
            </a>
            <ul class="submenu">
              <li><router-link to="/teachers">Adicionar</router-link></li>
              <li><router-link to="/all-teachers">Listar</router-link></li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-graduation-cap"></i
              ><span>
                Estudantes
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/students">Adicionar</router-link></li>
              <li><router-link to="/all-students">Listar</router-link></li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-city"></i
              ><span>
                Empresas
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/add-company">Adicionar</router-link></li>
              <li><router-link to="/allcompany">Listar</router-link></li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fa fa-user"></i
              ><span>
                Supervisor
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/newsupervisor">Adicionar </router-link></li>
              <li>
                <router-link to="/all-supervisors"
                  >Todos Supervisores</router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fa fa-briefcase"></i
              ><span>
                Vagas
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/add-job">Adicionar</router-link></li>
              <li><router-link to="/alljobs">Todas Vagas</router-link></li>
              <li>
                <router-link to="/newcandidates">Candidaturas</router-link>
              </li>
              <li>
                <router-link to="/showjobs">Minhas Vagas</router-link>
              </li>
               <li>
                <router-link to="/requestTrain">Pedidos de Vagas</router-link>
              </li>
            </ul>
          </li>

          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-wallet"></i
              ><span>
                Estagiarios
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li>
                <router-link to="/all-trainess">Todos Estagiarios</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
</template>

<script>
export default {};
</script>
