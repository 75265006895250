<template>
  <div class="row" v-show="secondInfoShow">
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Selecione o tipo documento*</label>
        <select class="form-control" v-model="form.doc_type">
          <option value="">Selecione o tipo documento</option>
          <option value="Bilhete de Identidade">Bilhete de Identidade</option>
        </select>
      </div>
      <p v-if="doc_message_error" style="color: red">
        {{ doc_message_error }}
      </p>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Numero do doc*</label>
        <input
          type="text"
          class="form-control"
          v-model="form.doc_number"
                maxlength="13"
          @input="validateIdentificationNumber"
        />
      </div>
      <p v-if="doc_number_message_error" style="color: red">
        {{ doc_number_message_error }}
      </p>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Nacionalidade*</label>
        <select v-model="form.nactionality" class="form-control">
          <option value="Afghanistan">Afghanistan</option>
          <option value="Åland Islands">Åland Islands</option>
          <option value="Albania">Albania</option>
          <option value="Algeria">Algeria</option>
          <option value="American Samoa">American Samoa</option>
          <option value="Andorra">Andorra</option>
          <option value="Angola">Angola</option>
          <option value="Anguilla">Anguilla</option>
          <option value="Antarctica">Antarctica</option>
          <option value="Antigua and Barbuda">Antigua and Barbuda</option>
          <option value="Argentina">Argentina</option>
          <option value="Armenia">Armenia</option>
          <option value="Aruba">Aruba</option>
          <option value="Australia">Australia</option>
          <option value="Austria">Austria</option>
          <option value="Azerbaijan">Azerbaijan</option>
          <option value="Bahamas">Bahamas</option>
          <option value="Bahrain">Bahrain</option>
          <option value="Bangladesh">Bangladesh</option>
          <option value="Barbados">Barbados</option>
          <option value="Belarus">Belarus</option>
          <option value="Belgium">Belgium</option>
          <option value="Belize">Belize</option>
          <option value="Benin">Benin</option>
          <option value="Bermuda">Bermuda</option>
          <option value="Bhutan">Bhutan</option>
          <option value="Bolivia">Bolivia</option>
          <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
          <option value="Botswana">Botswana</option>
          <option value="Bouvet Island">Bouvet Island</option>
          <option value="Brazil">Brazil</option>
          <option value="British Indian Ocean Territory">
            British Indian Ocean Territory
          </option>
          <option value="Brunei Darussalam">Brunei Darussalam</option>
          <option value="Bulgaria">Bulgaria</option>
          <option value="Burkina Faso">Burkina Faso</option>
          <option value="Burundi">Burundi</option>
          <option value="Cambodia">Cambodia</option>
          <option value="Cameroon">Cameroon</option>
          <option value="Canada">Canada</option>
          <option value="Cape Verde">Cape Verde</option>
          <option value="Cayman Islands">Cayman Islands</option>
          <option value="Central African Republic">
            Central African Republic
          </option>
          <option value="Chad">Chad</option>
          <option value="Chile">Chile</option>
          <option value="China">China</option>
          <option value="Christmas Island">Christmas Island</option>
          <option value="Cocos (Keeling) Islands">
            Cocos (Keeling) Islands
          </option>
          <option value="Colombia">Colombia</option>
          <option value="Comoros">Comoros</option>
          <option value="Congo">Congo</option>
          <option value="Congo, The Democratic Republic of The">
            Congo, The Democratic Republic of The
          </option>
          <option value="Cook Islands">Cook Islands</option>
          <option value="Costa Rica">Costa Rica</option>
          <option value="Cote D'ivoire">Cote D'ivoire</option>
          <option value="Croatia">Croatia</option>
          <option value="Cuba">Cuba</option>
          <option value="Cyprus">Cyprus</option>
          <option value="Czech Republic">Czech Republic</option>
          <option value="Denmark">Denmark</option>
          <option value="Djibouti">Djibouti</option>
          <option value="Dominica">Dominica</option>
          <option value="Dominican Republic">Dominican Republic</option>
          <option value="Ecuador">Ecuador</option>
          <option value="Egypt">Egypt</option>
          <option value="El Salvador">El Salvador</option>
          <option value="Equatorial Guinea">Equatorial Guinea</option>
          <option value="Eritrea">Eritrea</option>
          <option value="Estonia">Estonia</option>
          <option value="Ethiopia">Ethiopia</option>
          <option value="Falkland Islands (Malvinas)">
            Falkland Islands (Malvinas)
          </option>
          <option value="Faroe Islands">Faroe Islands</option>
          <option value="Fiji">Fiji</option>
          <option value="Finland">Finland</option>
          <option value="France">France</option>
          <option value="French Guiana">French Guiana</option>
          <option value="French Polynesia">French Polynesia</option>
          <option value="French Southern Territories">
            French Southern Territories
          </option>
          <option value="Gabon">Gabon</option>
          <option value="Gambia">Gambia</option>
          <option value="Georgia">Georgia</option>
          <option value="Germany">Germany</option>
          <option value="Ghana">Ghana</option>
          <option value="Gibraltar">Gibraltar</option>
          <option value="Greece">Greece</option>
          <option value="Greenland">Greenland</option>
          <option value="Grenada">Grenada</option>
          <option value="Guadeloupe">Guadeloupe</option>
          <option value="Guam">Guam</option>
          <option value="Guatemala">Guatemala</option>
          <option value="Guernsey">Guernsey</option>
          <option value="Guinea">Guinea</option>
          <option value="Guinea-bissau">Guinea-bissau</option>
          <option value="Guyana">Guyana</option>
          <option value="Haiti">Haiti</option>
          <option value="Heard Island and Mcdonald Islands">
            Heard Island and Mcdonald Islands
          </option>
          <option value="Holy See (Vatican City State)">
            Holy See (Vatican City State)
          </option>
          <option value="Honduras">Honduras</option>
          <option value="Hong Kong">Hong Kong</option>
          <option value="Hungary">Hungary</option>
          <option value="Iceland">Iceland</option>
          <option value="India">India</option>
          <option value="Indonesia">Indonesia</option>
          <option value="Iran, Islamic Republic of">
            Iran, Islamic Republic of
          </option>
          <option value="Iraq">Iraq</option>
          <option value="Ireland">Ireland</option>
          <option value="Isle of Man">Isle of Man</option>
          <option value="Israel">Israel</option>
          <option value="Italy">Italy</option>
          <option value="Jamaica">Jamaica</option>
          <option value="Japan">Japan</option>
          <option value="Jersey">Jersey</option>
          <option value="Jordan">Jordan</option>
          <option value="Kazakhstan">Kazakhstan</option>
          <option value="Kenya">Kenya</option>
          <option value="Kiribati">Kiribati</option>
          <option value="Korea, Democratic People's Republic of">
            Korea, Democratic People's Republic of
          </option>
          <option value="Korea, Republic of">Korea, Republic of</option>
          <option value="Kuwait">Kuwait</option>
          <option value="Kyrgyzstan">Kyrgyzstan</option>
          <option value="Lao People's Democratic Republic">
            Lao People's Democratic Republic
          </option>
          <option value="Latvia">Latvia</option>
          <option value="Lebanon">Lebanon</option>
          <option value="Lesotho">Lesotho</option>
          <option value="Liberia">Liberia</option>
          <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
          <option value="Liechtenstein">Liechtenstein</option>
          <option value="Lithuania">Lithuania</option>
          <option value="Luxembourg">Luxembourg</option>
          <option value="Macao">Macao</option>
          <option value="Macedonia, The Former Yugoslav Republic of">
            Macedonia, The Former Yugoslav Republic of
          </option>
          <option value="Madagascar">Madagascar</option>
          <option value="Malawi">Malawi</option>
          <option value="Malaysia">Malaysia</option>
          <option value="Maldives">Maldives</option>
          <option value="Mali">Mali</option>
          <option value="Malta">Malta</option>
          <option value="Marshall Islands">Marshall Islands</option>
          <option value="Martinique">Martinique</option>
          <option value="Mauritania">Mauritania</option>
          <option value="Mauritius">Mauritius</option>
          <option value="Mayotte">Mayotte</option>
          <option value="Mexico">Mexico</option>
          <option value="Micronesia, Federated States of">
            Micronesia, Federated States of
          </option>
          <option value="Moldova, Republic of">Moldova, Republic of</option>
          <option value="Monaco">Monaco</option>
          <option value="Mongolia">Mongolia</option>
          <option value="Montenegro">Montenegro</option>
          <option value="Montserrat">Montserrat</option>
          <option value="Morocco">Morocco</option>
          <option value="Mozambican" selected>Mo&ccedil;ambicana</option>
          <option value="Myanmar">Myanmar</option>
          <option value="Namibia">Namibia</option>
          <option value="Nauru">Nauru</option>
          <option value="Nepal">Nepal</option>
          <option value="Netherlands">Netherlands</option>
          <option value="Netherlands Antilles">Netherlands Antilles</option>
          <option value="New Caledonia">New Caledonia</option>
          <option value="New Zealand">New Zealand</option>
          <option value="Nicaragua">Nicaragua</option>
          <option value="Niger">Niger</option>
          <option value="Nigeria">Nigeria</option>
          <option value="Niue">Niue</option>
          <option value="Norfolk Island">Norfolk Island</option>
          <option value="Northern Mariana Islands">
            Northern Mariana Islands
          </option>
          <option value="Norway">Norway</option>
          <option value="Oman">Oman</option>
          <option value="Pakistan">Pakistan</option>
          <option value="Palau">Palau</option>
          <option value="Palestinian Territory, Occupied">
            Palestinian Territory, Occupied
          </option>
          <option value="Panama">Panama</option>
          <option value="Papua New Guinea">Papua New Guinea</option>
          <option value="Paraguay">Paraguay</option>
          <option value="Peru">Peru</option>
          <option value="Philippines">Philippines</option>
          <option value="Pitcairn">Pitcairn</option>
          <option value="Poland">Poland</option>
          <option value="Portugal">Portugal</option>
          <option value="Puerto Rico">Puerto Rico</option>
          <option value="Qatar">Qatar</option>
          <option value="Reunion">Reunion</option>
          <option value="Romania">Romania</option>
          <option value="Russian Federation">Russian Federation</option>
          <option value="Rwanda">Rwanda</option>
          <option value="Saint Helena">Saint Helena</option>
          <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
          <option value="Saint Lucia">Saint Lucia</option>
          <option value="Saint Pierre and Miquelon">
            Saint Pierre and Miquelon
          </option>
          <option value="Saint Vincent and The Grenadines">
            Saint Vincent and The Grenadines
          </option>
          <option value="Samoa">Samoa</option>
          <option value="San Marino">San Marino</option>
          <option value="Sao Tome and Principe">Sao Tome and Principe</option>
          <option value="Saudi Arabia">Saudi Arabia</option>
          <option value="Senegal">Senegal</option>
          <option value="Serbia">Serbia</option>
          <option value="Seychelles">Seychelles</option>
          <option value="Sierra Leone">Sierra Leone</option>
          <option value="Singapore">Singapore</option>
          <option value="Slovakia">Slovakia</option>
          <option value="Slovenia">Slovenia</option>
          <option value="Solomon Islands">Solomon Islands</option>
          <option value="Somalia">Somalia</option>
          <option value="South Africa">South Africa</option>
          <option value="South Georgia and The South Sandwich Islands">
            South Georgia and The South Sandwich Islands
          </option>
          <option value="Spain">Spain</option>
          <option value="Sri Lanka">Sri Lanka</option>
          <option value="Sudan">Sudan</option>
          <option value="Suriname">Suriname</option>
          <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
          <option value="Swaziland">Swaziland</option>
          <option value="Sweden">Sweden</option>
          <option value="Switzerland">Switzerland</option>
          <option value="Syrian Arab Republic">Syrian Arab Republic</option>
          <option value="Taiwan">Taiwan</option>
          <option value="Tajikistan">Tajikistan</option>
          <option value="Tanzania, United Republic of">
            Tanzania, United Republic of
          </option>
          <option value="Thailand">Thailand</option>
          <option value="Timor-leste">Timor-leste</option>
          <option value="Togo">Togo</option>
          <option value="Tokelau">Tokelau</option>
          <option value="Tonga">Tonga</option>
          <option value="Trinidad and Tobago">Trinidad and Tobago</option>
          <option value="Tunisia">Tunisia</option>
          <option value="Turkey">Turkey</option>
          <option value="Turkmenistan">Turkmenistan</option>
          <option value="Turks and Caicos Islands">
            Turks and Caicos Islands
          </option>
          <option value="Tuvalu">Tuvalu</option>
          <option value="Uganda">Uganda</option>
          <option value="Ukraine">Ukraine</option>
          <option value="United Arab Emirates">United Arab Emirates</option>
          <option value="United Kingdom">United Kingdom</option>
          <option value="United States">United States</option>
          <option value="United States Minor Outlying Islands">
            United States Minor Outlying Islands
          </option>
          <option value="Uruguay">Uruguay</option>
          <option value="Uzbekistan">Uzbekistan</option>
          <option value="Vanuatu">Vanuatu</option>
          <option value="Venezuela">Venezuela</option>
          <option value="Viet Nam">Viet Nam</option>
          <option value="Virgin Islands, British">
            Virgin Islands, British
          </option>
          <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
          <option value="Wallis and Futuna">Wallis and Futuna</option>
          <option value="Western Sahara">Western Sahara</option>
          <option value="Yemen">Yemen</option>
          <option value="Zambia">Zambia</option>
          <option value="Zimbabwe">Zimbabwe</option>
        </select>
      </div>
      <p v-if="country_message_error" style="color: red">
        {{ country_message_error }}
      </p>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Selecione o Provincia*</label>
        <select
          class="form-control"
          v-model="province_id"
          @change="ChangeProvince"
        >
          <option value="Class">Selecione o Provincia</option>
          <option :value="p.id" v-for="(p, index) in provinces" :key="index">
            {{ p.province_name }}
          </option>
        </select>
      </div>
      <p v-if="province_message_error" style="color: red">
        {{ province_message_error }}
      </p>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Selecione o Distrito*</label>
        <select class="form-control" v-model="form.district_id">
          <option value="Class">Selecione o Distrito</option>
          <option :value="d.id" v-for="(d, index) in districtData" :key="index">
            {{ d.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Endereco*</label>
        <input
          type="text"
          class="form-control"
          v-model="form.address_location"
        />
      </div>
      <p v-if="address_message_error" style="color: red">
        {{ address_message_error }}
      </p>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12">
      <button
        type="button"
        @click="nextStep"
        class="btn btn-primary float-right"
      >
        Proximo
      </button>
      <button type="button" class="btn btn-light" @click="prevStep">
        Voltar
      </button>
    </div>
  </div>
</template>

<script setup>
import StudentModuleService from "@/pages/students/StudentModuleService";
import axios from "axios";
import toastr from "toastr";
import { ref, defineProps, defineEmits, onMounted } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import router from "@/router";

const props = defineProps(["secondInfoShow"]);
const emits = defineEmits(["nextDocData", "nextDocPrev"]);

const route = useRoute();

const birthDate_message_error = ref("");
const district_message_error = ref("");
const address_message_error = ref("");
const doc_message_error = ref("");
const gender_message_error = ref("");
const isDeficient_message_error = ref("");
const father_name_message_error = ref("");
const mother_name_message_error = ref("");
const country_message_error = ref("");
const province_message_error = ref("");
const fullname_message_error = ref("");
const nationality_message_error = ref("");
const endereco_message_error = ref("");
const number_message_error = ref("");
const doc_number_message_error = ref("");

const form = ref({
  address_location: "",
  doc_type: "",
  doc_number: "",
  nactionality: "",
  district_id: "",
});

const provinces = ref([]);
const province_id = ref();
const districtData = ref([]);

async function getStudent() {
  const student = await StudentModuleService.actions.viewEstudante(
    route.params.id
  );
  form.value.address_location = student.data.dados_pessoais.address_location;
  form.value.doc_number = student.data.dados_pessoais.doc_number;
  form.value.nactionality = student.data.dados_pessoais.nactionality;
  form.value.district_id = student.data.dados_pessoais.district_id;

  province_id.value = student.data.district.province_id;
  getDisitrictbyId(province_id.value);
  console.log(student.data.district.province_id);
}

function nextStep() {
  if (form.value.doc_type == "") {
    doc_message_error.value = "O campo tipo de documeno não pode estar vazio!";
  }

  if (form.value.doc_number == "") {
    doc_number_message_error.value =
      "O campo numero do documeno não pode estar vazio!";
  }

  if (form.value.nactionality == "") {
    nationality_message_error.value =
      "O campo naciolidade não pode estar vazio!";
  }

  if (form.value.province_id == "") {
    province_message_error.value = "O campo provincia  não pode estar vazio!";
  }

  if (form.value.doc_number == "") {
    endereco_message_error.value = "O campo endereco não pode estar vazio!";
  }

  emits("nextDocData", form.value);
}

function prevStep() {
  emits("nextDocPrev");
}

async function getProvinces() {
  const resp = await StudentModuleService.actions.getProvinces();
  provinces.value = resp.data;
  console.log(resp.data);
}

async function getDisitrictbyId(province_id) {
  await axios
    .get("/api/district/" + province_id)
    .then((response) => {
      districtData.value = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

async function ChangeProvince(evt) {
  await axios
    .get("/api/district/" + evt.target.value)
    .then((response) => {
      districtData.value = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

function validateIdentificationNumber() {
  const regex = /^[0-9]{12}[a-zA-Z]$/;

  // Testar a expressão regular
  if (!regex.test(form.value.doc_number)) {
    doc_number_message_error.value = "Número de identificação inválido!";

    return;
  } else {
    doc_number_message_error.value = "";
  }
}
 

onMounted(() => {
  getProvinces();

  if (store.state.student.student.dados_pessoais != undefined) {
    // form.value = store.state.student.student.dados_pessoais;
    if (route.params.id !=undefined) {
      getStudent();
      
    }
    getDisitrictbyId(store.state.student.student.district.province_id);
    province_id.value = store.state.student.student.district.province_id;
  }
});
</script>