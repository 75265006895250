<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Jassa</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="card-title">Lista de Cursos</h5>
            </div>

            <div class="col-sm-6">
              <router-link to="/add-course" class="btn btn-primary float-right">
                + Adicionar Curso</router-link
              >
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
                v-model="search"
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table
              id="example5"
              class="table table-hover"
              style="min-width: 845px"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Curso</th>
                  <th>Descrição Curso</th>
                  <th>Açcões</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(c, index) in course" :key="index">
                  <td>{{ 1 + index }}</td>
                  <td>{{ c.name }}</td>
                  <td>{{ c.description }}</td>
                  <td>
                    <router-link
                      :to="{ name: 'editCourse', params: { id_course: c.id } }"
                      class="btn btn-sm btn-primary"
                      ><i class="fas fa-edit"></i
                    ></router-link>&nbsp;
                    <a
                      @click="isdeleteCourse(c.id, index)"
                      href="javascript:void(0);"
                      class="btn btn-sm btn-danger"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
                <tr>
                  <td class="text-center" colspan="4" v-if="course.length == 0">
                    <span style="font-style:italic; text-align:center">Ainda não tem registros</span>
                  </td>
                </tr>
              </tbody>
            </table>
               <Page :total="totalCourses" :model-value="currentPage"  :page-size="parseInt(perPage)" @on-change="getAllCourse" v-if="totalCourses"   class="float-right" />
          </div>
        </div>
      </div>
    </div>

    <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Eliminar</h5>
            <button
              type="button"
              @click="closeModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p style="color:#444">Tem certeza que deseja eliminar o curso?</p>
           </div>
          <div class="modal-footer">
            <Button type="success" :loading="isDeleting"  @click="deleteCourse()" class="btn btn-danger">
             Eliminar 
            </Button>
            <button
              @click="closeModal"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Nao
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LaravelVuePagination from "laravel-vue-pagination";
 
import { ref, onUnmounted, watch } from "vue";
import store from "@/store";
import CourseStoreModule from "./CourseStoreModule";
import toastr from "toastr";

export default {
   
  setup() {
    const CURSO_MODULE_NAME = "Curso";

    if (!store.hasModule(CURSO_MODULE_NAME)) {
      store.registerModule(CURSO_MODULE_NAME, CourseStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(CURSO_MODULE_NAME)) {
        store.unregisterModule(CURSO_MODULE_NAME);
      }
    });

    const course = ref([]);
    const totalCourses = ref(null);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const perPage = ref(10);
    const searchQuery = ref("");
    const isDelete = ref(false);
    const index = ref(null);
    const course_id = ref();
    const isDeleting = ref(false);
    const search = ref('');


    async function getAllCourse(page=1) {
      await CourseStoreModule.actions.fetchCourses(null, {
          perPage: perPage.value,
          page: page,
          search:search.value

        })
        .then((response) => {
          course.value = response.data.data;
          totalCourses.value = response.data.total;
          perPage.value = response.data.per_page;
          currentPage.value = response.data.current_page;
          

          // callback(course.value.data)
          // console.log(callback);
          totalCourses.value = course.value.total;
        })
        .catch((error) => {
          console.log(error);
          toastr.error("Error na busca de dados do curso");
        });
    }

  watch(search, (newValue, oldValue)=>{
      if (newValue.length >= 4 || oldValue.length >= 4) {
        getAllCourse()
      }else if(newValue.length == 0 || oldValue.length == 0){
        getAllCourse()

      }
  });

    function isdeleteCourse(id, i) {
      console.log(id);
      isDelete.value = true;
      index.value = i;
      course_id.value = id;
    }

    async function deleteCourse() {
      isDeleting.value = true;
      // console.log(id_course);
      // const id = course_id.value
      const response = await CourseStoreModule.actions.deleteCourse(course_id.value)
          console.log(response);
      if (response) {
        course.value.data.splice(index.value, 1);
          isDeleting.value = false;
          isDelete.value = false;
        
      }
      // store
      //   .dispatch("Curso/deleteCourse", id_course.value)
      //   .then((response) => {
      //     console.log(response);
      //     course.value.data.splice(index.value, 1);
      //     isDeleting.value = false;
      //     isDelete.value = false;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }

    // function deleteCourse() {
    //   isDeleting.value = true;
    //   store
    //     .dispatch("Curso/deleteCourse", id_course.value)
    //     .then((response) => {
    //       console.log(response);
    //       course.value.data.splice(index.value, 1);
    //       isDeleting.value = false;
    //       isDelete.value = false;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    function closeModal() {
      isDelete.value = false;
    }
    return {
      course,
      perPage,
      currentPage,
      getAllCourse,
      searchQuery,
      perPageOptions,
      deleteCourse,
      isDelete,
      closeModal,
      isdeleteCourse,
      index,
      course_id,
      isDeleting,
      search
    };
  },
  created() {
    this.getAllCourse();
  },
};
</script>


<style scope>
.modal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  transition: height 0.2s linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.modal {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: #fff;
}

.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;

  width: 100%;

  border-radius: 4px;
  height: 100%;
}
</style>