<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Actividades</li>
          <li class="breadcrumb-item active">Lista Actividades</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">
              <h4 class="card-title">Lista de Actividades</h4>
            </div>

            <div class="col-sm-6">
              <router-link to="/add-activities" class="btn btn-primary float-right">
                + Adicionar Actividades</router-link
              >
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" class="app-search">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
                v-model="search"
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table id="example5" class="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Descrição</th>
                  <th>Semestre</th>
                  <th>Grau superior</th>
                  <th>Data Inicio</th>
                  <th>Data Fim</th>
                  <th>Estado</th>
                  <th>Açcões</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(a, index) in activities" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{
                      a.estado == 'Em Curso'
                        ? "Ano lectivo em curso"
                        : "Ano lectivo Fechado"
                    }}
                  </td>
                  <td>{{ a.semester_name }}</td>
                  <td>{{ a.level_name }}</td>
                  <td>{{ a.date_start_academic_year }}</td>
                  <td>{{ a.date_end_academic_year }}</td>
                  <td>{{ a.estado }}</td>
                  <td>
                    <a
                      @click="isdeleteTurma(a.id, index)"
                      href="javascript:void(0);"
                      class="btn btn-sm btn-danger"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>

                <tr v-if="activities.length == 0">
                  <td colspan="6" class="text-center">
                    <span class="text-center">Ainda sem registros</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <Page
              :total="total"
              :model-value="current_page"
              :page-size="per_page"
              @on-change="getAllActivities"
              class="float-right"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Eliminar</h5>
            <button
              type="button"
              @click="closeModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p style="color: #444">
              Tem certeza que deseja eliminar essa actividade?
            </p>
          </div>
          <div class="modal-footer">
            <Button
              type="error"
              :loading="loading"
              @click="deleteActivite"
              class="btn btn-danger"
            >
              Eliminar
            </Button>
            <button
              @click="closeModal"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Nao
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import ActivitieStoreModule from "./ActivitieStoreModule";

const activities = ref([]);
const search = ref("");
const total = ref();
const per_page = ref();
const current_page = ref();
const idActivite = ref();
const isDelete = ref();
const index = ref();
const loading = ref(false);

async function getAllActivities(page = 1) {
  try {
    const resp = await ActivitieStoreModule.actions.listAll(page);
    activities.value = resp.data.data;
    total.value = resp.data.total;
  } catch (error) {
    console.log(error);
  }
}

function isdeleteTurma(id, i) {
  isDelete.value = true;
  index.value = i;
  idActivite.value = id;
}

async function deleteActivite() {
  try {
    loading.value = true;
    activities.value.splice(index.value, 1);
    
    const response = await ActivitieStoreModule.actions.deleteActivite(
      idActivite.value
    );

    if (response) {
      isDelete.value = false;
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

function closeModal() {
  isDelete.value = false;
}

onMounted(() => {
  getAllActivities();
});
</script>