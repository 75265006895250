

export default {
    namespaced:true,
    state:{
        subject_weight:[]
    },
    getters:{

    },
    mutations:{

    },
    actions:{

    }
}

 