<template>
  <div id="estudantes">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashboard</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Inicio</a></li>
            <li class="breadcrumb-item">Pedidos</li>
            <li class="breadcrumb-item active">Lista Pedidos</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="row tab-content">
          <div id="list-view" class="tab-pane fade active show col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table id="example3" class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Assunto</th>
                        <th>Número de Vagas Disponiveis</th>
                        <th>Descrição</th>
                        <th>Enviado pela</th>
                        <th>Email</th>
                        <th>Data de Envio</th>
                        <!-- <th>Acções</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(r, index) in responseTrain" :key="index">
                        <td>{{ index + 1 }}</td>
                         <td>Resposta do Pedido de vaga de Estagio</td>
                        <td v-if="r.number_of_train_available  == null">Neste momento não temos vagas disponiveis</td>
                        <td v-else>{{ r.number_of_train_available }}</td>
                        <td>
                          {{ r.description }}
                        </td>
                        <td>
                          {{ r.fullName }}
                        </td>
                        <td v-if="r.company_email == null">
                          --------------------
                        </td>
                        <td v-else>
                          {{ r.company_email }}
                        </td>
                        <td>
                          {{ r.date }}
                        </td>
                        <!-- <td>
                          <select
                            class="form-control"
                            style="border-radius: 16px"
                            @change="handleEventsStudent($event, index, r.id)"
                          >
                            <option value="0">Selecione</option>
                            <option value="response">Responder</option>
                          </select>
                        </td> -->
                      </tr>
                      <tr v-if="responseTrain.length == 0">
                        <td
                          colspan="7"
                          class="text-center"
                          style="font-style: italic"
                        >
                          Ainda não tem registros
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <Page :total="course.total" :model-value="course.current_page"  :page-size="parseInt(course.per_page)" @on-change="getAllCourse" v-if="course.total"   class="float-right" /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>

<script setup>
import toastr from "toastr";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import responseTrainStoreModule from "./responseTrainStoreModule";

const responseTrain = ref([]);
const router = useRouter();
const isClose = ref(false);
const loading = ref(false);

const requestForm = ref({
  request_id: "",
  fullName: "",
  email: "",
  number_of_train_available: null,
  user_id: null,
  message: null,
});

function closeModal() {
  isClose.value = false;
}

async function getAllResponseTrain() {
  try {
    loading.value = true;

    const resp = await responseTrainStoreModule.actions.getresponseTrainByUser();
    responseTrain.value = resp.data.data;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function handleSubmit() {
  try {
    loading.value = true;
    const resp = await jobStoreModule.actions.responseTrain(requestForm.value);

    if (resp.data.status == true) {
      toastr.success(resp.data.message);

      requestForm.value.message = "";
      requestForm.value.fullName = "";
      requestForm.value.email = "";
      requestForm.value.number_of_train_available = "";
      requestForm.value.request_id = "";
      requestForm.value.user_id = "";
      isClose.value = false
    }
    console.log(resp.data.message);

    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function handleEventsStudent(event, index, company_id) {
  switch (event.target.value) {
    case "response":
      event.target.value = 0;

      console.log(requestTrain.value[index]);
      requestForm.value.fullName = requestTrain.value[index].fullName;
      requestForm.value.email = requestTrain.value[index].teacher_email;
      requestForm.value.request_id = requestTrain.value[index].id;
      requestForm.value.user_id = requestTrain.value[index].user_id;

      isClose.value = true;
      break;

    default:
      console.log("opcao nao encontrada");
      break;
  }
}

onMounted(() => {
  getAllResponseTrain();
});
</script>
