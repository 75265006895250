<template>
  <div id="estudantes">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashbord</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Inicio</a></li>
            <li class="breadcrumb-item">Vagas</li>
            <li class="breadcrumb-item active">Lista Vagas</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>

    <div class="container">
      <div class="col-lg-12">
        <div class="tab-content mx-auto">
          <div id="list-view" class="tab-pane fade active show col-lg-12">
            <div
              v-if="jobsByUser.length > 0"
              class="pb-5 slimscroll-menu"
              id="remove-scroll"
            >
              <div
                class="card p-2 col-lg-8 mx-auto"
                style="background-color: #dae4ea"
                v-for="(v, index) in jobsByUser"
                :key="index"
              >
                <div class="card-title"></div>

                <div class="card-body">
                  <h1>{{ v.title }}</h1>
                  <small><i class="fa fa-city"></i> {{ v.company_name }}</small>
                  |
                  <small> {{ v.name }}</small>
 
                   <br />
                  <small
                    ><i class="fa fa-user"></i>&nbsp;Supervisor:
                    {{ v.fullName }}</small
                  >
                  |
                  <small
                    ><a
                      @click="openModalResume(v.url_file_name)"
                      href="javascript:void(0)"
                      >curriculo</a
                    ></small
                  >
                  <p style="font-size: 13px">
                    <i class="fa fa-map-marker"></i> Local:
                    {{ v.province_name }}, {{v.district_name}}
                  </p>

                  <p style="font-size: 12px" class="mt-2 text-danger">
                    <i class="fa fa-calendar"></i>Prazo de Candidaturas:
                    {{ v.deadline }}
                  </p>
                  <p style="text-align: justify" class="mt-3">
                    {{ v.description }}
                  </p>
                </div>

                <div
                  v-if="roleUser != 'Teacher' && roleUser != 'Student'"
                  class="d-flex card-footer justify-content-end"
                >
                  <small style="cursor: pointer" class="mr-1"
                    ><i class="fa fa-trash text-danger"></i
                    >&nbsp;Eliminar</small
                  >
                  <small style="cursor: pointer" class="mr-1"
                    ><i class="fa fa-pen text-info"></i>&nbsp;Actualizar</small
                  >
                </div>

                <div
                  v-if="roleUser == 'Student'"
                  class="d-flex card-footer justify-content-end"
                >
                  <Button
                    @click="cancel_job(v.id, index)"
                    style="cursor: pointer"
                    class="mr-1"
                    ><i class="fa fa-wallet text-danger"></i>&nbsp;Cancelar
                    candidatura</Button
                  >

                   <Button
                   v-if="v.accepted == '1' && v.student_treines_accept == '0'"
                   type="success"
                    @click="confirmTrainess(v.id, index)"
                    style="cursor: pointer"
                    class="mr-1"
                    >&nbsp;Confirmar 
                    candidatura</Button
                  >

                   <Button
                   v-if="v.accepted == '1' && v.student_treines_accept == '1'"
                   type="info"
                     style="cursor: pointer"
                    class="mr-1"
                    >&nbsp;Candidatura Confirmada</Button
                  >
                </div>
              </div>

              <Page
                :total="total"
                :model-value="current_page"
                :page-size="parseInt(per_page)"
                @on-change="getAllCompany"
                v-if="total"
                class="text-center mb-5"
              />
            </div>
              <div  style="background-color: #dae4ea" class=" p-3 card text-center" v-else>
               <span style="font-style: italic; text-align: center"
                          >Ainda não tem registros</span
                        >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isModalStudentJob" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cancelar</h5>
          <button
            type="button"
            @click="closeModalStudent_job"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
               <label class="form-label">Informe o motivo*</label>
              <textarea
                rows="5"
                cols="50"
                class="form-control"
                v-model="form.description_of_cancel_job"
              ></textarea>
          
        </div>
        <div class="modal-footer">
          <Button type="error" @click="deleteStudent_job()" :loading="loading">
            Cancelar
          </Button>
          <Button
            @click="closeModalStudent_job"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Nao
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import store from "@/store";
import axios from "axios";
import toastr from "toastr";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import StudentModuleService from "../students/StudentModuleService";
import jobStoreModule from "../Jobs/jobStoreModule";

const jobs = ref([]);
const router = useRouter();
const total = ref();
const per_page = ref("");
const current_page = ref("");
const isClose = ref(false);
const isModalEditCompany = ref(false);
const isModalProfileCompany = ref(false);
const isDelete = ref(false);
const loading = ref(false);
const delete_id = ref();

const roleUser = ref("");
const jobsByUser = ref([]);
const Logged_student_id = ref(null);
const isModalStudentJob = ref(false);
 const index = ref(null);
const form = ref({
    description_of_cancel_job:"",
    student_job: 0,
})
async function getJobsByUser() {
  try {
    loading.value = true;
    const resp = await jobStoreModule.actions.jobsByUser();
    jobsByUser.value = resp.data.data;
    total.value = resp.data.total;
    per_page.value = resp.data.per_page;
    current_page.value = resp.data.current_page;
    console.log(resp.data);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function deleteStudent_job() {
  try {
    loading.value = true;

    if (form.value.description_of_cancel_job == null || form.value.description_of_cancel_job == "") {
        
        return toastr.warning("Campo obrigatorio");
    }
    const response = await jobStoreModule.actions.cancel_job(form.value);
    if (response.data.status == true) {
          jobsByUser.value.splice(index.value, 1);

    }
    isModalStudentJob.value = false;

    console.log(response);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}
 
function closeModalStudent_job() {
  isModalStudentJob.value = false;
}

async function cancel_job(student_job_id, index) {
  try {
    isModalStudentJob.value = true;
    form.value.student_job = student_job_id;
    index.value = index;
    // jobsByUser.value[index].splice(index, 1);
    // const resp = await jobStoreModule.actions.cancel_job(student_id);
  } catch (error) {
    console.log(error);
  }
}

async function confirmTrainess(id, index){

  try {

  // console.log(jobsByUser.value);
    jobsByUser.value[index].student_treines_accept = '1' 
    const resp = await jobStoreModule.actions.confirmTrainess(id);
  } catch (error) {
    console.log(error);
  }
}

async function getCompany(id) {
  try {
    const resp = await CompanyStoreModule.actions.show(id);
    console.log(resp.data.data[0]);
    getDistrictsByprovinceIdd(resp.data.data[0].district.province_id);
    province_id.value = resp.data.data[0].district.province_id;
    district_name.value = resp.data.data[0].district.name;
    province_name.value = resp.data.data[0].province;
    form.value = resp.data.data[0];
  } catch (error) {
    console.log(error);
  }
}

function closeModal() {
  isClose.value = false;
}

function closeModalEditCompany() {
  isModalEditCompany.value = false;
}

function closeModalProfileCompany() {
  isModalProfileCompany.value = false;
}

function closeModalDelete() {
  isDelete.value = false;
}

async function handleEventsStudent(event, company_id) {
  switch (event.target.value) {
    case "request":
      event.target.value = 0;
      getRequestCompany(company_id);
      //   router.push({ name: "pedido", params: { id: company_id } });
      isClose.value = true;
      break;
    case "update":
      event.target.value = 0;
      isModalEditCompany.value = true;
      getCompany(company_id);
      //   router.push({ name: "editCompany", params: { id: company_id } });
      break;
    case "active":
      event.target.value = 0;
      const resp = await CompanyStoreModule.actions.activeUser(company_id);

      companyData.value.forEach((element) => {
        if (element.company_id == company_id) {
          element.is_active = 1;
        }
      });

      if (resp.data.status == true) {
        toastr.success(resp.data.message);
      } else {
        toastr.info(resp.data.message);
      }

      break;
    case "disable":
      event.target.value = 0;

      companyData.value.forEach((element) => {
        if (element.company_id == company_id) {
          element.is_active = 0;
        }
      });
      const respDisable = await CompanyStoreModule.actions.disableUser(
        company_id
      );

      if (respDisable.data.status == true) {
        toastr.success(respDisable.data.message);
      } else {
        toastr.info(respDisable.data.message);
      }

      break;

    case "view":
      event.target.value = 0;
      isModalProfileCompany.value = true;
      getCompany(company_id);
      break;

    case "delete":
      event.target.value = 0;
      isDelete.value = true;
      delete_id.value = company_id;

      break;

    default:
      console.log("opcao nao encontrada");
      break;
  }
}

async function getProvinces() {
  const resp = await StudentModuleService.actions.getProvinces();
  provinces.value = resp.data;
}

async function getDisitrictbyId(province_id) {
  await axios
    .get("/api/district/" + province_id)
    .then((response) => {
      districtData.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

async function ChangeProvince(evt) {
  await axios
    .get("/api/district/" + evt.target.value)
    .then((response) => {
      districtData.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

async function getDistrictsByprovinceIdd(id) {
  const resp = await CompanyStoreModule.actions.districtsByProvinceId(id);
  districtData.value = resp.data;
}

onMounted(() => {
  if (store.state.auth.authenticated == true) {
    roleUser.value = store.state.auth.user.data.role[0];
    Logged_student_id.value = store.state.student.student.contas_estudante[0].id;
    // console.log(store.state.auth.user.data.student );
  }

  getJobsByUser();
});
</script>


<style scoped>
.disabled {
  background: #ccc;
}
</style>