<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Todos Departamentos</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item ">Departamentos</li>
          <li class="breadcrumb-item active">Lista Departamentos</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">

              <h5 class="page-title">Lista de Departamentos</h5>
            </div>
            <div class="col-sm-6">
              <router-link to="/add-department" class="btn btn-primary float-right">
                + Adicionar Departamentos</router-link>

            </div>

          </div>
        </div>
        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
              <input type="text" class="form-control" v-model="search" placeholder="Pesquisar...">
            <button type="submit"><i class="fa fa-search"></i></button>

            </form>

          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Departamento</th>
                  <th scope="col">Descricao</th>
                  <th scope="col" colspan="2">Accao</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dep, index) in department.data" :key="index">
                  <td>{{ 1+index }}</td>
                  <td>{{dep.dep_name}}</td>
                  <td>{{dep.description}}</td>
                  
                  <td>
                    <div>
                      <router-link :to="{name:'editDepart', params:{id_department:dep.id}}" class="btn btn-primary btn-sm m-1"><i class="fas fa-edit"></i
                        ></router-link>
                      <a  @click="isdeleteDepartment(dep.id, index)"
                      href="javascript:void(0)" class="btn btn-primary btn-sm btn-danger"><i class="fas fa-trash-alt"></i></a>
                    </div>
                  </td>
                </tr>
               
              </tbody>
            </table>

                <Page :total="department.total" :model-value="department.current_page"  :page-size="parseInt(department.per_page)" @on-change="getAllDepartment" v-if="department.total"   class="float-right" />

          </div>
        </div>
      </div>
    </div>
    <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Eliminar</h5>
            <button
              type="button"
              @click="closeModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Tem certeza que deseja eliminar o departamento?</p>
           </div>
          <div class="modal-footer">
            <button type="button" @click="deleteDepartment()" class="btn btn-danger">
              {{ isDeleting == false ? "Sim" : "Eliminando..." }}
            </button>
            <button
              @click="closeModal"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Nao
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted, watch } from 'vue';
import store from '@/store';
import DepartmentStoreModule from './DepartmentStoreModule';
import toastr from 'toastr';
export default {
 
 
  setup() {
    const DEPARTAMENTO_MODULE_NAME = 'Departamento'
    // Register module
    if (!store.hasModule(DEPARTAMENTO_MODULE_NAME)) { store.registerModule(DEPARTAMENTO_MODULE_NAME, DepartmentStoreModule) }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTAMENTO_MODULE_NAME)) { store.unregisterModule(DEPARTAMENTO_MODULE_NAME) }
    })
    const department = ref([])
    const totalDepartamentos = ref(null)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const searchQuery = ref('')
    const pageInfo = ref(null)
    const search = ref('');
    const isDelete = ref();
    const index = ref();
    const department_id = ref();
    const isDeleting = ref(false);

    async function isdeleteDepartment(id, i){
       isDelete.value = true;
      index.value = i;
      department_id.value = id;
    }

    async function deleteDepartment(){
      isDeleting.value = true; 
      const response = await DepartmentStoreModule.actions.deleteDepartment(department_id.value)
          console.log(response);
      if (response) {
        department.value.data.splice(index.value, 1);
          isDeleting.value = false;
          isDelete.value = false;
        
      }

    }

    function closeModal() {
      isDelete.value = false;
    }
  

    async function getAllDepartment (page=1) {
 
       await DepartmentStoreModule.actions.fetchDepartamentos(null, {
        perPage: perPage.value,
        page: page,
        search:search.value
      })
      .then(response=>{
               department.value = response.data
                     console.log(response);

        pageInfo.value = response
        // callback(department.value)
        totalDepartamentos.value = response.data.total
      })
       .catch((error) => {
        console.log(error)
        toastr.error('Erro Na busca de dados do departamento ou contacte o administrador')
        })
 
    }

    watch(search, (newValue, oldValue)=>{
      if (newValue.length >= 4 || oldValue.length >= 4) {
        getAllDepartment()
      }else if(newValue.length == 0 || oldValue.length == 0){
        getAllDepartment()

      }
    });

    return {
      department, 
      perPageOptions,
      searchQuery,
      currentPage,
      getAllDepartment,
      isdeleteDepartment,
      deleteDepartment,
      deleteDepartment,
      pageInfo,
      search,
      isDelete,
      index,
      department_id,
      isDeleting,
      closeModal
    }
  },
  created() {
    this.getAllDepartment()
  },
};
</script>
