<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Todas Disciplinas</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Disciplinas</li>
          <li class="breadcrumb-item active">Lista Disciplinas</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Cadastrar Professor</div>
        </div>
        <div class="card-body">
          <form action="#" method="post">
            <!-- personal User data -->
            <div v-show="firstInfoShow">
              <h1 class="text-center mb-5">Dados Pessoas</h1>
            </div>
            <div class="row" v-show="firstInfoShow">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome Completo*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.fullname"
                  />
                </div>
                <p v-if="fullname_message_error" style="color: red">
                  {{ fullname_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Ano de Nascimento*</label>
                  <input
                    type="date"
                    class="datepicker-default form-control"
                    v-model="userData.birthDate"
                  />
                </div>
                <p v-if="birthDate_message_error" style="color: red">
                  {{ birthDate_message_error }}
                </p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Genero*</label>
                  <select class="form-control" v-model="userData.gender">
                    <option value="">Genero</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                  </select>
                </div>
                <p v-if="gender_message_error" style="color: red">
                  {{ gender_message_error }}
                </p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.email"
                    @input="validateEmail"
                  />
                </div>
                <p v-if="emailError" style="color: red">{{ emailError }}</p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto*</label>
                  <input
                    type="text"
                    class="form-control"
                    @keypress="numberOnly"
                    pattern="/^[0-9]+$/"
                    maxlength="9"
                    v-model="userData.contact"
                    @input="validatePhoneNumber"
                  />
                </div>
                <p v-if="phoneNumberError" style="color: red">
                  {{ phoneNumberError }}
                </p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto Alternativo</label>
                  <input
                    type="text"
                    class="form-control"
                    @keypress="numberOnly"
                    pattern="/^[0-9]+$/"
                    maxlength="9"
                    v-model="userData.alternative_contact"
                    @input="validatePhoneNumberAlternative"
                  />
                </div>
                <p v-if="phoneNumberErrorAlternative" style="color: red">
                  {{ phoneNumberErrorAlternative }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Tem alugma deficiencia?*</label>
                  <select
                    @change="is_deficientChange($event)"
                    class="form-control"
                    v-model="userData.is_deficient"
                  >
                    <option value="-1">Selecione</option>
                    <option value="0">Nao</option>
                    <option value="1">Sim</option>
                  </select>
                </div>
                <p v-if="isDeficient_message_error" style="color: red">
                  {{ isDeficient_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label"
                    >Selecione o tipo de deficiencia</label
                  >
                  <select
                    class="form-control"
                    :disabled="deficientTypeDisabled"
                    v-model="userData.deficient_type"
                  >
                    <option value="Class">Selecione o tipo</option>
                    <option value="Paraplegia">Paraplegia</option>
                    <option value="Paraparesia">Paraparesia</option>
                    <option value="Monoplegia">Monoplegia</option>
                    <option value="Monoparesia">Monoparesia</option>
                    <option value="Tetraplegia">Tetraplegia</option>
                    <option value="Triplegia">Triplegia</option>
                    <option value="Triparesia">Triparesia</option>
                    <option value="Hemiplegia">Hemiplegia</option>
                    <option value="Hemiparesia">Hemiparesia</option>
                    <option value="Amputação">Amputação</option>
                    <option value="Paralisia Cerebral">
                      Paralisia Cerebral
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome do Pai*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.father_filliation"
                  />
                </div>
                <p v-if="father_name_message_error" style="color: red">
                  {{ father_name_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Mãe*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.mother_filliation"
                  />
                </div>
                <p v-if="mother_name_message_error" style="color: red">
                  {{ mother_name_message_error }}
                </p>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <button
                  type="button"
                  class="btn btn-primary float-right"
                  v-on:click="handleNextInfo"
                >
                  Proximo
                </button>
              </div>
            </div>

            <!-- End -->

            <div v-show="secondInfoShow">
              <h1 class="text-center mb-5">Documentos Pessoais</h1>
            </div>
            <div class="row" v-show="secondInfoShow">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione o tipo documento*</label>
                  <select class="form-control" v-model="userData.doc_type">
                    <option value="">Selecione o tipo documento</option>
                    <option value="BI">Bilhete de Identidade</option>
                  </select>
                </div>
                <p v-if="doc_message_error" style="color: red">
                  {{ doc_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Numero do doc*</label>
                  <input
                    type="text"
                    class="form-control"
                    maxlength="13"
                    v-model="userData.doc_number"
                    @input="validateIdentificationNumber"
                  />
                </div>
                <p v-if="doc_number_message_error" style="color: red">
                  {{ doc_number_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nacionalidade*</label>
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-model="userData.nationality"
                  /> -->

                  <select v-model="userData.nactionality" class="form-control">
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Åland Islands">Åland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">
                      Bosnia and Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">
                      British Indian Ocean Territory
                    </option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos (Keeling) Islands">
                      Cocos (Keeling) Islands
                    </option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, The Democratic Republic of The">
                      Congo, The Democratic Republic of The
                    </option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (Malvinas)">
                      Falkland Islands (Malvinas)
                    </option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">
                      French Southern Territories
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-bissau">Guinea-bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and Mcdonald Islands">
                      Heard Island and Mcdonald Islands
                    </option>
                    <option value="Holy See (Vatican City State)">
                      Holy See (Vatican City State)
                    </option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran, Islamic Republic of">
                      Iran, Islamic Republic of
                    </option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea, Democratic People's Republic of">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="Korea, Republic of">
                      Korea, Republic of
                    </option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao People's Democratic Republic">
                      Lao People's Democratic Republic
                    </option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">
                      Libyan Arab Jamahiriya
                    </option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia, The Former Yugoslav Republic of">
                      Macedonia, The Former Yugoslav Republic of
                    </option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia, Federated States of">
                      Micronesia, Federated States of
                    </option>
                    <option value="Moldova, Republic of">
                      Moldova, Republic of
                    </option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambican" selected>
                      Mo&ccedil;ambicana
                    </option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">
                      Netherlands Antilles
                    </option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">
                      Northern Mariana Islands
                    </option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestinian Territory, Occupied">
                      Palestinian Territory, Occupied
                    </option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">
                      Russian Federation
                    </option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Pierre and Miquelon">
                      Saint Pierre and Miquelon
                    </option>
                    <option value="Saint Vincent and The Grenadines">
                      Saint Vincent and The Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">
                      Sao Tome and Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option
                      value="South Georgia and The South Sandwich Islands"
                    >
                      South Georgia and The South Sandwich Islands
                    </option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">
                      Svalbard and Jan Mayen
                    </option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syrian Arab Republic">
                      Syrian Arab Republic
                    </option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania, United Republic of">
                      Tanzania, United Republic of
                    </option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-leste">Timor-leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">
                      Turks and Caicos Islands
                    </option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">
                      United States Minor Outlying Islands
                    </option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Viet Nam">Viet Nam</option>
                    <option value="Virgin Islands, British">
                      Virgin Islands, British
                    </option>
                    <option value="Virgin Islands, U.S.">
                      Virgin Islands, U.S.
                    </option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
                <p v-if="country_message_error" style="color: red">
                  {{ country_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione o Provincia*</label>
                  <select
                    class="form-control"
                    v-model="userData.province_id"
                    @change="ChangeProvince($event)"
                  >
                    <option value="0">Selecione o Provincia</option>
                    <option
                      :value="provinces.id"
                      v-for="provinces in provincesData"
                      :key="provinces.id"
                    >
                      {{ provinces.province_name }}
                    </option>
                  </select>
                </div>
                <p v-if="province_message_error" style="color: red">
                  {{ province_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione o Distrito*</label>
                  <select
                    :disabled="loadDistricts"
                    class="form-control"
                    v-model="userData.district_id"
                  >
                    <option value="0">Selecione o Distrito</option>
                    <option
                      :value="district.id"
                      v-for="district in districtData"
                      :key="district.id"
                    >
                      {{ district.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Endereco*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.address_location"
                  />
                </div>
                <p v-if="address_message_error" style="color: red">
                  {{ address_message_error }}
                </p>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <Button
                  type="info"
                  v-on:click="handleSubmitButton"
                  :loading="loading"
                  class="float-right"
                >
                  Criar
                </Button>
                <button
                  type="button"
                  class="btn btn-light"
                  v-on:click="handleBackInfo"
                >
                  Voltar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted } from "vue";
import { mapGetters } from "vuex";
import TeacherStoreModule from "./TeacherStoreModule";
import store from "@/store";
import toastr from "toastr";
import DepartmentStoreModule from "../department/DepartmentStoreModule";
import ClassMateStoreModule from "../Classmates/ClassMateStoreModule";
import SubjectStoreModule from "../subject/SubjectStoreModule";
import permissionStoreModule from "../permissions/permissionStoreModule";
import axios from "axios";

const firstInfoShow = ref(true);
const secondInfoShow = ref(false);
const stepThreeInfo = ref(false);
const stepFourInfo = ref(false);
const stepFiveInfo = ref(false);
const personalData = ref(false);
const loadDistricts = ref(true);
const schoolData = ref(false);
const courseData = ref(false);
const documentData = ref(false);
const loading = ref(false);
const deficientTypeDisabled = ref(true);
const id_province = ref(0);

const userData = ref({
  fullname: "",
  address_location: "",
  email: "",
  gender: "",
  doc_type: "",
  doc_number: "",
  nactionality: "",
  birthDate: "",
  last_school_frequency: "",
  level_type: "",
  last_school_frequency_year: "",
  father_filliation: "",
  mother_filliation: "",
  childhood_location: "",
  is_deficient: "",
  deficient_type: "",
  permissions: "",
  contact: "",
  alternative_contact: "",
  province_id: "",
  district_id: "",
  role: "",
  regimeSuperior: "",
  department_id: 0,
  permissions: [],
  subjects: [],
  classmates: [],
});

const provincesData = ref([]);
const districtData = ref([]);
const DataCourse = ref([]);
const roles = ref([]);
const department = ref([]);
const classmates = ref([]);
const subjects = ref([]);
const permissions = ref([]);

const emailError = ref("");
const phoneNumberError = ref("");
const phoneNumberErrorAlternative = ref("");
const name_message_error = ref("");
const birthDate_message_error = ref("");
const district_message_error = ref("");
const address_message_error = ref("");
const doc_message_error = ref("");
const gender_message_error = ref("");
const isDeficient_message_error = ref("");
const father_name_message_error = ref("");
const mother_name_message_error = ref("");
const country_message_error = ref("");
const province_message_error = ref("");
const fullname_message_error = ref("");
const nationality_message_error = ref("");
const endereco_message_error = ref("");
const number_message_error = ref("");
const doc_number_message_error = ref("");

const resources = [
  {
    resource: "Material de Aulas",
    read: false,
    write: false,
    update: false,
    delete: false,
    name: "lessons_material",
  },
  {
    resource: "Visualizar Turmas",
    read: false,
    write: false,
    update: false,
    delete: false,
    name: "classmates",
  },
  {
    resource: "Cadastrar Pautas",
    read: false,
    write: false,
    update: false,
    delete: false,
    name: "average",
  },
  {
    resource: "Horario",
    read: false,
    write: false,
    update: false,
    delete: false,
    name: "average",
  },
];

const years = ref([]);

function getYearTilCurrent() {
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year >= 1900; year--) {
    years.value.push(year);
  }
}
async function getAllDepartment() {
  const resp = await DepartmentStoreModule.actions.getAlldepartment();

  department.value = resp.data.data;
}

async function getAllClassmates() {
  const resp = await ClassMateStoreModule.actions.getTurmas();
  classmates.value = resp.data.data;
}

async function getSubjects() {
  const resp = await SubjectStoreModule.actions.getSubjects();
  subjects.value = resp.data.data;
}

async function getAllpermissions() {
  const resp = await permissionStoreModule.actions.getAllPermissions();
  permissions.value = resp.data;
  console.log(resp.data);
}

function validatePhoneNumberWhatsapp() {
  // Expressão regular para validar o número de telefone
  const phoneNumberRegex = /^(86)\d{8}$/;
  var firstTwoDigits = "";
  if (userData.value.whatsapp.length >= 2) {
    firstTwoDigits = userData.value.whatsapp.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        whatsapp_message_error.value = null;

        break;
      case "85":
        whatsapp_message_error.value = null;

        break;
      case "86":
        whatsapp_message_error.value = null;

        break;
      case "87":
        whatsapp_message_error.value = null;

        break;
      case "82":
        phoneNumberError.value = null;

        break;
      case "83":
        whatsapp_message_error.value = null;

        break;

      default:
        whatsapp_message_error.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}
function validatePhoneNumber() {
  // Expressão regular para validar o número de telefone
  const phoneNumberRegex = /^(86)\d{8}$/;
  var firstTwoDigits = "";
  if (userData.value.contact.length >= 2) {
    firstTwoDigits = userData.value.contact.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        phoneNumberError.value = null;

        break;
      case "85":
        phoneNumberError.value = null;

        break;
      case "86":
        phoneNumberError.value = null;

        break;
      case "87":
        phoneNumberError.value = null;

        break;
      case "82":
        phoneNumberError.value = null;

        break;
      case "83":
        phoneNumberError.value = null;

        break;

      default:
        phoneNumberError.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}

function validateIdentificationNumber() {
  const regex = /^[0-9]{12}[a-zA-Z]$/;

  // Testar a expressão regular
  if (!regex.test(userData.value.doc_number)) {
    doc_number_message_error.value = "Número de identificação inválido!";

    return;
  } else {
    doc_number_message_error.value = "";
  }
}
function validatePhoneNumberAlternative() {
  // Expressão regular para validar o número de telefone
  var firstTwoDigits = "";

  if (userData.value.alternative_contact.length >= 2) {
    firstTwoDigits = userData.value.alternative_contact.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        phoneNumberErrorAlternative.value = null;

        break;
      case "85":
        phoneNumberErrorAlternative.value = null;

        break;
      case "86":
        phoneNumberErrorAlternative.value = null;

        break;
      case "87":
        phoneNumberErrorAlternative.value = null;

        break;
      case "82":
        phoneNumberErrorAlternative.value = null;

        break;
      case "83":
        phoneNumberErrorAlternative.value = null;

        break;

      default:
        phoneNumberErrorAlternative.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}
function validateEmail() {
  // Expressão regular para validar o formato de um e-mail
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Verifica se o e-mail atende ao formato esperado
  if (!emailRegex.test(userData.value.email)) {
    emailError.value = "Por favor, insira um endereço de e-mail válido.";
  } else {
    emailError.value = null;
  }
}

function clearInputs() {
  userData.value.address_location = "";
  userData.value.alternative_contact = "";
  userData.value.birthDate = "";
  userData.value.childhood_location = "";
  userData.value.contact = "";
  userData.value.course_id = "";
  userData.value.deficient_type = "";
  userData.value.district_id = "";
  userData.value.doc_number = "";
  userData.value.doc_type = "";
  userData.value.email = "";
  userData.value.father_filliation = "";
  userData.value.gender = "";
  userData.value.is_deficient = "";
  userData.value.last_school_frequency = "";
  userData.value.last_school_frequency_year = "";
  userData.value.level_type = "";
  userData.value.mother_filliation = "";
  userData.value.nationality = "";
  userData.value.name = "";
  userData.value.regime = "";
  userData.value.regimeSuperior = "";
}

function handleNextInfo() {
  if (userData.value.fullname == "") {
    fullname_message_error.value = "O campo nome não pode estar vazio!";
    return;
  }

  if (userData.value.birthDate == "") {
    birthDate_message_error.value =
      "O campo data de nascimento não pode estar vazio!";
    return;
  }

  if (userData.value.gender == "") {
    gender_message_error.value = "O campo genero não pode estar vazio!";
    return;
  }

  if (userData.value.contact == "") {
    phoneNumberError.value = "O campo contacto não pode estar vazio!";
    return;
  }

  if (userData.value.is_deficient == "") {
    isDeficient_message_error.value =
      "O campo deficiencia não pode estar vazio!";
    return;
  }

  if (userData.value.father_filliation == "") {
    father_name_message_error.value =
      "O campo nome do pai não pode estar vazio!";
    return;
  }

  if (userData.value.mother_filliation == "") {
    mother_name_message_error.value =
      "O campo nome da mãe não pode estar vazio!";
    return;
  }

  firstInfoShow.value = false;
  secondInfoShow.value = true;
  personalData.value = false;
  documentData.value = true;
  console.log("he");
}

function handleStepTwo() {
  if (
    userData.doc_type == "" ||
    userData.doc_number == "" ||
    userData.nactionality == "" ||
    userData.provice == "" ||
    userData.district == "" ||
    userData.address_location == ""
  ) {
    return toastr.warning(
      "Por favor preecha os campos obrigatorios",
      "Opsssiiii"
    );
  }

  secondInfoShow.value = false;
  stepThreeInfo.value = true;
  documentData.value = false;
  schoolData.value = true;
}

function handleStepThree() {
  if (
    userData.last_school_frequency == "" ||
    userData.regime == "" ||
    userData.last_school_frequency_year == ""
  ) {
    return toastr.warning(
      "Por favor preecha os campos obrigatorios",
      "Opsssiiii"
    );
  }

  stepThreeInfo.value = false;
  stepFourInfo.value = true;
  schoolData.value = false;
  // couseData.value = true;
}

function handleStepFive() {
  if (
    userData.department_id == "" ||
    userData.classmates[0] == "" ||
    userData.subjects[0] == ""
  ) {
    return toastr.warning(
      "Por favor preecha os campos obrigatorios",
      "Opsssiiii"
    );
  }

  stepFourInfo.value = false;
  stepFiveInfo.value = true;
  // schoolData.value = false;
  // couseData.value = true;
}
function handleBackInfo() {
  secondInfoShow.value = false;
  firstInfoShow.value = true;
  documentData.value = false;
  personalData.value = true;
}

function handleBackDocument() {
  firstInfoShow.value = false;
  schoolData.value = false;
  documentData.value = true;
  secondInfoShow.value = true;
  stepThreeInfo.value = false;
}

function handleBackNivel() {
  stepThreeInfo.value = true;
  stepFourInfo.value = false;
}

function handleBackSchool() {
  schoolData.value = true;
  stepFourInfo.value = true;
  stepFiveInfo.value = false;
}

function goToFirstForm() {
  firstInfoShow.value = true;
  secondInfoShow.value = false;
  stepThreeInfo.value = false;
  stepFourInfo.value = false;
  personalData.value = true;
}

function is_deficientChange(evt) {
  console.log(evt.target.value);

  if (evt.target.value < 0 || evt.target.value == 0) {
    deficientTypeDisabled.value = true;
  } else {
    deficientTypeDisabled.value = false;
  }
}

function getRoles() {
  store
    .dispatch("Professor/fetchRoles")
    .then((response) => {
      roles.value = response.data;
      userData.permission_id = response.data[4].id;

      console.log(response.data);
      console.log(response.data[4].id);
    })
    .catch((error) => {
      console.log(error);
    });
}

async function handleSubmitButton() {
  try {
    loading.value = true;

    if (userData.value.doc_type == "") {
      doc_message_error.value =
        "O campo tipo de documeno não pode estar vazio!";
    }

    if (userData.value.doc_number == "") {
      doc_number_message_error.value =
        "O campo numero do documeno não pode estar vazio!";
    }

    if (userData.value.nationality == "") {
      nationality_message_error.value =
        "O campo naciolidade não pode estar vazio!";
    }

    if (userData.value.province_id == "") {
      province_message_error.value = "O campo provincia  não pode estar vazio!";
    }

    if (userData.value.doc_number == "") {
      endereco_message_error.value = "O campo endereco não pode estar vazio!";
    }

    const response = await TeacherStoreModule.actions.createTeacher(
      userData.value
    );
    console.log(response.data);
    if (response.data.sucesso) {
      toastr.success(response.data.sucesso);
      clearInputs();
      goToFirstForm();
    }

    if (response.data.ExistEmail) {
      toastr.success("Email ja existe");
      
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function getProvinces() {
  try {
    const response = await TeacherStoreModule.actions.fetchProvinces();
    provincesData.value = response.data;
  } catch (error) {}
}

async function ChangeProvince(evt) {
  loadDistricts.value = true;
  id_province.value = evt.target.value;
  console.log(evt.target.value);

  const resp = await TeacherStoreModule.actions.fetchDistricts(
    id_province.value
  );
  districtData.value = resp.data;
  loadDistricts.value = false;
  console.log(resp);
}

async function getAllCourses() {
  try {
    const response = await TeacherStoreModule.actions.getAllCourses();
    DataCourse.value = response.data;
  } catch (error) {
    console.log(error);
  }
}

function numberOnly($event) {
  let keycode = $event.keyCode ? $event.keyCode : $event.which;

  if ((keycode < 48 || keycode > 57) && keycode !== 46) {
    $event.preventDefault();
  }
}

onMounted(() => {
  getProvinces();
  getAllCourses();
  getYearTilCurrent();
  //  getRoles();
  //  getAllDepartment();
  //  getAllClassmates();
  //  getSubjects();
  //  getAllpermissions();
});
</script>
