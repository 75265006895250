import axios from "axios";
import authHeader from "@/Api/AuthHeader";

// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCourses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get(URL_API+
            'cursos?page='+queryParams.page+'&search='+queryParams.search,
            { headers: authHeader() },
           )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getAllCourses() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+"cursos", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getCourses(){

      try {
        return axios.get(URL_API+"getAllCourses", {headers:authHeader()});
      } catch (error) {
        return error;
      }
    },

    // createCourse(ctx, CourseData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post("/api/cursos", CourseData, { headers: authHeader() })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    async createCourse(CourseData) {
      try {
        const response = await axios.post(URL_API+"cursos", CourseData, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },
    async getDepartments() {

      try {
        const response = await axios.get(URL_API+"getAlldepartment", { headers: authHeader() })
        return response;
      } catch (error) {
        return error;
      }
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get("/api/getAlldepartment", { headers: authHeader() })
      //     .then((response) => resolve(response))
      //     .catch((error) => reject(error));
      // });
    },

    async viewCourse(id) { 
        
        return await axios.get(URL_API+"cursos/" + id, { headers: authHeader() }) 
    },
    // viewCourse(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get("/api/cursos/" + id, { headers: authHeader() })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    async updateCourse(CourseData) {
      try {
        const response = await axios.put(URL_API+"cursos/" + CourseData.id,
          CourseData.data,
          {
            headers: authHeader(),
          }
        );

        return response;
      } catch (error) {
        return error;
      }
    },

    // updateCourse(ctx, CourseData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .put("/api/cursos/" + CourseData.id, CourseData.data, {
    //         headers: authHeader(),
    //       })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    async deleteCourse(id) {

      console.log("store", id);
      try {
        const response = await axios.delete(URL_API+"cursos/" + id, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },
    // deleteCourse(ctx, id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .delete("/api/cursos/" + id, { headers: authHeader() })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },
  },
};
