<template>
    <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Previlegios</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
          <li class="breadcrumb-item active">Funcionarios</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="card-title">Lista de Funcionarios</h5>
            </div> 
          </div>
        </div>
        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
                v-model="search"
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table
              id="example5"
              class="table table-hover"
              style="min-width: 845px"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Previlegio</th>
                  <th>Activo</th>
                  <th>Accoes</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in listUser" :key="index">
                  <td>{{ 1 + index }}</td>
                  <td>{{ user.fullname }}</td>
                  <td>{{ user.email }}</td>

                  <td v-for="role in user.roles" :key="role" class="  text-info">{{ role.name }}</td>
                  <td>{{ user.is_active }}</td>
                  <td>
                  <router-link :to="{name:'AlocateUser', params:{user_id:user.id}}" class="btn btn-sm btn-success">
                           <i class="fas fa-network-wired text-white"></i>
                          </router-link>&nbsp;
                    <router-link
                      :to="{ name: 'editCourse', params: { id_course: user.id } }"
                      class="btn btn-sm btn-primary"
                      ><i class="fas fa-edit"></i
                    ></router-link>&nbsp;
                    <a
                      @click="isdeleteCourse(c.id, index)"
                      href="javascript:void(0);"
                      class="btn btn-sm btn-danger"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
               <!-- <Page :total="listUser.total" :model-value="listUser.current_page"  :page-size="parseInt(listUser.per_page)" @on-change="getAllCourse" v-if="listUser.total"   class="float-right" /> -->

             
          </div>
        </div>
      </div>
    </div>
    </div>

</template>

<script>
import { onMounted, ref } from 'vue';
import EmployesStoreModule from '@/pages/Employes/EmployesStoreModule'
export default {
  setup() {
 

    const listUser = ref([]); 
    onMounted(()=>{
        getEmployes()
    })

    async function getEmployes(){

        const resp = await EmployesStoreModule.actions.getEmployes();
        listUser.value = resp.data;
        console.log(resp.data);
    }
    return{
        getEmployes,
        listUser
    }

  },
};
</script>
