<template>
  <div class="left side-menu">
    <div class="slimscroll-menu" id="remove-scroll">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu" id="side-menu">
          <li class="menu-title">Menu</li>

          <li>
            <router-link to="/Student" class="waves-effect"
              ><i class="fas fa-user-circle"></i><span> Perfil </span></router-link
            >
          </li> 
            <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-chalkboard-teacher	"></i>
              <span>
                Portal do Estudante
                <span class="float-right menu-arrow">
                  <i class="mdi mdi-chevron-right"></i>
                </span>
              </span>
            </a>
            <ul class="submenu">
              <li>
                <router-link to="/vagas-publicadas"><i class="mdi mdi-keyboard"></i> Feed Vagas</router-link>
              </li>
              <li>
                <router-link to="/candidaturas"><i class="mdi mdi-keyboard"></i> Minhas Candidaturas</router-link>
              </li>
              <li>
                <router-link to="/showAssessments-result"> <i class="mdi mdi-filter-variant "></i>Situação Académica</router-link>
              </li>
            

                
            </ul>
          </li> 

          <!-- <li>
            <router-link to="/update-password" class="waves-effect"
              ><i class="fas fa-key"></i>
              <span>
                Alterar Senha
              </span>
            </router-link>
          </li> -->
        </ul>
      </div>
      <!-- Sidebar -->
      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
</template>

<script>
export default {
  // let user = store.state.user.auth.page
};
</script>
