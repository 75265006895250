<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Alocar Professor</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Professores</li>
          <li class="breadcrumb-item active">Alocar Professores</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="row tab-content">
        <div id="list-view" class="tab-pane fade active show col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-8 col-lg-12 col-md-12">
                  <div class="card">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="card-header">
                          <h3 class="card-title">Perfil do Professor</h3>
                        </div>
                        <div class="card-body">
                          <ul class="list-unstyled">
                            <li class="mb-2">
                              <h4>
                                {{ userData.fullName }}
                              </h4>
                              <!-- <small >{{userData.email}}</small> -->
                            </li>
                            <li class="mb-2">
                              <strong>Sexo: </strong
                              ><span>{{ userData.gender }}</span>
                            </li>
                            <li class="mb-2">
                              <strong>Data de Nascimento: </strong
                              ><span>{{ userData.birthDate }}</span>
                            </li>
                            <li class="mb-2">
                              <strong>Endere&ccedil;o: </strong
                              ><span>{{ userData.address_location }}</span>
                            </li>
                            <li class="mb-2">
                              <strong>Formado na (o): </strong
                              ><span>{{ userData.last_school_frequency }}</span>
                            </li>
                            <li class="mb-2">
                              <strong class="text-muted">Detalhes</strong>
                            </li>

                            <li class="mb-2">
                              <strong>Deficiente: </strong
                              ><span>{{
                                is_deficient == 1 ? "Sim" : "Nao"
                              }}</span>
                            </li>

                            <li class="mb-2">
                              <strong>Detalhes da Deficiencia: </strong
                              ><span>{{
                                deficient_type == null
                                  ? "-----"
                                  : deficient_type
                              }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="card-header">
                          <h3 class="card-tittle">Mais Informações</h3>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div
                              class="col-lg-3 ml-2 p-2 mb-2 bg-card"
                              v-for="(ti, index) in teacher_info"
                              :key="index"
                            >
                              <div class="d-flex justify-content-end">
                                <span
                                  @click="
                                    removeClassmate(ti.classmate.id, index)
                                  "
                                  class="fa fa-times text-danger fa-1x"
                                ></span>
                              </div>
                              <ul class="list-unstyled">
                                <li class="mb-2">
                                  <h4>
                                    {{ ti.subject.name }}
                                  </h4>
                                  <span>{{ ti.course.name }} </span>
                                </li>
                                <li class="mb-2">
                                  <strong>Periodo: </strong
                                  ><span>{{ ti.classmate.period }}</span>
                                </li>

                                <li class="mb-2">
                                  <strong>Nivel Academico: </strong
                                  ><span>{{ ti.classmate.level.name }}</span>
                                </li>

                                <li class="mb-2">
                                  <strong>Turma: </strong
                                  ><span class="badge">
                                    {{ ti.classmate.name }}
                                    <!-- <span
                                        class="ml-2 btn-remove"
                                        @click="removeClassmate(ti.classmate.id, index)"
                                      >
                                        <i class="fas fa-times text-danger"></i>
                                      </span> -->
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <button
                            class="float-right btn btn-success btn-md"
                            @click="openModal(2)"
                          >
                            Alocar <i class="fas fa-plus text-white"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="isClose" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Alocar {{ title }}</h5>
            <button
              type="button"
              @click="closeModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div
                class="col-lg-12 col-md-12 col-sm-12"
                v-if="title == 'Turmas'"
              >
                <div class="form-group">
                  <label class="form-label">Selecione a Turma *</label>
                  <Select
                    v-model="classmatesDataAdd.classmate_id"
                    placeholder="Selecione a Turma"
                    disabled
                  >
                    <Option
                      :value="cl.id"
                      v-for="cl in classmatesData"
                      :key="cl.id"
                    >
                      {{ cl.name }}
                    </Option>
                  </Select>
                </div>
              </div>
            </form>
            <div class="container">
              <span class="mb-5" style="color:#444">Por favor selecione a turma que deseja alocar o professor</span>
              <div class="row">
                <div
                  class="col-lg-3 ml-2 p-2 mb-2 bg-card"
                  v-for="(cl, index) in classmatesData"
                  :key="index"
                >
                  <ul  style="cursor:pointer" class="list-unstyled" @click="selectClassmate(cl.id)">
                    <li class="mb-2">
                      <h4>
                        {{ cl.name }}
                      </h4>
                      <span>{{ cl.course.name }} </span>
                    </li>
                    <li class="mb-2">
                      <strong>Periodo: </strong><span>{{ cl.period }}</span>
                    </li>

                    <li class="mb-2">
                      <strong>Nivel Academico: </strong
                      ><span>{{ cl.level }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              v-if="title == 'Turmas'"
              type="button"
              @click="handleSubmitAlocateClassMate"
              class="btn btn-success"
            >
              {{ isAlocar == false ? "Alocar" : "Alocando..." }}
            </button>

            <button
              @click="closeModal"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Nao
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="block-screen" v-if="teacherLoadingData">
    <h2>Por favor, aguarde enquanto  carregamos a informaçao...</h2>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import TeacherStoreModule from "./TeacherStoreModule";
import { useRoute } from "vue-router";
import SubjectStoreModule from "../subject/SubjectStoreModule";
import toastr from "toastr";
import ClassMateStoreModule from "../Classmates/ClassMateStoreModule";
import DepartmentStoreModule from "../department/DepartmentStoreModule";
import permissionStoreModule from "../permissions/permissionStoreModule";

const userData = ref({
  fullName: "",
  address_location: "",
  alternative_email: "",
  gender: "",
  doc_type: "",
  doc_number: "",
  nactionality: "",
  birthDate: "",
  last_school_frequency: "",
  level_type: "",
  last_school_frequency_year: "",
  fathers_filliation: "",
  mothers_filliation: "",
  childhood_location: "",
  is_deficient: "",
  deficient_type: "",
  permissions: "",
  contact: "",
  alternative_contact: "",
  province_id: "",
  district_id: "",
  regimeSuperior: "",
});
const teacherLoadingData = ref(false);
const profileLoadingData = ref(false);

const department = ref({
  dep_name: "",
});

const classmates = ref([]);
const classmatesData = ref([]);
const subjects = ref([]);
const subjectAlocate = ref([]);
const isClose = ref(false);
const isAlocar = ref(false);
const title = ref("");

const subjectData = ref([]);
const departmentData = ref([]);
const permissions = ref([]);
const TeacherRole = ref();
const roles = ref([]);
const route = useRoute();
const teacher_info = ref([]);

const dataAlocate = {
  subject_id: "",
  subject_id: "",
  teacher_id: route.params.id_teacher,
};
const classmatesDataAdd = ref({
  classmate_id: "",
  subject_id: "",
  teacher_id: route.params.id_teacher,
});
onMounted(() => {
  ShowProfessor();
  getSubjects();
  // getAllDepartment();
  getAllClassmates();
  getRoles();
});

function selectClassmate(classmate_id) {
  classmatesDataAdd.value.classmate_id = classmate_id;
}

async function handleSubmitAlocateRole() {
  isAlocar.value = true;

  const resp = await TeacherStoreModule.actions.alocateRole(
    TeacherRole.value,
    route.params.id_teacher
  );

  isAlocar.value = false;
}
async function handleSubmitAlocate() {
  isAlocar.value = true;

  const resp = await TeacherStoreModule.actions.alocateData(dataAlocate);
  if (resp.status) {
    toastr.success(resp.message);
    ShowProfessor();
  } else {
    toastr.error("Ocorreu um erro ao tentar adicionar disciplinas");
  }
  // console.log(resp);
  isAlocar.value = false;
}

async function handleSubmitAlocateClassMate() {
  isAlocar.value = true;
  const resp = await TeacherStoreModule.actions.alocateTeacherToClassmate(
    classmatesDataAdd.value,
    route.params.id_teacher
  );

  console.log(resp.data);
  if (resp.data.success) {
    toastr.success(resp.data.success);
    ShowProfessor();
  } else {
    toastr.error("Ocorreu um erro ao tentar adicionar disciplinas");
  }
  // console.log(resp);
  isAlocar.value = false;
}

// async function getSubjectsByTeacherId(){

//   const resp = await TeacherStoreModule.actions.getSubjectsByTeacherId(route.params.id_teacher);
//   console.log(resp.data.data.subject);

// }

async function getRoles() {
  const resp = await TeacherStoreModule.actions.fetchRoles();
  roles.value = resp.data;
  userData.permission_id = resp.data[4].id;
  console.log(resp.data);
  // store
  //   .dispatch("Professor/fetchRoles")
  //   .then((response) => {
  //     roles.value = response.data;
  //     userData.permission_id = response.data[4].id;

  //     console.log(response.data);
  //     console.log(response.data[4].id);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
}

async function ShowProfessor() {
  try {
    teacherLoadingData.value = true;

    const resp = await TeacherStoreModule.actions.ShowTeacher(
      route.params.id_teacher
    );
    userData.value = resp.data.data.person;
    department.value = resp.data.data.department;
    subjectAlocate.value = resp.data.data.subject;
    classmates.value = resp.data.data.turma;
    teacher_info.value = resp.data.data.classmate_subject;
  } catch (error) {
    console.log(error);
  } finally {
    teacherLoadingData.value = false;
  }
}
async function getAllDepartment() {
  const resp = await DepartmentStoreModule.actions.getAlldepartment();
  departmentData.value = resp.data.data;
}

async function getAllClassmates() {
  const resp = await ClassMateStoreModule.actions.getTurmaWithDetails();
  classmatesData.value = resp.data.data;
}

async function getSubjects() {
  const resp = await SubjectStoreModule.actions.getSubjects();
  subjects.value = resp.data.data;
  classmatesDataAdd.value.subject_id = resp.data.data[0].id;
}

async function removeClassmate(classmate_id, index) {
  teacher_info.value.splice(index, 1);
  const resp = await TeacherStoreModule.actions.removeTeacherFromClassmate(
    classmate_id,
    route.params.id_teacher
  );
}

async function removeSubject(subject_id, index) {
  subjectAlocate.value.splice(index, 1);
  const resp = await TeacherStoreModule.actions.removeSubjectFromTeacher(
    subject_id,
    route.params.id_teacher
  );
}

async function removeRole() {}

function closeModal() {
  isClose.value = false;
}

function openModal(id) {
  if (id == 3) {
    title.value = "Disciplinas";
  } else if (id == 2) {
    title.value = "Turmas";
  } else if (id == 3) {
    title.value = "Departamentos";
  } else {
    title.value = "Previlegio";
  }
  isClose.value = true;
}

async function getAllpermissions() {
  const resp = await permissionStoreModule.actions.getAllPermissions();
  permissions.value = resp.data;
  console.log(resp.data);
}
</script>
<style scoped>
.departemanto {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 20px;
  margin-top: 14px;
  margin-left: 2px;
  background-color: #1a1c2a;
  color: #fff;
  width: 120px;
}

.bg-card {
  background-color: #1a1c2a;
  border-radius: 6px;
}

.bg-card span.fa.fa-times {
  cursor: pointer;
}

.departemanto span {
  color: #fff;
}
.btn-remove,
.addCursor {
  cursor: pointer;
}
</style>
