<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Todas Turmas</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Turmas</li>
          <li class="breadcrumb-item active">Lista Turmas</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">
              <h4 class="card-title">Lista de Turmas</h4>
            </div>

            <div class="col-sm-6">
              <router-link to="/classmates" class="btn btn-primary float-right">
                + Adicionar Turmas</router-link
              >
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" class="app-search">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
                v-model="searchQuery"
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table id="example5" class="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Criado por</th>
                  <th>Criado em</th>
                  <th>Açcões</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cl, index) in classMates" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ cl.name }}</td>
                  <td>{{ cl.creator }}</td>
                  <td>{{ cl.created_at }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'editClassMates',
                        params: { id_class: cl.id },
                      }"
                      class="btn btn-sm btn-primary"
                      ><i class="fas fa-edit"></i
                    ></router-link>
                    <a
                      @click="isdeleteTurma(cl.id, index)"
                      href="javascript:void(0);"
                      class="btn btn-sm btn-danger"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td>
                </tr>

                <tr>
                  <td
                    class="text-center"
                    colspan="4"
                    v-if="classMates.length == 0"
                  >
                    <span style="font-style: italic; text-align: center"
                      >Ainda não tem registros</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <Page
              :total="total"
              :model-value="current_page"
              :page-size="per_page"
              @on-change="getAllClassMates"
              class="float-right"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Eliminar</h5>
            <button
              type="button"
              @click="closeModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Tem certeza que deseja eliminar o Turma?</p>
          </div>
          <div class="modal-footer">
            <button type="button" @click="deleteTurma" class="btn btn-danger">
              {{ isDeleting == false ? "Eliminar" : "Eliminando..." }}
            </button>
            <button
              @click="closeModal"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Nao
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref, watch } from "vue";
import store from "@/store";
import ClassMateStoreModule from "./ClassMateStoreModule";
import SubjectStoreModule from "../subject/SubjectStoreModule";
export default {
  setup() {
    const TURMA_MODULE_NAME = "Turma";

    if (!store.hasModule(TURMA_MODULE_NAME)) {
      store.registerModule(TURMA_MODULE_NAME, ClassMateStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(TURMA_MODULE_NAME)) {
        store.unregisterModule(TURMA_MODULE_NAME);
      }
    });

    const isDelete = ref(false);
    const index = ref(null);
    const idTurma = ref(null);
    const isDeleting = ref(false);
    const total = ref();
    const per_page = ref();
    const current_page = ref();
    const classMates = ref([]);
    const searchQuery = ref("");

    async function getAllClassMates(page = 1) {
      await ClassMateStoreModule.actions
        .getAllTurmas({
          search: searchQuery.value,
          page: page,
        })
        .then((response) => {
          console.log(response.data);
          classMates.value = response.data.data;
          total.value = response.data.total;
          per_page.value = response.data.per_page;
          current_page.value = response.data.current_page;
        })
        .catch((error) => {});
    }

    watch(searchQuery, (newValue, oldValue) => {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        getAllClassMates();
      } else {
        if (newValue.length == 0 || oldValue.length == 0) {
          getAllClassMates();
        }
      }
    });
    function isdeleteTurma(id, i) {
      isDelete.value = true;
      index.value = i;
      idTurma.value = id;
    }

    async function deleteTurma() {
      isDeleting.value = true;

      const response = await ClassMateStoreModule.actions.deleteTurma(
        idTurma.value
      );
      if (response) {
        classMates.value.data.splice(index.value, 1);
        isDeleting.value = false;
        isDelete.value = false;
      }
      //  store
      //   .dispatch("Turma/deleteTurma", idTurma.value)
      //   .then((response) => {
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });

      // store
      //   .dispatch("Turma/deleteTurma", idTurma.value)
      //   .then((response) => {
      //      classMates.value.splice(index.value, 1);
      //     isDeleting.value = false;
      //     isDelete.value = false;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
    function closeModal() {
      isDelete.value = false;
    }

    return {
      getAllClassMates,
      classMates,
      deleteTurma,
      isDelete,
      closeModal,
      isdeleteTurma,
      index,
      idTurma,
      isDeleting,
      searchQuery,
    };
  },

  created() {
    this.getAllClassMates();
  },
};
</script>
