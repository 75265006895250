<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Detalhes</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Detalhes</li>
          <li class="breadcrumb-item active">Turma</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
         

        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nome Completo</th>
                  <th scope="col">Gênero</th>
                  <th scope="col">Estagio</th>
                  <th scope="col">Estagio Conluido</th>
                  <th scope="col">Aprovado</th>
                  <!-- <th scope="col">Dias em Falta</th> -->
                  <th scope="col">Acções</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(students, index) in dataStudents" :key="index">
                  <td>{{ students.id }}</td>
                  <td>{{ students.pessoa.fullName }}</td>
                  <td>{{ students.pessoa.gender }}</td>
                  <td v-if="students.isTrainess_accepted_by_student == '1'">
                    <span class="text-success">Estagiando</span>
                  </td>
                  <td v-else><span class="text-danger">Sem estagio</span></td>
                  <td v-if="students.trainess_concluded == '1'">
                    <span class="text-success">Concluido</span>
                  </td>
                  <td v-else><span class="text-danger">Nao</span></td>


                     <td v-if="students.status_assessment == '1'">
                    <span class="text-success">Aprovado</span>
                  </td>

                  <td v-else><span class="text-danger">Nao</span></td>
                  <!-- <td><span class="text-danger">0</span></td> -->


                  <td>
                    <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
                      <select
                        class="form-control"
                        style="border-radius: 16px"
                        @change="
                          handleEventsStudent($event, students.pessoa.id)
                        "
                      >
                        <option value="0">Selecione</option>
                        <option
                          v-if="(students.trainess_concluded == '1')"
                          value="confirm_conclude_trainess"
                        >
                          Confirmar Conclusao de Estagio
                        </option>
                       

                        <!-- <option v-else value="unprove_trainess">
                          Reprovar Conclusao de Estagio
                        </option> -->
                        <!-- <option value="addTrainess">
                          Adicionar ao Estagio
                        </option> -->
                        <option value="checkEvaluation">Estagiario Avaliado pela Empresa</option>
                        <option value="update">Editar</option>
                        <option value="view">Visualizar</option>
                        <option value="delete">Eliminar</option>
                      </select>
                    </div>
                    <!-- <router-link
                      :to="{
                        name: 'AddAssessments',
                        params: { id_classmate: students.turma.id },
                      }"
                      >Lancamentos</router-link
                    > -->
                  </td>
                </tr>
              </tbody>
            </table>

            <Page
              :total="total"
              :model-value="current_page"
              :page-size="parseInt(per_page)"
              @on-change="getStudentByClassMates"
              v-if="total"
              class="float-right"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isModalProfileCompany" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Informação do Estudante</h5>
          <button
            type="button"
            @click="closeModalProfileCompany"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#" method="post">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Empresa</label>
                  <p style="color: #444">{{ studentData.fullName }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto</label>
                  <p style="color: #444">(+258){{ studentData.contact }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto Alternativo</label>
                  <p style="color: #444">
                    (+258){{
                      studentData.alternative_contact == null
                        ? "----------------"
                        : studentData.alternative_contact
                    }}
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email</label>
                  <p style="color: #444">{{ studentData.alternative_email }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Bilhete de Identidade</label>
                  <p style="color: #444">{{ studentData.doc_number }}</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione Provincia</label>
                  <p style="color: #444">-------------------</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione Distrito</label>
                  <p style="color: #444">---------------------</p>
                </div>
              </div>

              <div class="col-lg-8 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Endereco/Bairro</label>
                  <p style="color: #444">{{ studentData.address_location }}</p>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModalProfileCompany"
            type="error"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Fechar
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Eliminar</h5>
          <button
            type="button"
            @click="closeModalDelete"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p style="color: #444">
            Tem certeza que deseja eliminar este registro?
          </p>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModalDelete"
            type="light"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Nao
          </Button>
          <Button type="error" @click="handleDelete" :loading="loading">
            Eliminar
          </Button>
        </div>
      </div>
    </div>
  </div>


   <div id="modal-overlay" v-show="modalStatus">
    <div class="mt-2 pb-3 bg-white">
      <div class="col-lg-3 form-group float-right m-2">
        <select
          v-model="year_filter"
          class="form-control col-lg-12 app-search"
          style="border-radius: 16px"
          @change="getstatusEvaluation($event)"
        >
          <option :value="y" v-for="(y, index) in years" :key="index">{{y}}</option>
          
        </select>
      </div>
      <div class="table-responsive table-bordered">
        <table id="example5" class="table table-hover" style="min-width: 845px">
          <thead>
            <tr>
              <th colspan="6">
                <span style="font-weight: bold">Grau de desempenho:</span>
                MB=Muito Bom, B=Bom, R=Regular I= Insatisfatorio
              </th>
            </tr>
            <tr>
              <th>Itens</th>
              <th>selecione as Opções</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(e, index) in studentEvaluate" :key="index">
              <td>{{ e.title }}</td>
              <td>
                <select
                  v-model="e.option"
                  class="form-control"
                  style="border-radius: 16px"
                >
                  <option disabled="true" value="Muito Bom">Muito Bom</option>
                  <option disabled="true" value="Bom">Bom</option>
                  <option disabled="true" value="Regular">Regular</option>
                  <option disabled="true" value="Insatisfatorio">Insatisfatorio</option>
                </select>
              </td>
            </tr>
            <tr class="text-center"  v-if="studentEvaluate.length == 0">
            <td colspan="2">
              <span class="text-center">Nenhum registro foi encontrada</span>
            </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <Button
          type="error"
          @click="CloseModalStatus"
          class="btn btn-success"
          data-dismiss="modal"
        >
          Fechar
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import TeacherDashStoreModule from "./TeacherDashStoreModule";
import { useRoute, useRouter } from "vue-router";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
import toastr from "toastr";
import StudentModuleService from "../students/StudentModuleService";
import StudentStoreModule from "../studentDashboard/StudentStoreModule";
import CompanyStoreModule from "../companyDashboard/CompanyStoreModule";

const dataStudents = ref([]);
var countTestPercent = ref(1);
var countTrabPercent = ref(1);
const route = useRoute();
const options = ref({
  responsive: true,
  maintainAspectRatio: false,
});
const isClose = ref(false);
const isModalEditCompany = ref(false);
const isModalProfileCompany = ref(false);
const isDelete = ref(false);
const loading = ref(false);
const isEdit = ref(false);
const total = ref();
const current_page = ref();
const per_page = ref();
const studentData = ref({});
const studentClassmate = ref({});
const router = useRouter();
const studentEvaluate = ref([]);
const year_filter = ref(new Date().getUTCFullYear())
const years = ref([]);
const modalStatus = ref(false);


async function getStudent(student_id) {
  const resp = await StudentModuleService.actions.viewEstudante(student_id);

  studentData.value = resp.data.dados_pessoais;
  console.log(resp.data.dados_pessoais);
}


async function checkEvaluationStatus(student_id) {
  try {
  // id_student.value = student_id
  console.log(student_id);
    modalStatus.value = true;


    const resp = await CompanyStoreModule.actions.checkEvaluationStatusTeacherClassmate(
      student_id,
      year_filter.value
    );

    if (resp.data.length > 0) {
      studentEvaluate.value = resp.data
      
    }else{
      toastr.info("Nao foi encontrado nenhum registro!");
    }

  } catch (error) {
    console.log(error);
  }
}

function CloseModalStatus(){
  modalStatus.value = false
}

function closeModal() {
  isClose.value = false;
}

function closeModalEditCompany() {
  isModalEditCompany.value = false;
}

function closeModalProfileCompany() {
  isModalProfileCompany.value = false;
}

function closeModalDelete() {
  isDelete.value = false;
}

const testDataEdit = ref({
  id: "",
  classmate_id: route.params.id_classmate,
  assessment_type: "",
  percentage: "",
});

const testData = ref({
  classmate_id: route.params.id_classmate,
  assessment_type: "",
  percentage: "",
});

const classmateFormula = ref([]);

const datatTeste = ref({
  testData: [
    {
      titulo: "",
      percentegemTeste1: "",
    },
  ],

  trabalho: [
    {
      titulo: "",
      percentegemTrabalho: 0,
    },
  ],
});

async function submitData() {
  loading.value = true;
  const resp = await TeacherDashStoreModule.actions.submitData(testData.value);

  if (resp.data.message) {
    toastr.success(resp.data.message);
    getFormula();
  } else {
    toastr.error(
      "Desculpe-nos ocorreu um erro ao tentar adicionar os dados, tente mais tarde..."
    );
  }

  loading.value = false;
}

async function getFormula() {
  const resp = await TeacherDashStoreModule.actions.getAllFormulas();
  classmateFormula.value = resp.data;
  console.log(resp);
}

async function removeDataTest(id, index) {
  const resp = await TeacherDashStoreModule.actions.DeleteDataTest(id);

  classmateFormula.value.splice(index, 1);
  console.log(resp);
}

async function editDataTest(id, index) {
  isEdit.value = true;
  classmateFormula.value.forEach((element) => {
    if (element.id === id) {
      testDataEdit.value.assessment_type = element.assessment_type;
      testDataEdit.value.percentage = element.percentage;
      testDataEdit.value.id = element.id;
    }
  });
}

async function updateTestData(id) {
  loading.value = true;

  const resp = await TeacherDashStoreModule.actions.updateFormula(
    testDataEdit.value,
    id
  );
  if (resp.data == 1) {
    getFormula();
    isEdit.value = false;
  }
  loading.value = false;
}

function addPercenteTeste() {
  countTestPercent.value += 1;
  datatTeste.value.testData.push({ percentegemTeste1: "" });
}

function remove(index) {
  datatTeste.value.testData.splice(index, 1);
  countTestPercent.value -= 1;
}

function addPercenteTrabalho() {
  countTrabPercent.value += 1;
  datatTeste.value.trabalho.push({ percentegemTrabalho: "" });
}

function removeTrabalho(index) {
  datatTeste.value.trabalho.splice(index, 1);
  countTrabPercent.value -= 1;
}

const data = {
  datasets: [
    {
      backgroundColor: ["#41B883", "#E46651"],
      data: [40, 20],
    },
  ],
  labels: ["Homens", "Mulheres"],
};

onMounted(() => {
  getStudentByClassMates();
});

async function getStudentByClassMates() {
  const resp = await TeacherDashStoreModule.actions.getStudentByClassMates(
    route.params.id_classmate
  );
  dataStudents.value = resp.data.data;
  total.value = resp.data.meta.total;
  current_page.value = resp.data.meta.current_page;
  per_page.value = resp.data.meta.per_page;
  console.log(resp.data.meta.current_page);
}

async function handleEventsStudent(event, student_id) {
  switch (event.target.value) {
    case "confirm_conclude_trainess":
      event.target.value = 0;

      const id = dataStudents.value.findIndex(
        (obj) => obj.person_id == student_id
      );

      if (id !== -1) {
        dataStudents.value[id].status_assessment = "1";
      }

      console.log("sds");
      StudentStoreModule.actions.approve_conclude_trainess(student_id);

      break;
    case "unprove_trainess":
      event.target.value = 0;

      const idd = dataStudents.value.findIndex(
        (obj) => obj.person_id == student_id
      );

      if (idd !== -1) {
        dataStudents.value[idd].status_assessment = "0";
      }
      StudentStoreModule.actions.unapprove_conclude_trainess(student_id);

      break;

    case "update":
      event.target.value = 0;

      router.push({ name: "editStudent", params: { id: student_id } });
      break;

    case "view":
      event.target.value = 0;
      isModalProfileCompany.value = true;
      getStudent(student_id);

      break;
      case "checkEvaluation":
      event.target.value = 0;
       checkEvaluationStatus(student_id)

      break;

    default:
      console.log("opcao nao encontrada");
      break;
  }
}
</script>

<style scoped>
.disabled {
  background: #ccc;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

#modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1400;
}
</style>