<template>
  <div id="estudantes">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashbord</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Inicio</a></li>
            <li class="breadcrumb-item">Vagas</li>
            <li class="breadcrumb-item active">Lista Vagas</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="form-group float-right m-2">
              <!-- <form action="" role="search" class="app-search">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Pesquisar..."
                  v-model="search"
                />
                <button type="submit"><i class="fa fa-search"></i></button>
              </form> -->
            </div>
            <div class="table-responsive">
              <table
                id="example5"
                class="table table-hover"
                style="min-width: 845px"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Contacto</th>
                    <th>Email</th>
                    <th>Título da Vaga</th>
                    <th>Estado da Candidatura</th>
                    <th>Estado de Avaliação do estudante</th>
                    <th>Acções</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(c, index) in candidates" :key="index">
                    <td>{{ 1 + index }}</td>
                    <td>{{ c.fullName }}</td>
                    <td>
                      {{ c.contact == null ? "--------------" : c.contact }}
                    </td>
                    <td>{{ c.email }}</td>
                    <td>{{ c.job_title }}</td>
                    <td v-if="c.accepted == 1">
                      <span class="text-success">Aprovado</span>
                    </td>
                    <td v-else><span class="text-warning">Pendente</span></td>
                    <td style="cursor: pointer">
                      <span @click="checkEvaluationStatus(c.id)"
                        >Estudante avaliado</span
                      ><span class="fas fa-question-circle"></span>
                    </td>
                    <td>
                      <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
                        <select
                          class="form-control"
                          style="border-radius: 16px"
                          @change="
                            handleEventsStudent($event, c.id, c.url_file_name)
                          "
                        >
                          <option value="0">Selecione</option>
                          <option
                            value="1"
                            v-if="
                              (c.accepted == '0' || c.accepted == '2') &&
                              (roleUser == 'Superadmin' ||
                                roleUser == 'Company')
                            "
                          >
                            Aprovar Candidato
                          </option>

                          <option
                            value="2"
                            v-if="
                              c.accepted == '1' &&
                              (roleUser == 'Superadmin' ||
                                roleUser == 'Company')
                            "
                          >
                            Desaprovar Candidato
                          </option>
                          <option
                            @click="openModalResume(c.url_file_name)"
                            value="3"
                            v-if="
                              roleUser == 'Superadmin' || roleUser == 'Company'
                            "
                          >
                            Previsualizar Curriculo
                          </option>
                          <option
                            @click="evaluateStudent(c.id)"
                            value="4"
                            v-if="
                              roleUser == 'Superadmin' || roleUser == 'Company'
                            "
                          >
                            Avaliar estudante
                          </option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="candidates.length == 0">
                    <td colspan="8" class="text-center" style="font-style:italic">Ainda não tem registros...!</td>
                  </tr>
                </tbody>
              </table>
              <Page
                :total="total"
                :model-value="current_page"
                :page-size="parseInt(per_page)"
                @on-change="getcompanyCandidates"
                v-if="total"
                class="float-right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="modal-overlay" v-show="isModal">
    <div class="mt-2 pb-3 bg-white">
      <embed
        :src="urlLocalHost + setFileReader"
        type="application/pdf"
        style="width: 210mm; height: 600px"
      />
      <div class="modal-footer">
        <button
          type="button"
          @click="closeModalResume"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>

  <div id="modal-overlay" v-show="isEvaluateModal">
    <div class="mt-2 pb-3 bg-white">
      <div class="table-responsive table-bordered">
        <table id="example5" class="table table-hover" style="min-width: 845px">
          <thead>
            <tr>
              <th colspan="6">
                <span style="font-weight: bold">Grau de desempenho:</span>
                MB=Muito Bom, B=Bom, R=Regular I= Insatisfatorio
              </th>
            </tr>
            <tr>
              <th>Itens</th>
              <th>selecione as Opções</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(e, index) in dataEvaluate" :key="index">
              <td>{{ e.title }}</td>
              <td>
                <select
                  v-model="e.option"
                  class="form-control"
                  style="border-radius: 16px"
                >
                  <option value="Muito Bom">Muito Bom</option>
                  <option value="Bom">Bom</option>
                  <option value="Regular">Regular</option>
                  <option value="Insatisfatorio">Insatisfatorio</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <Button
          type="button"
          @click="evaluateStudentServer"
          class="btn btn-success"
          data-dismiss="modal"
          :loading="loading"
        >
          Submeter Avaliação
        </Button>
      </div>
    </div>
  </div>

  <div id="modal-overlay" v-show="modalStatus">
    <div class="mt-2 pb-3 bg-white">
      <div class="col-lg-3 form-group float-right m-2">
        <select
          v-model="year_filter"
          class="form-control col-lg-12 app-search"
          style="border-radius: 16px"
          @change="getstatusEvaluation($event)"
        >
          <option :value="y" v-for="(y, index) in years" :key="index">{{y}}</option>
          
        </select>
      </div>
      <div class="table-responsive table-bordered">
        <table id="example5" class="table table-hover" style="min-width: 845px">
          <thead>
            <tr>
              <th colspan="6">
                <span style="font-weight: bold">Grau de desempenho:</span>
                MB=Muito Bom, B=Bom, R=Regular I= Insatisfatorio
              </th>
            </tr>
            <tr>
              <th>Itens</th>
              <th>selecione as Opções</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(e, index) in studentEvaluate" :key="index">
              <td>{{ e.title }}</td>
              <td>
                <select
                  v-model="e.option"
                  class="form-control"
                  style="border-radius: 16px"
                >
                  <option disabled="true" value="Muito Bom">Muito Bom</option>
                  <option disabled="true" value="Bom">Bom</option>
                  <option disabled="true" value="Regular">Regular</option>
                  <option disabled="true" value="Insatisfatorio">Insatisfatorio</option>
                </select>
              </td>
            </tr>
            <tr class="text-center"  v-if="studentEvaluate.length == 0">
            <td colspan="2">
              <span class="text-center">Nenhum registro foi encontrada</span>
            </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <Button
          type="error"
          @click="CloseModalStatus"
          class="btn btn-success"
          data-dismiss="modal"
        >
          Fechar
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import store from "@/store";
import { onMounted, ref } from "vue";
import CompanyStoreModule from "./CompanyStoreModule";
import jobStoreModule from "../Jobs/jobStoreModule";
import toastr from "toastr";

const setFileReader = ref(null);
const urlLocalHost = ref("https://traineesystem.jcompany.tech/Api/assets/upload_docs/");
const isModal = ref(false);
const candidates = ref([]);
const roleUser = ref(null);
const total = ref();
const per_page = ref("");
const current_page = ref("");
const isEvaluateModal = ref(false);
const selectedOptions = ref([]);
const loading = ref(false);
const id_student = ref();
const modalStatus = ref(false);
const studentEvaluate = ref([]);
const year_filter = ref(new Date().getUTCFullYear())
const years = ref([]);
const entry_year = ref(new Date().getUTCFullYear()); 
const dataEvaluate = ref([
  { title: "Participacao", option: "" },
  { title: "Iniciativas", option: "" },
  { title: "Capacidade de Aprender", option: "" },
  { title: "Aceitacao de Responsabilidade", option: "" },
  { title: "Pontualidade e Frequencia", option: "" },
  { title: "Cumprimentos de Normas Internas", option: "" },
  { title: "Relacionamento com a Empresa/Instituicao", option: "" },
  { title: "Relacionamento com os Superiores", option: "" },
  { title: "Relacionamento com os colegas", option: "" },
]);

async function evaluateStudentServer() {
  try {
    loading.value = true;

    dataEvaluate.value.forEach((element) => {
      if (element.option == "") {
        toastr.info("Por favor preencha todos os campos!");
        return;
      }
    });
    const resp = await CompanyStoreModule.actions.evaluateStudentServer(
      dataEvaluate.value,
      id_student.value
    );

    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      isEvaluateModal.value = false;
      dataEvaluate.value.forEach((element) => {
        element.option = "";
      });
    }

     if (resp.data.Existstatus == true) {
      toastr.info(resp.data.message);
      isEvaluateModal.value = false;
     
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
  console.log(dataEvaluate.value);
}

async function checkEvaluationStatus(student_id) {
  try {
  id_student.value = student_id
    modalStatus.value = true;


    const resp = await CompanyStoreModule.actions.checkEvaluationStatus(
      student_id,
      year_filter.value
    );

    if (resp.data.length > 0) {
      studentEvaluate.value = resp.data
      
    }else{
      toastr.info("Nao foi encontrado nenhum registro!");
    }

  } catch (error) {
    console.log(error);
  }
}

function CloseModalStatus(){
  modalStatus.value = false
}

function getYearTilCurrent(entry_year) {
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year >= entry_year; year--) {
    years.value.push(year);
  }
}

async function getstatusEvaluation(event){
  try{
     event.target.value = 0;
     console.log(id_student.value);
    const resp = await CompanyStoreModule.actions.checkEvaluationStatus(
      id_student.value,
      year_filter.value
    );

    if (resp.data.length > 0) {
      studentEvaluate.value = resp.data
      
    }else{
      studentEvaluate.value = []
      toastr.info("Nao foi encontrado nenhum registro!");
    }

  } catch (error) {
    console.log(error);
  }
   
}

function openModalResume(file_name) {
  isModal.value = true;
  setFileReader.value = file_name;
}
function closeModalResume() {
  isModal.value = false;
  setFileReader.value = null;
}

function evaluateStudent(student_id) {
  isEvaluateModal.value = true;
  id_student.value = student_id;
}

async function getcompanyCandidates() {
  try {
    const resp = await jobStoreModule.actions.getcompanyCandidates();
    candidates.value = resp.data.data;
    // entry_year.value = resp.data.data[0].entry_year;
    if(resp.data.data.length > 0){
      getYearTilCurrent(resp.data.data[0].entry_year)

    }
    total.value = resp.data.meta.total;
    per_page.value = resp.data.meta.per_page;
    current_page.value = resp.data.meta.current_page;
    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
  }
}

async function handleEventsStudent(event, student_id, url_file_name) {
  switch (event.target.value) {
    case "1":
      event.target.value = 0;

      const id = candidates.value.findIndex((obj) => obj.id == student_id);

      if (id !== -1) {
        candidates.value[id].accepted = 1;
      }
      jobStoreModule.actions.approve_candidate(student_id);
      break;
    case "2":
      event.target.value = 0;

      const idd = candidates.value.findIndex((obj) => obj.id == student_id);

      if (idd !== -1) {
        candidates.value[idd].accepted = 2;
      }
      jobStoreModule.actions.unapprove_candidate(student_id);

      break;

    case "3":
      event.target.value = 0;

      openModalResume(url_file_name);
      break;

    case "4":
      event.target.value = 0;
      id_student.value = student_id
      evaluateStudent(student_id);
      break;

    default:
      console.log("opcao nao encontrada");
      break;
  }
}

onMounted(() => {
  if (store.state.auth.authenticated == true) {
    roleUser.value = store.state.auth.user.data.role[0];
  }
  getcompanyCandidates();
   
});
</script>

<style scoped>
.disabled {
  background: #ccc;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

#modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1400;
}
</style>