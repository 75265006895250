import axios from "axios";
import authHeader from "@/Api/AuthHeader";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: { 
    async createRoles(formData) {
        try {
          const response = await axios.post("/api/roles", formData, {
            headers: authHeader(),
          });
          return response;
        } catch (error) {
          return error;
        }
      },

    getAllRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get("api/roles", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async deleteRole(id) {

         try {
          const response = await axios.delete("api/roles/" + id, {
            headers: authHeader(),
          });
          return response;
        } catch (error) {
          return error;
        }
      },
  },
};
