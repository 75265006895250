<template>
  <div id="estudantes">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashbord</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Inicio</a></li>
            <li class="breadcrumb-item">Vagas</li>
            <li class="breadcrumb-item active">Lista Vagas</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>

    <div class="container">
      <div class="col-lg-12">
        <div class="tab-content mx-auto">
          <div id="list-view" class="tab-pane fade active show col-lg-12">
            <div
              v-if="jobs.length > 0"
              class="pb-5 slimscroll-menu"
              id="remove-scroll"
            >
              <div
                class="card p-2 col-lg-8 mx-auto"
                style="background-color: #dae4ea"
                v-for="(v, index) in jobs"
                :key="index"
              >
                <div class="card-title"></div>

                <div class="card-body">
                  <h1>{{ v.title }}</h1>
                  <small><i class="fa fa-city"></i> {{ v.company_name }}</small>
                  |
                  <small> {{ v.course_name }}</small
                  ><br />
                  <small
                    ><i class="fa fa-user"></i>&nbsp;Supervisor:
                    {{ v.supervisor.fullName }}</small
                  >
                  |
                  <small
                    ><a
                      @click="openModalResume(v.url_file_name)"
                      href="javascript:void(0)"
                      >curriculo</a
                    ></small
                  >

                  <br />
                  <p style="font-size: 13px">
                    <i class="fa fa-map-marker"></i> Local:
                    {{ v.province_name }}, {{ v.district_name }}
                  </p>

                  <p style="font-size: 12px" class="mt-2 text-danger">
                    <i class="fa fa-calendar"></i>Prazo de Candidaturas:
                    {{ v.deadline }}
                  </p>
                  <p style="text-align: justify" class="mt-3">
                    {{ v.description }}
                  </p>
                </div>

                <div
                  v-if="roleUser != 'Teacher' && roleUser != 'Student'"
                  class="d-flex card-footer justify-content-end"
                >
                  <small @click="deleteJob(v.id,index)" style="cursor: pointer" class="mr-1"
                    ><i class="fa fa-trash text-danger"></i
                    >&nbsp;Eliminar</small
                  >
                  <router-link v-if="roleUser == 'Superadmin'" :to="{name:'editJob',params:{id:v.id}}" style="cursor: pointer" class="mr-1"
                    ><i class="fa fa-pen text-info"></i>&nbsp;Actualizar</router-link
                  >
                  <router-link v-if="roleUser ==  'Company'" :to="{name:'edit-job',params:{id:v.id}}" style="cursor: pointer" class="mr-1"
                    ><i class="fa fa-pen text-info"></i>&nbsp;Actualizar</router-link
                  >
                </div>

                <div
                  v-if="roleUser == 'Student'"
                  class="d-flex card-footer justify-content-end"
                >
                  <Button
                    v-if="v.student_job == Logged_student_id"
                    @click="cancel_job(v.id, index)"
                    style="cursor: pointer"
                    class="mr-1"
                    ><i class="fa fa-wallet text-danger"></i>&nbsp;Cancelar
                    candidatura</Button
                  >

                  <Button
                    v-else
                    @click="apply_job(v.id, index)"
                    style="cursor: pointer"
                    class="mr-1"
                    ><i class="fa fa-wallet text-info"></i
                    >&nbsp;Candidatar-me</Button
                  >
                </div>
              </div>

              <Page
                :total="total"
                :model-value="current_page"
                :page-size="parseInt(per_page)"
                @on-change="getAllCompany"
                v-if="total"
                class="text-center mb-5"
              />
            </div>
                 <div  style="background-color: #dae4ea" class=" p-3 card text-center" v-else>
               <span style="font-style: italic; text-align: center"
                          >Ainda não tem registros</span
                        >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isClose" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Pdido de Vaga</h5>
          <button
            type="button"
            @click="closeModal"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#" method="post">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Assunto</label>
                  <input
                    type="text"
                    class="form-control"
                    value="Pedido de Vaga"
                    disabled
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Empresa*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="requestForm.company_name"
                    placeholder="Descrição"
                  />
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="requestForm.company_email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descrição*</label>
                  <textarea
                    type="text"
                    rows="5"
                    class="form-control"
                    v-model="requestForm.message"
                    placeholder="Descrição"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModal"
            type="error"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Não
          </Button>
          <Button
            type="info"
            class="btn btn-primary float-right"
            @click="handleSubmit"
            :loading="loading"
          >
            Enviar
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isModalEditCompany" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Actualizar Dados da Empresa</h5>
          <button
            type="button"
            @click="closeModalEditCompany"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#" method="post">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Empresa*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.company_name"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.company_contact"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto Alternativo</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.company_optional_contact"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.company_email"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nuit*</label>
                  <input
                    type="email"
                    class="form-control"
                    v-model="form.nuit"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione Provincia*</label>
                  <select
                    class="form-control"
                    v-model="province_id"
                    @change="ChangeProvince"
                  >
                    <option value="Class">Selecione Provincia</option>
                    <option
                      :value="p.id"
                      v-for="(p, index) in provinces"
                      :key="index"
                    >
                      {{ p.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione Distrito*</label>
                  <select class="form-control" v-model="form.district_id">
                    <option value="Class">Selecione o Distrito</option>
                    <option
                      :value="d.id"
                      v-for="(d, index) in districtData"
                      :key="index"
                    >
                      {{ d.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-8 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Endereco da Empresa*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.company_address"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModalEditCompany"
            type="error"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Não
          </Button>
          <Button
            type="info"
            class="btn btn-primary float-right"
            @click="handleUpdateCompany"
            :loading="loading"
          >
            Actualizar
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isModalProfileCompany" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Informação da Empresa</h5>
          <button
            type="button"
            @click="closeModalProfileCompany"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#" method="post">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Empresa</label>
                  <p style="color: #444">{{ form.company_name }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto</label>
                  <p style="color: #444">(+258){{ form.company_contact }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto Alternativo</label>
                  <p style="color: #444">
                    (+258){{
                      form.company_optional_contact == null
                        ? "----------------"
                        : form.company_optional_contact
                    }}
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email</label>
                  <p style="color: #444">{{ form.company_email }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nuit</label>
                  <p style="color: #444">{{ form.nuit }}</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione Provincia</label>
                  <p style="color: #444">{{ province_name }}</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione Distrito</label>
                  <p style="color: #444">{{ district_name }}</p>
                </div>
              </div>

              <div class="col-lg-8 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Endereço da Empresa</label>
                  <p style="color: #444">{{ form.company_address }}</p>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModalProfileCompany"
            type="error"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Não
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Eliminar</h5>
          <button
            type="button"
            @click="closeModalDelete"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p style="color: #444">
            Tem certeza que deseja eliminar esta vaga?
          </p>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModalDelete"
            type="light"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Não
          </Button>
          <Button type="error" @click="handleDelete" :loading="loading">
            Eliminar
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isModalStudentJob" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Eliminar</h5>
          <button
            type="button"
            @click="closeModalStudent_job"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p style="color: #444">
            Tem certeza que deseja cancelar a candidatura?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="deleteStudent_job()"
            class="btn btn-danger"
          >
            {{ isDeleting == false ? "Eliminar" : "Eliminando..." }}
          </button>
          <button
            @click="closeModalStudent_job"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Não
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="modal-overlay" v-show="isModal">
    <div class="mt-2 pb-3 bg-white">
      <embed
        :src="urlLocalHost + setFileReader"
        type="application/pdf"
        style="width: 210mm; height: 600px"
      />
      <div class="modal-footer">
        <button
          type="button"
          @click="closeModalResume"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>


</template>

<script setup>
import store from "@/store";
import axios from "axios";
import toastr from "toastr";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import StudentModuleService from "../students/StudentModuleService";
import jobStoreModule from "./jobStoreModule";
import "jquery-slimscroll/jquery.slimscroll.min"; // Importe o SlimScroll

const urlLocalHost = ref("https://traineesystem.jcompany.tech/Api/assets/upload_docs/");
const modalContentRef = ref();
const isModal = ref(false);
const jobs = ref([]);
const router = useRouter();
const total = ref();
const per_page = ref("");
const current_page = ref("");
const isClose = ref(false);
const isModalEditCompany = ref(false);
const isModalProfileCompany = ref(false);
const isDelete = ref(false);
const loading = ref(false);
const provinces = ref([]);
const province_id = ref();
const district_name = ref("");
const province_name = ref("");
const delete_id = ref();
const index_id = ref();
const districtData = ref([]);
const requestForm = ref({
  title: "Pedido de Vaga",
  company_name: "",
  company_email: "",
  message: "",
});
const roleUser = ref("");
const jobsByUser = ref([]);
const Logged_student_id = ref(null);
const isModalStudentJob = ref(false);
const setFileReader = ref(null);
const deleteModal = ref(false)
const form = ref({
  company_id: "",
  company_name: "",
  company_email: "",
  company_contact: "",
  company_optional_contact: "",
  nuit: "",
  company_address: "",
  district_id: "",
});

function openModalResume(file_name) {
  isModal.value = true;
  setFileReader.value = file_name;
}
function closeModalResume() {
  isModal.value = false;
  setFileReader.value = null;

}


function deleteJob(id, index){
  isDelete.value = true;
  delete_id.value = id;
  index_id.value = index;
}

async function getJobsByUser() {
  try {
    loading.value = true;
    const resp = await jobStoreModule.actions.jobsByUser();
    jobsByUser.value = resp.data;

    console.log(resp.data);
  } catch (error) {}
}

function checkId(jobs) {
  var id = null;
  jobs.value.forEach((element) => {
    id = element.student_id;
  });

  return id;
}

 

async function deleteStudent_job(student_job, index) {
  try {
    loading.value = true;

    const response = await jobStoreModule.actions.cancel_job(student_job);
    if (response) {
      jobs.value[index].student_job = null;
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function handleDelete() {
  try {
    loading.value = true;

    jobs.value.splice(index_id.value, 1);
    const resp = await jobStoreModule.actions.removeJob(
      delete_id.value
    );

    if (resp.data.status == 1) {
      isDelete.value = false;
    } else {
      isDelete.value = false;
    }
    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  isDelete.value = false;

  }
}
async function handleUpdateCompany() {
  try {
    loading.value = true;

    const resp = await CompanyStoreModule.actions.updateCompany(form.value);

    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      getAllCompany();
    } else {
      toastr.info(resp.data.message);
    }
    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function getAlljobs(page = 1) {
  const resp = await jobStoreModule.actions.getJobs(page);
  jobs.value = resp.data.data;
  total.value = resp.data.meta.total;
  per_page.value = resp.data.meta.per_page;
  current_page.value = resp.data.meta.current_page;
  console.log(resp);
}

async function handleSubmit() {
  try {
    loading.value = true;
    const resp = await CompanyStoreModule.actions.requestVaga(form.value);

    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function getRequestCompany(id) {
  try {
    const resp = await CompanyStoreModule.actions.show(id);

    requestForm.value = resp.data;
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

async function apply_job(job_id, index) {
  try {
    loading.value = true;
    jobs.value[index].student_job = Logged_student_id;
    const resp = await jobStoreModule.actions.apply_job(job_id);
    console.log(resp.data.candidated);
    if (resp.data.candidated == true) {
      return toastr.info(resp.data.message);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}
function closeModalStudent_job() {
  isModalStudentJob.value = false;
}

async function cancel_job(job_id, index) {
  try {
    loading.value = true;
    isModalStudentJob.value = true;
    jobs.value[index].student_job.splice(index, 1);
    const resp = await jobStoreModule.actions.cancel_job(job_id);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function getCompany(id) {
  try {
    const resp = await CompanyStoreModule.actions.show(id);
    console.log(resp.data.data[0]);
    getDistrictsByprovinceIdd(resp.data.data[0].district.province_id);
    province_id.value = resp.data.data[0].district.province_id;
    district_name.value = resp.data.data[0].district.name;
    province_name.value = resp.data.data[0].province;
    form.value = resp.data.data[0];
  } catch (error) {
    console.log(error);
  }
}

function closeModal() {
  isClose.value = false;
}

function closeModalEditCompany() {
  isModalEditCompany.value = false;
}

function closeModalProfileCompany() {
  isModalProfileCompany.value = false;
}

function closeModalDelete() {
  isDelete.value = false;
}

async function handleEventsStudent(event, company_id) {
  switch (event.target.value) {
    case "request":
      event.target.value = 0;
      getRequestCompany(company_id);
      //   router.push({ name: "pedido", params: { id: company_id } });
      isClose.value = true;
      break;
    case "update":
      event.target.value = 0;
      isModalEditCompany.value = true;
      getCompany(company_id);
      //   router.push({ name: "editCompany", params: { id: company_id } });
      break;
    case "active":
      event.target.value = 0;
      const resp = await CompanyStoreModule.actions.activeUser(company_id);

      companyData.value.forEach((element) => {
        if (element.company_id == company_id) {
          element.is_active = 1;
        }
      });

      if (resp.data.status == true) {
        toastr.success(resp.data.message);
      } else {
        toastr.info(resp.data.message);
      }

      break;
    case "disable":
      event.target.value = 0;

      companyData.value.forEach((element) => {
        if (element.company_id == company_id) {
          element.is_active = 0;
        }
      });
      const respDisable = await CompanyStoreModule.actions.disableUser(
        company_id
      );

      if (respDisable.data.status == true) {
        toastr.success(respDisable.data.message);
      } else {
        toastr.info(respDisable.data.message);
      }

      break;

    case "view":
      event.target.value = 0;
      isModalProfileCompany.value = true;
      getCompany(company_id);
      break;

    case "delete":
      event.target.value = 0;
      isDelete.value = true;
      delete_id.value = company_id;

      break;

    default:
      console.log("opcao não encontrada");
      break;
  }
}

async function getProvinces() {
  const resp = await StudentModuleService.actions.getProvinces();
  provinces.value = resp.data;
}

async function getDisitrictbyId(province_id) {
  await axios
    .get("/api/district/" + province_id)
    .then((response) => {
      districtData.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

async function ChangeProvince(evt) {
  await axios
    .get("/api/district/" + evt.target.value)
    .then((response) => {
      districtData.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

async function getDistrictsByprovinceIdd(id) {
  const resp = await CompanyStoreModule.actions.districtsByProvinceId(id);
  districtData.value = resp.data;
}

onMounted(() => {
  if (store.state.auth.authenticated == true) {
    roleUser.value = store.state.auth.user.data.role[0];

    // console.log(store.state.auth.user.data.student );
  if (store.state.auth.user.data.role[0] == "Student") {
    Logged_student_id.value = store.state.student.student.contas_estudante[0].id;
  }
  }


  // if (store.state.auth.user.data.role[0] == "Company") {
  //    Logged_student_id.value = store.state.auth.user.data.student.id;
  // }
// console.log(store.state.student.student.contas_estudante[0].id);
  getAlljobs();
  // getJobsByUser();
  //   getProvinces();
});
</script>


<style scoped>
.disabled {
  background: #ccc;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

#modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1400;
}
</style>