<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <th scope="col">Accoes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, index) in inscriptionsData" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ i.person }}</td>
          <td>{{ i.course }}</td>
          <td>{{ i.level }}</td>
          <td>{{ i.academic_year }}&#176;</td>
          <td>{{ i.semester }}</td>
          <td>{{ i.inscriptionType }}</td>
          <td>{{ i.ano_entrada }}</td>
          <td>
            {{ i.account_entity == null ? "---------" : i.account_entity }}
          </td>
          <td>
            {{ i.acount_reference == null ? "----------" : i.acount_reference }}
          </td>
          <td v-if="i.isActive == 0">
            <span class="badge badge-danger">Inactivo</span>
          </td>
          <td v-else><span class="badge badge-success">Activo</span></td>
          <td>
            <div>
              <a href="#" class="btn btn-primary btn-sm">Edit</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import CourseStoreModule from "../Courses/CourseStoreModule";
import StudentModuleService from "../students/StudentModuleService";
import TrainessStoreModule from "./TrainessStoreModule";


const trainess = ref([]);
const searchData = ref({
  level: null,
  academic_year: null,
  period: null,
  course_id: null,
  entry_year: null,
  status: "Todos",
});
const courses = ref([]);
const academicYear = ref([]);
const levels = ref([]);
const years = ref([]);

async function getTrainess() {

    try {
        const resp = await TrainessStoreModule.actions.getTrainess(searchData.value);

        console.log(resp);
    } catch (error) {
        
    }

}
 
onMounted(() => {
 getTrainess();
});
</script>