<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Jassa</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Detalhes dos Cursos</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="sendData">
            <div class="row">
              <div class="col-lg-12 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome do Curso</label>
                  <input type="text" class="form-control"  v-model="formData.name"/>
                </div>
              </div>    
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descrição do Curso</label>
                  <textarea class="form-control" rows="5" v-model="formData.description"></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <button type="submit" class="btn btn-primary float-right">{{ loading == false ? 'Adicionar' : 'Adicionando...' }} </button>
                <!-- <button type="submit" class="btn btn-light">Voltar</button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onUnmounted } from "vue";
  import {mapGetters} from "vuex";
  import CoursesStoreModule from "./CourseStoreModule"
  import store from "@/store";
  import toastr from "toastr";


export default {


  setup(){
    const CURSO_MODULE_NAME ="Curso";

    if(!store.hasModule(CURSO_MODULE_NAME)){
      store.registerModule(CURSO_MODULE_NAME, CoursesStoreModule);
    }



    onUnmounted(()=>{
      if(store.hasModule(CURSO_MODULE_NAME)){
        store.unregisterModule(CURSO_MODULE_NAME);
      }
    });

    const formData = {
      name : "",
      description : "",
      department_id: null
    };

    

    const departments = ref(null);
    const loading = ref(false);


    function getAllDepartment(){
      store
        .dispatch("Curso/getDepartments")
        .then((response)=>{
        console.log(response.data)
        departments.value = response.data.data
      })
      .catch((error)=>{
        console.log(error)
      });
    }

    async function sendData(){

      try {
        
        loading.value = true;
        if(formData.name == "" || formData.description == ""){
          toastr.warning("Por favor preencha os dados obrigatorios");
          return;
        }
  
        const response = await CoursesStoreModule.actions.createCourse(formData);
        
        console.log(response);
            toastr.success(response.data.success)
            loading.value = false
            clearFields();
  
   
      } catch (error) {
        console.log(error);
      }finally{
        loading.value = false
      }

    }

    function clearFields(){

      formData.name = "";
      formData.description = "";
      formData.department_id = "";
    }
  
    return {
      getAllDepartment,
      departments,
      loading,
      formData,
      sendData
    };
  },
  computed:{
    ...mapGetters(["token"])
  },
  created(){
    this.getAllDepartment();
  }

  

};
</script>
