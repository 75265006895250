<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Supervisores</li>
          <li class="breadcrumb-item active">Lista Supervisores</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="row tab-content">
        <div id="list-view" class="tab-pane fade active show col-lg-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-sm">
                  <h4 class="card-title">Todos Supervisores</h4>
                </div>

                <div class="col-sm-6">
                  <router-link
                    to="/add-supervisor"
                    class="btn btn-primary float-right"
                    >+ Adicionar Supervisor</router-link
                  >
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table id="example3" class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome</th>
                      <th>Sexo</th>
                      <th>Contacto</th>
                      <th>Email</th>
                      <th>Acções</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sup, index) in dataSupervisors" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ sup.fullName }}</td>
                      <td>{{ sup.gender }}</td>
                      <td>
                        {{
                          sup.contact == null
                            ? "-----------------"
                            : sup.contact
                        }}
                      </td>
                      <td>
                        {{
                          sup.email == null ? "--------------------" : sup.email
                        }}
                      </td>
                      <td>{{ sup.created_at }}</td>
                      <td>
                        <router-link
                          v-if="roleUser == 'Company'"
                          :to="{
                            name: 'updateSupervisor',
                            params: { id: sup.id },
                          }"
                          class="btn btn-sm btn-primary"
                          ><i class="fas fa-edit"></i></router-link
                        >
                         <router-link
                          v-else
                          :to="{
                            name: 'editSupervisor',
                            params: { id: sup.id },
                          }"
                          class="btn btn-sm btn-primary"
                          ><i class="fas fa-edit"></i></router-link
                        >
                        
                        &nbsp;
                        <a
                          @click="isdeleteSupervisor(sup.id, index)"
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger"
                          ><i class="fas fa-trash-alt"></i
                        ></a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center"
                        colspan="6"
                        v-if="dataSupervisors.length == 0"
                      >
                        <span style="font-style: italic; text-align: center"
                          >Ainda não tem registros  </span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Page
                  :total="total"
                  :model-value="current_page"
                  :page-size="parseInt(per_page)"
                  @on-change="getsupervisors"
                  v-if="total > 0"
                  class="float-right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Eliminar</h5>
          <button
            type="button"
            @click="closeModal"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p style="color: #444">
            Tem certeza que deseja eliminar este supervisor?
          </p>
        </div>
        <div class="modal-footer">
          <Button
            type="error"
            :loading="isDeleting"
            @click="deleteSupervisor()"
            class="btn btn-danger"
          >
            Eliminar
          </Button>
          <button
            @click="closeModal"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Nao
          </button>
        </div>
      </div>
    </div>
  </div>




  
</template>

<script setup>
import store from "@/store";
import { onMounted, ref, watch } from "vue";
import SupervisorStoreModule from "./SupervisorStoreModule";

const dataSupervisors = ref([]);
const search = ref("");
const total = ref(null);
const current_page = ref(1);
const per_page = ref(10);
const isDelete = ref(false);
const index = ref(null);
const supervisor_id = ref();
const isDeleting = ref(false);
const roleUser = ref();
function closeModal() {
  isDelete.value = false;
}

async function getsupervisors(page = 1) {
  const resp = await SupervisorStoreModule.actions.getsupervisors({
    page: page,
    search: search.value,
  });
  dataSupervisors.value = resp.data.data;
  total.value = resp.data.meta.total;
  current_page.value = resp.data.meta.current_page;
  per_page.value = resp.data.meta.per_page;
}

function isdeleteSupervisor(id, i) {
  console.log(id);
  isDelete.value = true;
  index.value = i;
  supervisor_id.value = id;
}

async function deleteSupervisor() {
  try {
    isDeleting.value = true;

    dataSupervisors.value.splice(index.value, 1);
    const response = await SupervisorStoreModule.actions.deleteSupervisor(
      supervisor_id.value
    );
    isDelete.value = false;
  } catch (error) {
    console.log(error);
  } finally {
    isDeleting.value = false;
  }
}
watch(search, (newValue, oldValue) => {
  if (newValue.length >= 4 || oldValue.length >= 4) {
    getAllCourse();
  } else if (newValue.length == 0 || oldValue.length == 0) {
    getAllCourse();
  }
});

onMounted(() => {
  if (store.state.auth.authenticated == true) {
    roleUser.value = store.state.auth.user.data.role[0];
  }
  getsupervisors();
});
</script>
