<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <!-- <h4 class="page-title">Departamento: {{ departamento.dep_name }}</h4> -->
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item">
            <router-link to="/">Inicio</router-link>
          </li>
          <li class="breadcrumb-item">Estudante</li>
          <li class="breadcrumb-item active">Perfil</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-sm-12 col-md-12">
      <div class="card">
        <div class="row">
          <div class="col-12 col-md-12 col-sm-12 col-lg-4">
            <div class="card-header">
              <h3 class="card-title">Perfil pessoal</h3>
            </div>
            <div class="card-body">
              <ul class="list-unstyled" v-if="myProfileData.person !== null">
                <li class="mb-2">
                  <h4 class="">
                    {{ person.fullName }}
                    <span class="rounded-pill"> <i class="fa fa-pen"></i></span>
                  </h4>
                  <small class="text-center">{{ person.email }} </small>
                </li>
                <li class="mb-2">
                  <strong class="">Bilhete de Identidade: </strong
                  ><span class="">{{ person.doc_number }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Data de Nascimento: </strong
                  ><span class="">{{ person.birthDate }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Endere&ccedil;o: </strong
                  ><span class="">{{ person.address_location }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">G&eacute;nero: </strong
                  ><span class="">{{ person.gender }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Formado na (o): </strong
                  ><span class="">{{ person.last_school_frequency }}</span>
                </li>

                <li class="mb-2">
                  <strong class="">Meu Resumo: </strong>
                </li>
                <div
                  style="text-align: center; width: 200px"
                  class="imgContainer"
                >
                  <div
                    v-if="attachment"
                    class="box"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  >
                    <embed
                      v-if="previewURL == null"
                      class="image"
                      :src="
                        'https://traineesystem.jcompany.tech/Api/assets/upload_docs/' +
                        attachment.url_file_name
                      "
                      type="application/pdf"
                      width="200"
                    />

                    <embed
                      v-else
                      class="image"
                      :src="previewURL"
                      type="application/pdf"
                      width="200"
                    />

                    <div class="middle">
                      <div class="btnTem">
                        <span class="text-center fa fa-eye"></span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="box"
                    data-aos="zoom-in"
                    data-aos-delay="300"
                  > 
                    <embed
                       class="image"
                      :src="previewURL"
                      type="application/pdf"
                      width="200"
                    />

                    <div class="middle">
                      <div class="btnTem">
                        <span class="text-center fa fa-eye"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <div class="d-flex d-block">
                <Button type="primary" @click="selectFiles" class="btn btn-primary btn-md">
                  Carregar o Resumo
                </Button>

                <Button
                  type="success"
                  :loading="loading"
                  @click="addResume"
                  v-show="previewURL != null"
                  class="btn btn-success btn-md ml-1"
                >
                  Actualizar
                </Button>

                <!-- <button class="btn btn-warning btn-md ml-1">
                  Actualizar dados do perfil <i class="fa fa-pen"></i>
                </button> -->
              </div>
              <input
                name="file"
                type="file"
                class="file"
                :ref="fileInpu"
                @change="onChange"
                accept="application/pdf"
                style="visibility: hidden; width"
              />
            </div>
          </div>
          <div class="col-12 col-lg-8 col-md-12 col-sm-12">
            <div class="card-header">
              <h3 class="card-title">Informa&ccedil;&otilde;es adicionais</h3>
            </div>
            <div class="card-body">
              <ul class="list-unstyled" v-if="classmate !== null">
                <li class="mb-2">
                  <strong class="">Nome Da Turma: </strong
                  ><span class="">{{ classmate.name }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Periodo: </strong
                  ><span class="">{{ classmate.period }}</span>
                </li>

                <li class="mb-2">
                  <strong class="">Curso: </strong
                  ><span class="">{{ course_name }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Nivel: </strong
                  ><span class="">{{level_name }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Ano De Turma: </strong
                  ><span class="">{{ classmate.entry_year }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Descri&ccedil;ao: </strong
                  ><span class="">{{ classmate.description }}</span>
                </li>
              </ul>

              <!-- <table class="table">
                <tr>
                  <th style="width:20%">Disciplinas que leciona</th>
                  <td>
                    <span class="justify-content-between">
                     </span> 
                  </td>
                </tr>
                <tr>
                  <th>Turmas alocadas</th>
                  <td>
                    <span class="justify-content-between">
                      
                     
                    </span>
                  </td>
                </tr>
               
                
              </table> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-xl-12 col-xxl-8 col-lg-8">
      <div class="card">
        <div class="card-header justify-content-center">
          <h2 class="card-title">Actualmente estagiando</h2>
        </div>
        <div class="card-body">
          <table class="table table-bordeless table-stripped">
            <thead>
              <tr>
                <th>Empresa</th>
                <th>Periodo</th>
                <th>Em curso</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>UPM</td>
                <td>3 Meses</td>
                <td><span class="text-success"> Sim</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import store from "@/store";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";
import StudentStoreModule from "./StudentStoreModule";
import toastr from "toastr";
import StudentModuleService from "../students/StudentModuleService";
import { useStore } from "vuex";

const person = ref({});
const departamento = ref({});
const user = ref({});
const disciplinas = ref({});
const classmate = ref({});
const myProfileData = ref({});
const course = ref({});
const level = ref({});
const attachment = ref({});
const previewURL = ref(null);
const myRef = ref();
const fileInput = ref();
const loading = ref(false);
// const url_path_static = ref(
//   "http://localhost:8080/assets/icons/attachment.svg"
// );
const url_path_static = ref(
  "https://traineesystem.jcompany.tech/assets/icons/attachment.svg"
);
const st = useStore();
const level_name = ref("");
const course_name = ref(""); 
const form = ref({
  doc_file: "",
  is_student: true,
});

// Fazendo a manipulacao do elemento do DOM
const fileInpu = (element) => {
  myRef.value = element;
};

const selectFiles = () => {
  myRef.value.click();
};

function onChange(e) {
  form.value.doc_file = e.target.files[0];
  url_path_static.value = URL.createObjectURL(form.value.doc_file);
  previewDocument();
}

function previewDocument() {
  if (form.value.doc_file) {
    const reader = new FileReader();

    reader.onload = (event) => {
      previewURL.value = event.target.result;
    };

    reader.readAsDataURL(form.value.doc_file);
  }
}

async function addResume() {
  try {
    loading.value = true;

    const resp = await StudentStoreModule.actions.addResume(form.value);
    console.log(resp);
    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      form.value.doc_file = null;
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function getStudentProfile() {
  try {
    const response = await StudentStoreModule.actions.getMyDatas();

    person.value = response.person;
    attachment.value = response.attachment;
    classmate.value = response.classmate, 
    course_name.value = response.classmate.course.name;
    level_name.value = response.classmate.level.name

console.log(response.classmate.level.name);
     const student = await StudentModuleService.actions.viewEstudante(
      response.id
      );


  st.dispatch("student/student", student.data)
   } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getStudentProfile();
});
</script>
<style scoped>
.box {
  box-shadow: 10px 2px 10px 6px rgba(0, 0, 0, 0.2);
}

.imgContainer {
  position: relative;
  margin-bottom: 4%;
  width: 100%;
  height: 200px;
  cursor: pointer;
}

.image {
  width: 100%;
  height: 200px;
  transition: 0.5s ease;
  cursor: pointer;
}

.imgContainer:hover .image {
  opacity: 0.3;
  cursor: pointer;
  cursor: pointer;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}

.imgContainer:hover .middle {
  opacity: 1;
  cursor: pointer;
}
</style>