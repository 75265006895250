export const EN = {

    BarMenu: {
        Home:"Home",
        Sell:"Sell",
        Buy:"Buy",
        Account: "My Account",
        Languages: "Languages",
        Login:"Login",
        Logout:"Logout",
        Credentials:"Credentials",
        MyProduct: "My Products",
        Product: "Products",
        Add: "Add",
        List: "List",
        Categories: "Categories",
        Categorie: "Categorie",
        Agents: "Agents",
        Profile: "Profile",
        Agent:"Agent",
        ProporsalClients:'Proposal'




    },
    FormLogin: {
        ForgotPassword: "Forgot Password",
        RemberMe: "Remember Me",
        Password: "Password of at least 8 digits, containing uppercase, lowercase, and digits ",
        AccessAccount: "Log in to your account",
        SignUp: "You don't have account yet",
        clickTitle: "Click Here",
        Access: "Login",
        Accessing: "Login",
        recoverEmail:"Retrieve your password",
        update:"update",
        updating:"updating..."

    },

    HomePage: {
        FindYourProduct:"Find your Product",
        RealState: "Real Estate",
        Land:"Land",
        News:"News",
        SearchProductInput:'Where? Neighborhood or City or Province'
    },
 
    
    Footer:{
        aboutSite: "Are you looking for a space or want to buy a trespass, we are the ideal solution.",
        Links:{
            Home:"Home",
            ProductDateils:"Product Dateils",
            Agents:"Agents",
            ContactUs: "Contact Us",
            HelpUs:"Help us improve the platform"
        },
        MyAccount:{
            MyAccount:"My Account",
            WhyTransapss: "Why - Traversing terrain",
            Contacts: "Contacts",
            PolicePrivicy:"Privacy Policy",
            AcessYouData:"Access to your personal data",
            PrivacyState:"Privacy Statement",
            CondOfUse:"Conditions of use",
            SafetyTips:"Safety Tips",
            Newsletter: "Newsletter",
            KeepInformed:"We want to keep you informed of our news sign up",
            YourEmail:"Your Email",
            SignUp:"Sign up",
            FallowUs:"Fallow-Us" 
        }
    },

    PageDateils:{
        Description:"Description",
        Dateils:"Dateils",
        Divisions:"Divisions",
        ContactAgent: "Contact Agent",
        Comment:"Comments",
        Bedroom: "Bedrooms",
        Lift: "Lift",
        Parking: "Park",
        ProductAvailableRent: "Products Available For Rent",
        ProductAvailableSell: "Products Available For Sell"
    },

    PageFilter: {
        InfoStatusMessage: "There are no properties that meet your search criteria!",
        InfoMessageSmall: "Please try a new search.",
        FilterBtnText: "Filters",
        ServiceBtnText: "Service P",
        Price: "Price",
        Location: "Location",
        DefineIntervalPrice: "Set your price range",
        BtnAply: "Aply",
        BtnRent: "Rent",
        MoreFilterTitle: "More Filters",
        WcLabel: "Bathrooms",
        AreaLabel: "Area m2",
        Lift: "Lift",
        MarketState: "Market State",
        CategorieRealEstate: "Property Category",
        Published: "Published",
        ServicePersonalized: "Customized Services",
        ProductCategorie: "Product Category",
        RentOrBuy: "Rent/Buy",
        Title: "Title",
        district: "Select District",
        ProvinceLabel: "Select Province",
        PlaceHolderCategorie: "Please select the category",
        PlaceHolderCategorieRentOrBuy: "Please Select if You Want to Rent/Buy",
        PlaceHolderContact: "Inform your contact",
        PlaceHolderEmail: "Enter your email",
        Loading: "Loading more products",
        Any: "Any",
        MarketStateLabel: "Select Market State",
        CleanTitle: "Clean",
        DescriptionP: "Please Leave Your Description here, it also mentions the nearest infrastructures you want to facilitate our agents...",
        Send:"Send",
        Cancel: "Cancel",
        Description: "Description",
        Description: "Description", 
        SelectTime: "Please select the time",   
    },
    AdminDashBoard: {
        TotalProduct: "Total Products",
        TotalAgents: "Total Agents",
        ProductSell: "Products Sell",
        ProductRent: "Products Rent",
        Sell: "Sell",
        Rent: "Rent",
        LastFiveProducts: "Last 5 Product",
        Type: "Type",
        Categorie: "Categorie",
        Available: "Available",
        Date: "Date",
        Neighborhood: "Neighborhood",
        DragAndDropPlaceholder: "Drag and drop the image here or",
        DragAndDropPlaceholderAgent: "Drag and drop the profile image here or",
        DragAndDrop: "Drag and drop the image",
        DragAndDropAgent: "Drag and drop the profile image",
        DragAndDropPlaceholderV: "Drag and drop the video",
        DragAndDropPlaceholderVideo: "Drag and drop the video or",
        Choose: "Choose",
        Floor: "Piso",
        BuildYear: "Year of construction",
        BruteArea: "Gross Area*",
        Parking: "Parking lot",
        Back: "Back",
        AreaLoteTotal: "Total Plot Area",
        AreaUtil: "Usable Area",
        Submit:"Submit",
        Next:"Next"


    },

    FormAgents: {
        Title: "Register New Agent",
        CompleteName: "Complete Name",
        BirthDate: "Birth Date",
        Gender: "Gender",
        MaritalStatus: "Marital Status",
        BI: "BI",
        AlternativeContact: "Alternative Contact*"
    }

  



}