<template>
  <div id="wrapper">
    <MyNavbarVue />
    <MySideBarStundent />
    <div class="content-page">
      <div class="content">
        <RouterView></RouterView>
      </div>
    </div>
  </div>
</template>

<script>
import MyNavbarVue from "@/layouts/MyNavbar.vue";
import MySideBarStundent from "@/layouts/MySideBarStundent.vue";

export default {
  components: {
    MyNavbarVue,
    MySideBarStundent,
  },
};
</script>