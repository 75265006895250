<template>
  <div class="authincation h-100">
    <div class="container h-100">
      <div class="car col-lg-8 mx-auto m-2">
        <div class="row justify-content-center h-100 p-5 align-items-center">
          <div class="car col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <h4 class="text-center mb-4">Actualizar a senha</h4>
                    <form @submit.prevent="handleSubmitButton">
                      <div class="form-group">
                        <label class="ml-0"><strong>Nova Senha</strong></label>
                        <input
                          type="password"
                          class="form-control col-lg-12 mx-auto"
                          v-model="nova_senha"
                        />
                      </div>
                      <div class="form-group">
                        <label class="ml-0"
                          ><strong>Confirmar Senha</strong></label
                        >
                        <input
                          type="password"
                          class="form-control col-lg-12 mx-auto"
                          v-model="confirmar_senha"
                        />
                      </div>
                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2 col-lg-5"
                      ></div>
                        <div class="text-center mb-2">
                        <button
                          type="button"
                          class="btn-block"
                          style="
                            background-color: #1a73e8;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                          v-on:click="handleSubmitButton"
                          :disabled="loading"
                        >
                          {{ loading == false ?  $t('FormLogin.update') : $t('FormLogin.updating')}}
                        </button>
                      </div>
                    </form>
                        <div class="new-account mt-3">
                      <p>
                        Acesse a sua conta?
                        <router-link style="color: #1a73e8" to="/login">Clique aqui</router-link>
                      </p>
                    </div>
                 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { mapActions } from "vuex";
import toastr from "toastr";
import axios from "axios";
import store from "@/store";
import authHeader from "@/Api/AuthHeader";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

const route = useRoute();
const nova_senha = ref("");
const confirmar_senha = ref("");
const token = ref(route.params.token);
const router = useRouter();
const loading = ref(false);

async function handleSubmitButton() {
  loading.value = true;

  if (
    nova_senha.value == "" ||
    confirmar_senha.value == ""
   ) {
    loading.value = false;

    return toastr.info("Por favor preencha todos os campos");
  }

  if (nova_senha.value != confirmar_senha.value) {
    loading.value = false;

            return toastr.info("A senha de confirmação fornecida não está correta");

  }
  await axios
    .post("/Api/api/update_recover_password", {"nova_senha":nova_senha.value, "confirmar_senha":confirmar_senha.value, "token":token.value})
    .then(({ data }) => {
        if (data == 1) {
        toastr.success("A sua senha foi alterada com sucesso...");
        router.push("/login");
        nova_senha.value = "";
        confirmar_senha.value = "";
      } else if (data == 0) {
        toastr.error(
          "Ocorreu um erro ao tentar recuperar a senha!"
        );
      }
    })
    .catch((response) => {
      toastr.error(
        "Ocorreu um erro ao tentar recuperar a senha" +
          response.data
      );
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
