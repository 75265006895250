<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <th scope="col">Curso</th>
          <th scope="col">Nivel Academico</th>
          <th scope="col">Ano Academico</th>
          <th scope="col">Semestre</th>
          <th scope="col">Tipo de Inscricao</th>
          <th scope="col">Ano de Entrada</th>
          <th scope="col">Entidade</th>
          <th scope="col">Referencia</th>
          <th scope="col">Estudante Activo</th>
          <th scope="col">Accoes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, index) in inscriptionsData" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ i.person.fullName }}</td>
          <td>{{ i.course }}</td>
          <td>{{ i.level }}</td>
          <td>{{ i.academic_year }}&#176;</td>
          <td>{{ i.semester }}</td>
          <td>{{ i.inscriptionType }}</td>
          <td>{{ i.ano_entrada }}</td>
          <td>
            {{ i.account_entity == null ? "---------" : i.account_entity }}
          </td>
          <td>
            {{ i.acount_reference == null ? "----------" : i.acount_reference }}
          </td>
          <td v-if="i.isActive == 0">
            <span class="badge badge-danger">Inactivo</span>
          </td>
          <td v-else><span class="badge badge-success">Activo</span></td>
          <td>
            <div>
              <!-- <a href="#" class="btn btn-primary btn-sm">Edit</a> -->
               <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
                <select
                  class="form-control"
                  style="border-radius: 16px"
                  @change="handleCandidate"
                >
                  <option value="0">Selecione</option>
                  <option value="Aproved">Aprovar Candidatura</option>
                  <option value="dined">Reprovar Candidatura</option>
                 
                </select>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div></template>
<script setup>
import { onMounted, ref } from "vue";
import InscriptionStoreModule from "./InscriptionStoreModule"

    const inscriptionsData = ref([]);

    onMounted(()=>{
        getCandidateInscriptions();
    })

    async function getCandidateInscriptions(){
        let tipo_inscricao = "candidaturas";
        const resp = await InscriptionStoreModule.actions.getCandidateInscriptions();
        inscriptionsData.value = resp.data.data;
    }

    async function handleCandidate(event){
      console.log(event.target.value)
    }
</script>