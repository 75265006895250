import axios from "axios";
import authHeader from "@/Api/AuthHeader";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    createTeacher(ctx, TeacherData) {
      return new Promise((resolve, reject) => {
        axios
          .post("api/professor", TeacherData, { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async fetchProvinces() {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get("/api/provinces", { headers: authHeader() })
      //     .then((response) => resolve(response))
      //     .catch((error) => reject(error));
      // });

      try {
        const response = await axios.get("/api/provinces/", {
          headers: authHeader(),
        });

        return response;
      } catch (error) {
        return error;
      }
    },

    getAllCourses() {
      return new Promise((resolve, reject) => {
        axios
          .get("api/getCourse", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async fetchDistricts(province_id) {

      try {

        const resp = await axios.get('/api/district/'+province_id, {headers:authHeader()});
        return resp; 
      } catch (error) {
        return error;
      }
    
    },

    async fetchRoles() {

      try {
        const resp = await axios.get('/api/roles', {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
      }
    },

    
    async getRoleByUser(user_id) {

        try {
          const resp = await axios.get('/api/getRoleByUser/'+user_id, {headers:authHeader()});
          return resp;
        } catch (error) {
          return error;
        }
      },
      
    
    async alocateRole(roleData, user_id){

      try {
        const resp = await axios.post('/api/addUserRole',{'role':roleData, 'user_id':user_id}, {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
      }
    },

    async addPermissions(permissionsData, user_id){
        try {
            const resp = await axios.post('/api/addPermissions',{'permissions':permissionsData, 'user_id':user_id}, {headers:authHeader()});
            return resp;
          } catch (error) {
            return error;
          }
    },

    getAllTeachers() {
      return new Promise((resolve, reject) => {
        axios
          .get("api/professor", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async ShowTeacher(teacher_id){
      try {
        const response = await axios.get("/api/professor/" + teacher_id, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    async updateTeacher(teacher_id, formData){

      console.log(formData);
      try {
        const response = await axios.put("/api/professor/"+teacher_id, formData, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }

    },

    async alocateData(dataAlocate, teacher_id){

      try {
          const resp = await axios.post('/api/alocateDataProfessor', {'data':dataAlocate, 'id':teacher_id}, {
            headers:authHeader()
          });

          return resp;
      } catch (error) {
        return error;
      }
    }, 

 
   

    async getEmployes(){
        try {
            const resp = await axios.get('/api/listAdminUser', {headers:authHeader()});
            return resp;
          } catch (error) {
            return error;
            
          }

    }


 
  },
};
