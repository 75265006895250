import axios from "axios";
import authHeader from "@/Api/AuthHeader";

// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // createTurma(ctx, TurmaData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post("api/turmas", TurmaData, { headers: authHeader() })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    async createTurma(TurmaData) {
      try {
        const response = await axios.post(URL_API+"turmas", TurmaData, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    getDepartment() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+"getAlldepartment", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getAllTurmas(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+'turmas?page='+queryParams.page+'&search='+queryParams.search, { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // Get All classmates without pagination
    getTurmas() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+'getTurmas', { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      });
    },

    getTurmaWithDetails(){
      return axios.get(URL_API+'getTurmaWithDetails', { headers: authHeader() })
  
    },

    getAcademicYear() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+'academic_year', { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      });
    },

    // updateTurma(ctx, dataTurma) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .put("/api/turmas/" + dataTurma.id, dataTurma.data, {
    //         headers: authHeader(),
    //       })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    async updateTurma(dataTurma) {
      try {
        const response = await axios.put(URL_API+"turmas/" + dataTurma.id,
          dataTurma.data,
          {
            headers: authHeader(),
          }
        );

        return response;
      } catch (error) {
        return error;
      }
    },

    // viewClassMates(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get("/api/turmas/" + id, { headers: authHeader() })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    async viewClassMates( id ) {
      try {
        const response = await axios.get(URL_API+"turmas/" + id, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    async deleteTurma(id) {
      
      try {
        const response = await axios.delete(URL_API+"turmas/" + id, { headers: authHeader() })
        return response;
      } catch (error) {
        return error;
        
      }
       
    },

    // deleteTurma(id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .delete("/api/turmas/" + id, { headers: authHeader() })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },
  },
};
