<template>
  <div class="topbar">
    <!-- LOGO -->
    <div class="topbar-left">
      <a href="#" class="logo">
        <span class="logo-light">
          <img src="assets/images/up.png" width="50" height="50" alt="" />
        </span>
        <span class="logo-sm">
          <i class="mdi mdi-camera-control"></i>
        </span>
      </a>
    </div>
    <nav class="navbar-custom">
      <ul class="navbar-right list-inline float-right mb-0">
         <!-- <li
          class="dropdown notification-list list-inline-item d-none d-md-inline-block"
        >
          <a
            class="nav-link dropdown-toggle arrow-none waves-effect"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <img
              src="assets/images/us_flag.jpg"
              class="mr-2"
              height="12"
              alt=""
            />
            English <span class="mdi mdi-chevron-down"></span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right dropdown-menu-animated language-switch"
          >
            <a class="dropdown-item" href="#"
              ><img
                src="assets/images/french_flag.jpg"
                alt=""
                height="16"
              /><span> French </span></a
            >
            
         
      
          </div>
        </li> -->

        <!-- notification -->
        <li class="dropdown notification-list list-inline-item">
          <a
            class="nav-link dropdown-toggle arrow-none waves-effect"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <i class="mdi mdi-bell-outline noti-icon"></i>
            <span class="badge badge-pill badge-danger noti-icon-badge">{{totalNotifications}}</span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-menu-lg px-1"
          >
            <!-- item-->
           
            <h6 class="dropdown-item-text">Notificações</h6>
            <div class="slimscroll notification-item-list" v-for="(n, index) in notifications" :key="index">
              <!-- item--> 
              <router-link href="javascript:void(0);"  v-if="roleUser == 'Student'"  :to="{name:'notification', params:{id:n.id}}"  class="dropdown-item notify-item limitar-linhas" >
                 
                <div class="notify-icon bg-success">
                  <i class="mdi mdi-message-text-outline"></i>
                </div>
                <div class="notify-details limitar-linhas ">
                  <b>Nova Mensagem recebida</b
                  >   
                  <br>
                    {{n.description}} 
                    <br>
                    <span style="font-style:italic; font-size:10px;" class="text-warning" v-if="n.read_notification == 0">Não  Lida</span>
                    <span style="font-style:italic; font-size:10px" class="text-info" v-else>Lida</span>
                </div>
 
              </router-link>
               <router-link href="javascript:void(0);"  v-if="roleUser == 'Superadmin'"  :to="{name:'notificationAdmin', params:{id:n.id}}"  class="dropdown-item notify-item limitar-linhas" >
                 
                <div class="notify-icon bg-success">
                  <i class="mdi mdi-message-text-outline"></i>
                </div>
                <div class="notify-details limitar-linhas ">
                  <b>Nova Mensagem recebida</b
                  >   
                  <br>
                    {{n.description}} 
                    <br>
                    <span style="font-style:italic; font-size:10px;" class="text-warning" v-if="n.read_notification == 0">Não  Lida</span>
                    <span style="font-style:italic; font-size:10px" class="text-info" v-else>Lida</span>
                </div>
 
              </router-link>
               <router-link href="javascript:void(0);"  v-if="roleUser == 'Teacher'"  :to="{name:'notificationTeacher', params:{id:n.id}}"  class="dropdown-item notify-item limitar-linhas" >
                 
                <div class="notify-icon bg-success">
                  <i class="mdi mdi-message-text-outline"></i>
                </div>
                <div class="notify-details limitar-linhas ">
                  <b>Nova Mensagem recebida</b
                  >   
                  <br>
                    {{n.description}} 
                    <br>
                    <span style="font-style:italic; font-size:10px;" class="text-warning" v-if="n.read_notification == 0">Não  Lida</span>
                    <span style="font-style:italic; font-size:10px" class="text-info" v-else>Lida</span>
                </div>
 
              </router-link>
               <router-link href="javascript:void(0);"  v-if="roleUser == 'Student'"  :to="{name:'notificationCompany', params:{id:n.id}}"  class="dropdown-item notify-item limitar-linhas" >
                 
                <div class="notify-icon bg-success">
                  <i class="mdi mdi-message-text-outline"></i>
                </div>
                <div class="notify-details limitar-linhas ">
                  <b>Nova Mensagem recebida</b
                  >   
                  <br>
                    {{n.description}} 
                    <br>
                    <span style="font-style:italic; font-size:10px;" class="text-warning" v-if="n.read_notification == 0">Não  Lida</span>
                    <span style="font-style:italic; font-size:10px" class="text-info" v-else>Lida</span>
                </div>
 
              </router-link>
              
            </div>
            <!-- All-->
            <router-link  v-if="roleUser == 'Student'"  :to="{name:'AllNotification'}"
              href="javascript:void(0);"
              class="dropdown-item text-center notify-all text-primary"
            >
              Visualizar Totas <i class="fi-arrow-right"></i>
            </router-link>
             <router-link  v-if="roleUser == 'Company'"  :to="{name:'AllNotificationCompany'}"
              href="javascript:void(0);"
              class="dropdown-item text-center notify-all text-primary"
            >
              Visualizar Totas <i class="fi-arrow-right"></i>
            </router-link>
             <router-link  v-if="roleUser == 'Superadmin'"  :to="{name:'AllNotificationAdmin'}"
              href="javascript:void(0);"
              class="dropdown-item text-center notify-all text-primary"
            >
              Visualizar Totas <i class="fi-arrow-right"></i>
            </router-link>
             <router-link  v-if="roleUser == 'Teacher'"  :to="{name:'AllNotificationTeacher'}"
              href="javascript:void(0);"
              class="dropdown-item text-center notify-all text-primary"
            >
              Visualizar Totas <i class="fi-arrow-right"></i>
            </router-link>
          </div>
        </li>
        <li class="dropdown notification-list list-inline-item">
          <div class="dropdown notification-list nav-pro-img">
            <a
              class="dropdown-toggle nav-link arrow-none nav-user"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                src="assets/images/userProfile.svg"
                alt="user"
                class="rounded-circle"
              />
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-dropdown">
              <!-- item-->
              <router-link class="dropdown-item" to="/profile"
                ><i class="mdi mdi-account-circle"></i> Perfil</router-link
              >

              <router-link v-if="roleUser == 'Superadmin'" class="dropdown-item d-block" to="/update_credetials"> <i class="mdi mdi-key"></i> Alterar Senha</router-link>
              <router-link v-else-if="roleUser == 'Company'" class="dropdown-item d-block" to="/update-password"> <i class="mdi mdi-key"></i> Alterar Senha</router-link>
              <router-link v-else-if="roleUser == 'Student'" class="dropdown-item d-block" to="/updatepassword"> <i class="mdi mdi-key"></i> Alterar Senha</router-link>
              <router-link v-else class="dropdown-item d-block" to="/update_password"> <i class="mdi mdi-key"></i> Alterar Senha</router-link>

              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item text-danger"
                href="javascript:void(0)"
                @click="logout"
                ><i class="mdi mdi-power text-danger"></i> Sair</a
              >
            </div>
          </div>
        </li>
      </ul>
      <ul class="list-inline menu-left mb-0">
        <li class="float-left">
          <button class="button-menu-mobile open-left waves-effect">
            <i class="mdi mdi-menu"></i>
          </button>
        </li>
        <li class="d-none d-md-inline-block">
          <form role="search" class="app-search">
            <div class="form-group mb-0">
              <input type="text" class="form-control" placeholder="Search.." />
              <button type="submit"><i class="fa fa-search"></i></button>
            </div>
          </form>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import NotificationsStoreModule from "@/pages/Notifications/NotificationsStoreModule";
import { mapActions } from "vuex";
import store from '@/store';
export default {
  data() {
    return {
      user: this.$store.state.auth.user,
      notifications:[],
      totalNotifications:0,
      loading:false,
      roleUser:null
    };
  },
  methods: {
    ...mapActions({
      signOut: "auth/logout",
    }),
    async logout() {
      this.signOut();
      localStorage.removeItem("user");
      this.$router.push({ name: "login" });
    },

    async getNotifications() {
      try {
        const resp =
          await NotificationsStoreModule.actions.getFirstFiveNotificatioinsByLoggedUser();

          this.notifications = resp.data;
        console.log(resp);
      } catch (error) {
        console.log(error);
      }finally{
        this.loading = false
      }
    },

      async getTotalNotifications() {
      try {
        const resp =
          await NotificationsStoreModule.actions.getTotalNotificatioinsByLoggedUser();

          this.totalNotifications = resp.data;
        console.log(resp);
      } catch (error) {
        console.log(error);
      }finally{
        this.loading = false
      }
    },
  },

  created(){
    this.getNotifications();
    this.getTotalNotifications()

    if (store.state.auth.authenticated == true) {
      this.roleUser = store.state.auth.user.data.role[0];
    }
  }
};
</script>

<style scoped>
.limitar-linhas {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
