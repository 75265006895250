import axios from "axios";
import authHeader from "@/Api/AuthHeader";

const URL_API =  process.env.VUE_APP_API_URL
// const URL_API = "http://localhost:8000/api/";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getSemesters() {

        try {
          const resp = await axios.get(URL_API+'semesters', { headers: authHeader() });  
          return resp;

        } catch (error) {
            return error;
        }
       
    },

    async addActivities(data){

        try {
           const resp = await axios.post(URL_API+"activities", data, {headers:authHeader()});
           
           return resp;

        } catch (error) {

            return error;
        }
    },

    async listAll(page){
      try {
          const resp =  await axios.get(URL_API+`activities?page=${page}`, {headers:authHeader()})
          return resp;
      } catch (error) {
        return error;
      }
    },
    deleteActivite(id){

      return axios.delete(URL_API+`activities/${id}`, {headers:authHeader()});
    }
  }
}