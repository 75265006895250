<template>
  <div id="estudantes">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Todas Estudantes</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Inicio</a></li>
            <li class="breadcrumb-item">Estudantes</li>
            <li class="breadcrumb-item active">Lista Estudantes</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="row tab-content">
          <div id="list-view" class="tab-pane fade active show col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-sm-6">
                    <h4 class="card-title">Lista de Todos Estudantes</h4>
                  </div>

                  <div class="col-sm-6">
                    <router-link
                      :to="'students'"
                      class="btn btn-primary float-right"
                      >+ Adicionar Estudante</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table id="example3" class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nome</th>
                        <th>Doc</th>
                        <th>Email</th>
                        <th>Sexo</th>
                        <!-- <th>Activo</th> -->
                        <th>Açcões</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(st, index) in studentData" :key="index">
                        <td>{{ index + 1}}</td>
                        <td>{{ st.person.fullName }}</td>
                        <td>{{ st.person.doc_type }}</td>
                        <td>{{ st.person.user.email }}</td>
                        <td>{{ st.person.gender }}</td>
                        <!-- <td v-if="st.isActive == 1">
                          <span class="badge badge-success">Activo</span>
                        </td>
                        <td v-else>
                          <span class="badge badge-danger">Inactivo</span>
                        </td> -->
                        <td>
                          <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
                            <select
                              class="form-control"
                              style="border-radius: 16px"
                              @change="handleEventsStudent($event, st.person_id, index)"
                            >
                              <option value="0">Selecione</option>
                              <!-- <option value="active">Activar</option> -->
                              <!-- <option value="disable">Desactivar</option> -->
                              <option value="update">Actualizar</option>
                              <!-- <option value="view">Visualizar</option> -->
                              <option value="delete">Eliminar</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                       <tr>
                      <td
                        class="text-center"
                        colspan="6"
                        v-if="studentData.length == 0"
                      >
                        <span style="font-style: italic; text-align: center"
                          >Ainda não tem registros</span
                        >
                      </td>
                    </tr>
                    </tbody>
                  </table>
                   <Page
                :total="total"
                :model-value="current_page"
                :page-size="per_page"
                @on-change="getAllStudent"
                class="float-right"
                v-if="total > 0"

              />
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
 import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import StudentModuleService from "./StudentModuleService";

const studentData = ref([]);
const router = useRouter();
const total = ref();
const per_page = ref();
const current_page = ref();


async function getAllStudent() {
  const resp = await StudentModuleService.actions.getAllStudent();
  studentData.value = resp.data;
  total.value = resp.total;
  per_page.value = resp.per_page;
  current_page.value = resp.current_page;
   
}

async function handleEventsStudent(event, student_id, index) {
 
  switch (event.target.value) {
    case "update":
       router.push({name:'updateStudent', params:{id:student_id}})  
      break;
    case "active":
      console.log("active");
      break;
    case "disable":
      console.log("disable");
      break;

    case "view":
      console.log("view");
      break;

    case "delete":
      console.log("delete");
      studentData.value.splice(index, 1)
      const resp = await StudentModuleService.actions.deleteStudent(student_id);
      console.log(resp);
      break;

    default:
      console.log("opcao nao encontrada");
      break;
  }
}

onMounted(() => {
  getAllStudent();
});
</script>
