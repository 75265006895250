import router from "@/router";
import axios from "axios";
// const URL_API = "https://traineesystem.jcompany.tech/Api/";
// const URL_API = "http://localhost:8000/";
const URL_API =  process.env.VUE_APP_API_URL

// import router from "@/router";
export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
  },
  actions: {

    login({ commit }, data) {
      // console.log(access_oken)
      axios
        // .get("/api/user", {
          .get(URL_API+"user", {
          headers: {
            Authorization: "Bearer " + data.access_token,
          },
        })
        .then(({ data }) => {
          commit("SET_USER", data);
          commit("SET_AUTHENTICATED", true);
          //  router.push({path:'/dashboard'})

          if (data.data.role[0] == "Superadmin") {
            // router.push({path:'/dashboard'})
            window.location.href = "/dashboard";
          } else if (data.data.role[0] == "Student") {
            // router.push({path: '/Home'})
            window.location.href = "/Student";
          } else if (data.data.role[0] == "Teacher") {
            // router.push({path: '/Teacher'})
            window.location.href = "/Teacher";
          } else {
            window.location.href = "/Company";
          }
        })
        .catch(() => {
          commit("SET_USER", {});
          commit("SET_AUTHENTICATED", false);
        });
    },

    logout({ commit }) {
      commit("SET_USER", {});
      commit("SET_AUTHENTICATED", false);
    },
  },
};
