<template>
  <div id="wrapper">
    <MyNavbarVue />
    <MySideBarTeacher />
    <div class="content-page">
      <div class="content">
        <RouterView></RouterView>
      </div>
    </div>
  </div>
</template>

<script>
import MyNavbarVue from "@/layouts/MyNavbar.vue";
import MySideBarTeacher from "@/layouts/MySideBarTeacher.vue";

export default {
  components: {
    MyNavbarVue,
    MySideBarTeacher,
  },
};
</script>