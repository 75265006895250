<script setup>
import StudentModuleService from "./StudentModuleService";
import { ref, reactive, onMounted } from "vue";
import toastr from "toastr";
import PersonData from "../../components/admin/Student/PersonData.vue";
import Documents from "../../components/admin/Student/Documents.vue";
import SchoolFrequency from "../../components/admin/Student/SchoolFrequency.vue";
import CourseData from "../../components/admin/Student/CourseData.vue";
import ClassmateStudent from "../../components/admin/Student/ClassmateStudent.vue";
import EstudantesServices from "./StudentModuleService";
import { Step } from "view-ui-plus";

const form = reactive({});

const firstInfoShow = ref(true);
const secondInfoShow = ref(false);
const stepThreeInfo = ref(false);
const stepFourInfo = ref(false);
const deficientTypeDisabled = ref(false);
const years = ref([]);
const currentStep = ref(1);
const stepSixInfo = ref(false);
const courseData = ref();
const statusServer = ref(false);
const clearInputs = ref(false)

async function submitData(data) {
  try {
    statusServer.value = true;
    form.value = { ...form.value, ...data };

    if (
      form.value.level_id == "" ||
      form.value.course_id == "" ||
      form.value.ano_entrada == "" ||
      form.value.classmate_id == "" ||
      form.value.academic_year_id == "" ||
      form.value.semester_id == ""
    ) {
      statusServer.value = false;
      return toastr.warning(
        "Por favor preencha todos os campos obrigatorios(*)"
      );
    }

    const resp = await StudentModuleService.actions.createInscritpion(
      form.value
    );

    console.log(resp);
     Object.keys(resp.response.data.errors).forEach(key=>{
        toastr.info(key, resp.response.data.errors[key])
    })


    if (resp.data.status  != undefined) {
      if (resp.data.status == true) {
        toastr.success(resp.data.msg);
        firstInfoShow.value = true;
        stepFourInfo.value = false;
         clearInputs.value = true
  
      }
      
    }

    if (resp.data.semester_closed == true) {
      toastr.error(
        "Desculpe mas ainda não foi declarando inicio de actividades para este semestre!"
      );
    }

 
   } catch (error) {
    console.log(error);
  } finally {
    statusServer.value = false;
    // clearInputs.value = false
  }
}

async function submitDataStudent(data) {
  statusServer.value = true;
  form.value = { ...form.value, ...data };

  const resp = await StudentModuleService.actions.TeacherRegisterStudent(
    form.value
  );

  if (resp.data.status == true) {
    toastr.success(resp.data.msg);
  } else {
    toastr.error(resp.data.msg);
  }

  statusServer.value = false;
}

function handleNextInfo(data) {
  form.value = { ...form.value, ...data };
  console.log(form.value);

  secondInfoShow.value = true;

  firstInfoShow.value = false;
  console.log(firstInfoShow.value);
}
function handleStepTwo(data) {
  form.value = { ...form.value, ...data };

  stepFourInfo.value = true;
  secondInfoShow.value = false;
}

function handleStepThree(data) {
  form.value = { ...form.value, ...data };

  stepThreeInfo.value = false;
  stepSixInfo.value = true;
}

function handleBackInfoClassmateStudentData() {
  stepFourInfo.value = false;
  stepSixInfo.value = true;
}

function handleStepSix(data) {
  form.value = { ...form.value, ...data };

  stepFourInfo.value = true;
  stepThreeInfo.value = false;
}

function handleBackInfoSchoolData() {
  stepThreeInfo.value = true;
  stepFourInfo.value = false;
}

function handleStepFour() {
  if (form.course == "" || form.regimeSuperior == "") {
    return toastr.warning(
      "Por favor preecha os campos obrigatorios",
      "Opsssiiii"
    );
  }
}

function handleBackInfoDocData() {
 secondInfoShow.value = true;
  stepFourInfo.value = false;
}

function handleBackInfoPersoData() {
  firstInfoShow.value = true;
  secondInfoShow.value = false;
}

const submitEstudante = async () => {
  if (form.course == "" || form.regimeSuperior == "") {
    toastr.warning("Por favor preecha os campos obrigatorios", "Opsssiiii");
  }

  return;
};
function is_deficientChange(evt) {
  console.log(evt.target.value);

  if (evt.target.value < 0 || evt.target.value == 0) {
    deficientTypeDisabled.value = true;
  } else {
    deficientTypeDisabled.value = false;
  }
}
async function courses() {
  const resp = await StudentModuleService.actions.getAllCourses();
  courseData.value = resp.data;
  console.log(resp.data);
}

onMounted(() => {
  courses();
});
</script>

<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Estudantes</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Estudantes</li>
          <li class="breadcrumb-item active">Adicionar Estudantes</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Novo Estudante</div>
        </div>
        <div class="card-body">
          <form autocomplete="off">
            <!-- personal User data -->

            <PersonData
              :firstInfoShow="firstInfoShow"
              @personData="handleNextInfo"
              :clearInputs="clearInputs"
            />

            <Documents
              :secondInfoShow="secondInfoShow"
              @nextDocData="handleStepTwo"
              @nextDocPrev="handleBackInfoPersoData"
              :clearInputs="clearInputs"

            />

            <!-- <school-frequency
              :stepThreeInfo="stepThreeInfo"
              @nextCourseData="handleStepSix"
              @DocPrevData="handleBackInfoDocData"
              @submitData="submitDataStudent"
            /> -->

            <CourseData
              :stepFourInfo="stepFourInfo"
              @submitData="submitData"
              @schoolDataPrev="handleBackInfoDocData"
              :courses="courseData"
              :statuServer="statusServer"
              :clearInputs="clearInputs"

            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-image {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  height: 100%;
}
</style>
