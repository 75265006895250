<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Supervisor</li>
          <li class="breadcrumb-item active">Adicionar Supervisor</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Cadastrar Supervisor</div>
        </div>
        <div class="card-body">
          <form action="#" method="post">
            <!-- personal User data -->
            <div v-show="firstInfoShow">
              <h1 class="text-center mb-5">Dados Pessoas</h1>
            </div>
            <div class="row" v-show="firstInfoShow">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome Completo*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.fullName"
                  />
                </div>
                <p v-if="name_message_error" style="color: red">
                  {{ name_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Ano de Nascimento*</label>
                  <input
                    type="date"
                    name="datepicker"
                    class="datepicker-default form-control"
                    id="datepicker1"
                    v-model="userData.birthDate"
                  />
                </div>
                <p v-if="birth_message_error" style="color: red">
                  {{ birth_message_error }}
                </p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Genero*</label>
                  <select class="form-control" v-model="userData.gender">
                    <option value="">Genero</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                  </select>
                </div>
                <p v-if="gender_message_error" style="color: red">
                  {{ gender_message_error }}
                </p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.email"
                    @input="validateEmail"
                  />
                </div>
                <p v-if="emailError" style="color: red">
                  {{ emailError }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto*</label>
                  <input
                    type="text"
                    class="form-control"
                    @keypress="numberOnly"
                    pattern="/^[0-9]+$/"
                    maxlength="9"
                    v-model="userData.contact"
                    @input="validatePhoneNumber"
                  />
                </div>
                <p v-if="phoneNumberError" style="color: red">
                  {{ phoneNumberError }}
                </p>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto Alternativo</label>
                  <input
                    type="text"
                    class="form-control"
                    @keypress="numberOnly"
                    pattern="/^[0-9]+$/"
                    maxlength="9"
                    v-model="userData.alternative_contact"
                    @input="validatePhoneNumberAlternative"
                  />
                </div>
                <p v-if="phoneNumberErrorAlternative" style="color: red">
                  {{ phoneNumberErrorAlternative }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Tem alugma deficiencia?*</label>
                  <select
                    @change="is_deficientChange($event)"
                    class="form-control"
                    v-model="userData.is_deficient"
                  >
                    <option value="-1">Selecione</option>
                    <option value="0">Nao</option>
                    <option value="1">Sim</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label"
                    >Selecione o tipo de deficiencia</label
                  >
                  <select
                    class="form-control"
                    :disabled="deficientTypeDisabled"
                    v-model="userData.deficient_type"
                  >
                    <option value="Class">Selecione o tipo</option>
                    <option value="Paraplegia">Paraplegia</option>
                    <option value="Paraparesia">Paraparesia</option>
                    <option value="Monoplegia">Monoplegia</option>
                    <option value="Monoparesia">Monoparesia</option>
                    <option value="Tetraplegia">Tetraplegia</option>
                    <option value="Triplegia">Triplegia</option>
                    <option value="Triparesia">Triparesia</option>
                    <option value="Hemiplegia">Hemiplegia</option>
                    <option value="Hemiparesia">Hemiparesia</option>
                    <option value="Amputação">Amputação</option>
                    <option value="Paralisia Cerebral">Paralisia Cerebral</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome do Pai*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.fathers_filliation"
                  />
                </div>
                <p v-if="father_message_error" style="color: red">
                  {{ father_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Mãe*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.mothers_filliation"
                  />
                </div>
                <p v-if="mother_message_error" style="color: red">
                  {{ mother_message_error }}
                </p>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <button
                  type="button"
                  class="btn btn-primary float-right"
                  v-on:click="handleNextInfo"
                >
                  Proximo
                </button>
              </div>
            </div>

            <!-- End -->

            <div v-show="secondInfoShow">
              <h1 class="text-center mb-5">Documentaçao</h1>
            </div>
            <div class="row" v-show="secondInfoShow">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione o tipo documento*</label>
                  <select class="form-control" v-model="userData.doc_type">
                    <option value="Class">Selecione o tipo documento</option>
                    <option value="Bilhete de Identidade" selected>
                      Bilhete de Identidade
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Numero do doc*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.doc_number"
                    @input="validateIdentificationNumber"
                    maxlength="13"
                  />
                </div>
                <p v-if="doc_number_message_error" style="color: red">
                  {{ doc_number_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nacionalidade*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.nactionality"
                  />
                </div>
                <p v-if="nactionality_message_error" style="color: red">
                  {{ nactionality_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione o Provincia*</label>
                  <select
                    class="form-control"
                    v-model="province_id"
                    @change="ChangeProvince($event)"
                  >
                    <option value="0">Selecione o Provincia</option>
                    <option
                      :value="provinces.id"
                      v-for="provinces in provincesData"
                      :key="provinces.id"
                    >
                      {{ provinces.province_name }}
                    </option>
                  </select>
                </div>
                <p v-if="province_message_error" style="color: red">
                  {{ province_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione o Distrito{{userData.district_id}}*</label>
                  <select
                    :disabled="loadDistricts"
                    class="form-control"
                    v-model="userData.district_id"
                  >
                    <option value="0">Selecione o Distrito</option>
                    <option
                      :value="district.id"
                      v-for="district in districtData"
                      :key="district.id"
                    >
                      {{ district.name }}
                    </option>
                  </select>
                </div>
                <p v-if="district_message_error" style="color: red">
                  {{ district_message_error }}
                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Endereco*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="userData.address_location"
                  />
                </div>
              </div>

              <div class="container-fluid mb-2">
                <div class="row justify-content-center">
                  <div class="cardImages">
                    <div class="top">
                      <p>Carregar Curriculo</p>
                    </div>
                    <div
                      class="drag-area"
                      @dragover.prevent="onDragOver"
                      @dragleave.prevent="onDragLeave"
                      @drop.prevent="onDrop"
                    >
                      <span v-if="!isDragging"
                        >Clique para
                        <span class="select" role="button" @click="selectFiles">
                          carregar o documento
                        </span>
                      </span>
                      <div v-else class="select">Drop images here</div>
                      <input
                        name="file"
                        type="file"
                        class="file"
                        :ref="fileInpu"
                        @change="onChange"
                        accept="application/pdf"
                      />
                    </div>
                  </div>
                  <p v-if="doc_message_error" style="color: red">
                    {{ doc_message_error }}
                  </p>
                </div>
                <div class="card">
                  <div class="image">
                    <div v-if="previewURL">
                      <embed
                        :src="previewURL"
                        type="application/pdf"
                        width="25%"
                        height="200px"
                      />
                    </div>
                    <div v-else>
                      <embed
                        :src="url_location+userData.url_file_name"
                        type="application/pdf"
                        width="25%"
                        height="200px"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <Button
                  type="primary"
                  v-on:click="handleSubmitButton"
                  class="float-right"
                  :loading="loading"
                >
                  Criar
                </Button>

                <button
                  type="button"
                  class="btn btn-light"
                  v-on:click="handleBackInfo"
                >
                  Voltar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted } from "vue";
import { mapGetters } from "vuex";
import SupervisorStoreModule from "./SupervisorStoreModule";
import store from "@/store";
import toastr from "toastr";
import axios from "axios";
import TeacherStoreModule from "../teachers/TeacherStoreModule";
import { useRoute, useRouter } from "vue-router";

const url_path_static = ref("https://traineesystem.jcompany.tech/assets/icons/attachment.svg");
const url_location = "https://traineesystem.jcompany.tech/Api/assets/upload_docs/"

const firstInfoShow = ref(true);
const secondInfoShow = ref(false);
const stepThreeInfo = ref(false);
const stepFourInfo = ref(false);
const stepFiveInfo = ref(false);
const personalData = ref(false);
const loadDistricts = ref(true);
const schoolData = ref(false);
const courseData = ref(false);
const documentData = ref(false);
const loading = ref(false);
const deficientTypeDisabled = ref(true);
const id_province = ref(0);
const roleUser = ref(null);

const userData = ref({
  fullName: null,
  address_location: null,
  email: null,
  gender: null,
  doc_type: "Bilhete de Identidade",
  doc_number: null,
  nactionality: "Moçambique ",
  birthDate: null,
  last_school_frequency: null,
  level_type: "",
  last_school_frequency_year: null,
  fathers_filliation: "",
  mothers_filliation: null,
  childhood_location: null,
  is_deficient: "0",
  deficient_type: null,
  contact: null,
  alternative_contact: null,
  province_id: null,
  district_id: null,
  doc_file: null,
});

const provincesData = ref([]);
const districtData = ref([]);
const emailError = ref("");
const phoneNumberError = ref("");
const phoneNumberErrorAlternative = ref("");
const name_message_error = ref("");
const birth_message_error = ref("");
const district_message_error = ref("");
const province_message_error = ref("");
const father_message_error = ref("");
const mother_message_error = ref("");
const gender_message_error = ref("");
const nactionality_message_error = ref("");
const doc_message_error = ref("");
const doc_number_message_error = ref("");
const previewURL = ref(null);
const myRef = ref();
const fileInput = ref();
const route = useRoute();
const province_id = ref();
const years = ref([]);
const districts = ref([]);

function getYearTilCurrent() {
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year >= 1900; year--) {
    years.value.push(year);
  }
}

function clearInputs() {
  userData.address_location = "";
  userData.alternative_contact = "";
  userData.birthDate = "";
  userData.childhood_location = "";
  userData.contact = "";
  userData.deficient_type = "";
  userData.district_id = "";
  userData.doc_number = "";
  userData.doc_type = "";
  userData.email = "";
  userData.fathers_filliation = "";
  userData.gender = "";
  userData.is_deficient = "";
  userData.mothers_filliation = "";
  userData.nactionality = "";
  userData.fullname = "";
}

function handleNextInfo() {
  if (userData.value.fullname == "") {
    name_message_error.value = "O campo nome nao pode estar vazio!";
    return;
  }

  if (userData.value.birthDate == "") {
    birth_message_error.value =
      "O campo data de nascimento nao pode estar vazio!";
    return;
  }
  if (userData.value.gender == "") {
    gender_message_error.value = "O campo genero nao pode estar vazio!";
    return;
  }

  if (userData.value.father_filliation == "") {
    father_message_error.value = "O campo nome do pai nao pode estar vazio!";
    return;
  }

  if (userData.value.mother_filliation == "") {
    father_message_error.value = "O campo nome da mae nao pode estar vazio!";
    return;
  }

  if (userData.value.contact == "") {
    phoneNumberError.value = "O campo contacto nao pode estar vazio!";
    return;
  }

  // if (
  //   userData.name == "" ||
  //   userData.birthDate == "" ||
  //   userData.gender == "" ||
  //   userData.email == "" ||
  //   userData.contact == "" ||
  //   userData.is_deficient == "" ||
  //   userData.father_filliation == "" ||
  //   userData.mother_filliation == ""
  // ) {
  //   return toastr.warning(
  //     "Por favor preecha os campos obrigatorios",
  //     "Opsssiiii"
  //   );
  // }

  firstInfoShow.value = false;
  secondInfoShow.value = true;
  personalData.value = false;
  documentData.value = true;
  console.log("he");
}

function handleStepTwo() {
  if (
    userData.doc_type == "" ||
    userData.doc_number == "" ||
    userData.nactionality == "" ||
    userData.provice == "" ||
    userData.district == "" ||
    userData.address_location == ""
  ) {
    return toastr.warning(
      "Por favor preecha os campos obrigatorios",
      "Opsssiiii"
    );
  }

  secondInfoShow.value = false;
  stepThreeInfo.value = true;
  documentData.value = false;
  schoolData.value = true;
}

function handleStepThree() {
  if (
    userData.last_school_frequency == "" ||
    userData.regime == "" ||
    userData.last_school_frequency_year == ""
  ) {
    return toastr.warning(
      "Por favor preecha os campos obrigatorios",
      "Opsssiiii"
    );
  }

  stepThreeInfo.value = false;
  stepFourInfo.value = true;
  schoolData.value = false;
  // couseData.value = true;
}

function handleStepFive() {
  if (
    userData.department_id == "" ||
    userData.classmates[0] == "" ||
    userData.subjects[0] == ""
  ) {
    return toastr.warning(
      "Por favor preecha os campos obrigatorios",
      "Opsssiiii"
    );
  }

  stepFourInfo.value = false;
  stepFiveInfo.value = true;
  // schoolData.value = false;
  // couseData.value = true;
}
function handleBackInfo() {
  secondInfoShow.value = false;
  firstInfoShow.value = true;
  documentData.value = false;
  personalData.value = true;
}

function handleBackDocument() {
  firstInfoShow.value = false;
  schoolData.value = false;
  documentData.value = true;
  secondInfoShow.value = true;
  stepThreeInfo.value = false;
}

function handleBackNivel() {
  stepThreeInfo.value = true;
  stepFourInfo.value = false;
}

function handleBackSchool() {
  schoolData.value = true;
  stepFourInfo.value = true;
  stepFiveInfo.value = false;
}

function goToFirstForm() {
  firstInfoShow.value = true;
  secondInfoShow.value = false;
  stepThreeInfo.value = false;
  stepFourInfo.value = false;
  personalData.value = true;
}

function is_deficientChange(evt) {
  console.log(evt.target.value);

  if (evt.target.value < 0 || evt.target.value == 0) {
    deficientTypeDisabled.value = true;
  } else {
    deficientTypeDisabled.value = false;
  }
}

async function getSupervisorById() {
  try {
    const resp = await SupervisorStoreModule.actions.getsupervisorById(
      route.params.id
    );

    userData.value = resp.data.data[0];
    console.log(resp.data.data[0]);
    const district = await SupervisorStoreModule.actions.getProvinceByDistricId(resp.data.data[0].district_id);
    province_id.value =district.data.province_id; 
      getDistrictsByprovinceIdd(resp.data.data[0].province_id);

    console.log(district.data.province_id);

  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}


async function getDistrictsByprovinceIdd(id_province) {
   const resp = await TeacherStoreModule.actions.fetchDistricts(
    province_id.value
  );

  console.log(resp);
  districtData.value = resp.data;
}

async function handleSubmitButton() {
  try {
    loading.value = true;
    const response = await SupervisorStoreModule.actions.update(
      userData.value,
      route.params.id
    );
    console.log(response.data);
    if (response.data.status) {
      toastr.success("Supervisor actualizado com sucesso");
      clearInputs();
      goToFirstForm();
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

function validatePhoneNumber() {
  // Expressão regular para validar o número de telefone
  const phoneNumberRegex = /^(86)\d{8}$/;
  var firstTwoDigits = "";
  if (userData.value.contact.length >= 2) {
    firstTwoDigits = userData.value.contact.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        phoneNumberError.value = null;

        break;
      case "85":
        phoneNumberError.value = null;

        break;
      case "86":
        phoneNumberError.value = null;

        break;
      case "87":
        phoneNumberError.value = null;

        break;
      case "82":
        phoneNumberError.value = null;

        break;
      case "83":
        phoneNumberError.value = null;

        break;

      default:
        phoneNumberError.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}

function validateIdentificationNumber() {
  const regex = /^[0-9]{12}[a-zA-Z]$/;

  // Testar a expressão regular
  if (!regex.test(userData.value.doc_number)) {
    doc_number_message_error.value = "Número de identificação inválido!";

    return;
  } else {
    doc_number_message_error.value = "";
  }
}
function validatePhoneNumberAlternative() {
  // Expressão regular para validar o número de telefone
  var firstTwoDigits = "";

  if (userData.value.alternative_contact.length >= 2) {
    firstTwoDigits = userData.value.alternative_contact.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        phoneNumberErrorAlternative.value = null;

        break;
      case "85":
        phoneNumberErrorAlternative.value = null;

        break;
      case "86":
        phoneNumberErrorAlternative.value = null;

        break;
      case "87":
        phoneNumberErrorAlternative.value = null;

        break;
      case "82":
        phoneNumberErrorAlternative.value = null;

        break;
      case "83":
        phoneNumberErrorAlternative.value = null;

        break;

      default:
        phoneNumberErrorAlternative.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}
function validateEmail() {
  // Expressão regular para validar o formato de um e-mail
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Verifica se o e-mail atende ao formato esperado
  if (!emailRegex.test(userData.value.email)) {
    emailError.value = "Por favor, insira um endereço de e-mail válido.";
  } else {
    emailError.value = null;
  }
}
async function getProvinces() {
  try {
    const response = await TeacherStoreModule.actions.fetchProvinces();
    provincesData.value = response.data;
  } catch (error) {}
}

// Fazendo a manipulacao do elemento do DOM
const fileInpu = (element) => {
  myRef.value = element;
};

const selectFiles = () => {
  myRef.value.click();
};

async function ChangeProvince(evt) {
  loadDistricts.value = true;
  id_province.value = evt.target.value;
  console.log(evt.target.value);

  const resp = await TeacherStoreModule.actions.fetchDistricts(
    id_province.value
  );
  districtData.value = resp.data;
  loadDistricts.value = false;
  console.log(resp);
}

function onChange(e) {
  userData.value.doc_file = e.target.files[0];
  url_path_static.value = URL.createObjectURL(userData.value.doc_file);
  previewDocument();
}

function previewDocument() {
  if (userData.value.doc_file) {
    const reader = new FileReader();

    reader.onload = (event) => {
      previewURL.value = event.target.result;
    };

    reader.readAsDataURL(userData.value.doc_file);
  }
}

onMounted(() => {
  if (store.state.auth.authenticated == true) {
    roleUser.value = store.state.auth.user.data.role[0];
  }
  getProvinces();
  getSupervisorById();
});
</script>

<style scoped>
.cardImages {
  width: 100%;
  padding: 10px;
  box-shadow: 0 0 5px #ffdfdf;
  border-radius: 5px;
  overflow: hidden;
}

.cardImages .top {
  text-align: center;
}

.cardImages p {
  font-weight: bold;
  color: #fe0000;
}

.cardImages button {
  outline: 0;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  padding: 8px 13px;
  width: 100%;
  background: #fe0000;
}

.cardImages .drag-area {
  height: 150px;
  border-radius: 5px;
  border: 2px dashed #fe0000;
  background: #f4f3f9;
  color: #fe0000;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: center;
  -webkit-user-select: none;
  margin-top: 10px;
}

.cardImages .drag-areaVideo .visible {
  font-size: 18px;
}

.cardImages .drag-areaVideo {
  height: 150px;
  border-radius: 5px;
  border: 2px dashed #5256ad;
  background: #f4f3f9;
  color: #fe0000;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: center;
  -webkit-user-select: none;
  margin-top: 10px;
}

.cardImages .drag-area .visible {
  font-size: 18px;
}

.cardImages .select {
  color: #5256ad;
  cursor: pointer;
  transition: 0.4s;
}

.cardImages .container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  position: relative;
  margin-bottom: 8px;
  padding-top: 15px;
}

.cardImages .container .image {
  width: 75px;
  margin-right: 5px;
  height: 75px;
  position: relative;
  margin-bottom: 8px;
}

.cardImages .container .image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.cardImages .container .image video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.cardImages .container .image span {
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}

.cardImages input,
.cardImages .drag-area .on-drop,
.cardImages .drag-area.dragover .visible {
  display: none;
}

.delete {
  z-index: 999;
  color: #fe0000;
}
</style>