<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title">
          <h4>Perfil</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="index.html">Inicio</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">Admin</a>
          </li>
          <li class="breadcrumb-item active">
            <a href="javascript:void(0);">Perfil</a>
          </li>
        </ol>
      </div>
    </div>
    <!-- End row -->
  </div>

    <div class="row">
    <div class="col-8 col-lg-12 col-md-12">
      <div class="card">
        <div class="row">
          <div class="col-md-12">
            <!-- <div class="card-header">
              <h3 class="card-title">Perfil pessoal</h3>
            </div> -->
            <div class="card-body">
              <!-- <img src="" class="img-card img-fluid" alt="">
              <button class="btn btn-md btn-primary btn-block">carreagar foto de perfil</button> -->

              <br />
              <ul class="list-unstyled">
                <li class="mb-2">
                  <h4>{{ person.fullName }}</h4>
                  <small class="text-center">{{person.email   }}</small>
                </li>
                <li class="mb-2">
                  <strong>Número do Bilhete de Identidade: </strong
                  ><span>{{ person.doc_number  }}</span>
                </li>
                <li class="mb-2">
                  <strong>Endere&ccedil;o: </strong
                  ><span>{{ person.address_location }}</span>
                </li>
                <li class="mb-2">
                  <strong>Contacto: </strong
                  ><span>{{ person.contact  }}</span>
                </li>
                <li class="mb-2">
                  <strong>Contacto Alternativo: </strong
                  ><span>{{ person.alternative_contact == null ? "--------------": person.alternative_contact }}</span>
                </li>
              </ul>
              <router-link :to="{name:'editAdmin',params:{id:person.id}}" class="btn btn-warning btn-md">
                Actualizar dados do perfil
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>

<script setup>
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import ProfileAdminStore from "./ProfileAdminStore";
const route = useRoute();

const person = ref({});


async function ShowUserProfile() {
  const resp = await ProfileAdminStore.actions.getUserProfile();
  person.value = resp.data.data.person;
 
   console.log(resp.data.data);
}


async function getDistrict(id) {
  try {
    const resp = await ProfileAdminStore.actions.getDistrict(id);
    // console.log(resp.data);
    userData.value.province_id = resp.data.province_id;
    getDistrictsByprovinceIdd(resp.data.province_id);
  } catch (error) {
    console.log(error);
  }
}

async function getDistrictsByprovinceIdd(id) {
  const resp = await jobStoreModule.actions.districtsByProvinceId(id);
  districtData.value = resp.data;
  console.log(resp.data);
}

 onMounted(()=>{
  ShowUserProfile();
  })
</script>
