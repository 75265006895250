<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Empresa</li>
          <li class="breadcrumb-item active">Actualizar Dados</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

    <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <div class="card-title">Empresa</div>
      </div>
      <div class="card-body">
        <form action="#" method="post">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nome da Empresa*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.company_name"
                />
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Contacto*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.company_contact"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Contacto Alternativo</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.company_optional_contact"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Email*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.company_email"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nuit*</label>
                <input type="email" class="form-control" v-model="form.nuit" />
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione Provincia*</label>
                <select
                  class="form-control"
                  v-model="province_id"
                  @change="ChangeProvince"
                >
                  <option value="Class">Selecione Provincia</option>
                  <option
                    :value="p.id"
                    v-for="(p, index) in provinces"
                    :key="index"
                  >
                    {{ p.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione Distrito*</label>
                <select class="form-control" v-model="form.district_id">
                  <option value="Class">Selecione o Distrito</option>
                  <option
                    :value="d.id"
                    v-for="(d, index) in districtData"
                    :key="index"
                  >
                    {{ d.name }}
                  </option>
                </select>
              </div>
            </div>

             <div class="col-lg-8 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Endereco da Empresa*</label>
                 <input type="text" class="form-control" v-model="form.company_address">
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <Button
                type="button"
                class="btn btn-primary float-right"
                @click="handleSubmit"
                :loading="loading"
              >
                Criar
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import CompanyStoreModule from "@/pages/Company/CompanyStoreModule";
import toastr from "toastr";
import { ref, defineEmits, defineProps, onMounted } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import axios from "axios";
import StudentModuleService from "../students/StudentModuleService";

 
const route = useRoute();

const form = ref({
    company_name: "",
    company_email: "",
    company_contact: "",
    company_optional_contact: "",
    nuit:"",
    company_address:"",
    district_id:""

});
const loading = ref(false);
const provinces = ref([]);
const province_id = ref();
const districtData = ref([]);



</script>