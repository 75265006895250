<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashbord</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Resultados</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <form action="" role="search" class="app-searh">
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group float-right">
                  <select
                    class="form-control"
                    v-model="year"
                    style="border-radius: 16px"
                    @change="onChangeYear"
                  >
                    {{
                      years
                    }}
                    <option value="0">Selecionar o ano</option>
                    <option :value="y" v-for="(y, index) in years" :key="index">
                      {{ y }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </form>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Disciplina</th>
                  <th scope="col">Situação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ 1 }}</td>
                  <td>Estagio Tecnico Profissional</td>
                  <td v-if="assessment.status_assessment == 1"><span class="text-success">Aprovado</span></td>
                  <td v-else><span class="text-danger">Não Aprovado</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import store from "@/store";
import { onMounted, ref } from "vue";
import StudentStoreModule from "./StudentStoreModule";

const assessment = ref({});
const entry_year = ref(null);
const year = ref(new Date().getFullYear());
async function getResults() {
  try {
    const resp = await StudentStoreModule.actions.assessmentResults(year.value);
    if (resp.data.length > 0) {
      assessment.value = resp.data[0];
    }
  } catch (error) {
    console.log(error);
  }
}

async function onChangeYear(event) {
  year.value = event.target.value;
   getResults();
}

const years = ref([]);

function getYearTilCurrent() {
  const currentYear = new Date().getFullYear();
  year.value = currentYear;
  for (let year = currentYear; year >= entry_year.value; year--) {
    years.value.push(year);
  }
}

onMounted(() => {
  entry_year.value = store.state.student.student.contas_estudante[0].entry_year;
  getResults();
  getYearTilCurrent();
});
</script>