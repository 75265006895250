<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Incio</a></li>
          <li class="breadcrumb-item active">Actividades</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Declarar Inicio de Actividades do Semestre</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="submitActivities">
            <div class="row">
              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nivel Academico*</label>
                  <select class="form-control" v-model="formData.level_id">
                    <option value="">Selecione o nivel</option>
                    <option
                      :value="l.id"
                      v-for="(l, index) in levels"
                      :key="index"
                    >
                      {{ l.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Semestre*</label>
                  <select class="form-control" v-model="formData.semester_id">
                    <option value="">Selecione o Semestre</option>
                    <option
                      :value="s.id"
                      v-for="(s, index) in Semesters"
                      :key="index"
                    >
                      {{ s.semester_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Data Inicio</label>
                  <input
                    class="form-control"
                    type="date"
                    v-model="formData.date_start_academic_year"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Data Fim</label>
                  <input
                    class="form-control"
                    type="date"
                    v-model="formData.date_end_academic_year"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Ano Lectivo*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.academic_year"
                    disabled
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Estado*</label>
                  <select class="form-control" v-model="formData.status">
                    <option value="">Selecione o Estado</option>
                    <option value="Em Curso">Em Curso</option>
                    <option value="Fechado">Fechado</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descrição da Actividades</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="formData.description"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <Button 
                  type="success"
                  class="float-right"
                  :loading="loading"
                  @click="submitActivities"
                >
                  Adicionar
                </Button>
            
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import ClassMateStoreModule from "../Classmates/ClassMateStoreModule";
import StudentModuleService from "../students/StudentModuleService";
import ActivitieStoreModule from "../Activities/ActivitieStoreModule";
import toastr from "toastr";

const loading = ref(false);
const Semesters = ref([]);
const levels = ref([]);
const courses = ref([]);
const academic_year = ref([]);

const years = ref([]);

const formData = ref({
  description: "",
  date_start_academic_year: "",
  date_end_academic_year: "",
  level_id: "",
  academic_year: "",
  semester_id: "",
  status: "",
});

async function submitActivities() {
  loading.value = true;

  const resp = await ActivitieStoreModule.actions.addActivities(formData.value);
  
  if (resp.data.Declarado) {
    loading.value = false;

    return toastr.info(resp.data.Declarado);
  } else if (resp.data.status == true) {
    loading.value = false;

    return toastr.success(resp.data.message);
  } else {
    loading.value = false;

    return toastr.error(resp.data.message);
  }
}

function getYearTilCurrent() {
  formData.value.academic_year = new Date().getFullYear();
  //   for (let year = currentYear; year >= 1900; year--) {
  //     years.value.push(year);
  //   }
}

async function getAcademicYear() {
  const resp = await ClassMateStoreModule.actions.getAcademicYear();
  academic_year.value = resp.data.data;
  console.log(resp);
}

function clearFields() {
  formData.name = "";
  formData.description = "";
  formData.capacity = "";
}

async function getLevels() {
  const resp = await StudentModuleService.actions.getLevels();
  levels.value = resp.data;
}

async function getSemesters() {
  const resp = await ActivitieStoreModule.actions.getSemesters();
  Semesters.value = resp.data;
  console.log(resp.data);
}

onMounted(() => {
  getYearTilCurrent();
  getLevels();
  getAcademicYear();
  getSemesters();
});
</script>