import authHeader from "@/Api/AuthHeader";
import axios from "axios";


// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL

export default {
  namespaced: true,
  actions: {
    getTrainess(data) {
      return axios.get(
        URL_API +
          `getTrainess/${data.academic_year}/${data.level_id}/${data.period}/${data.status}/${data.entry_year}/${data.course_id}`, {headers:authHeader()}
      );
    },
  },
};
