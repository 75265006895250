import axios from "axios";
import authHeader from "@/Api/AuthHeader";

// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: { 
    getresponseTrainByUser(){
      return axios.get(URL_API+`responseTrainRequest`, {headers:authHeader()});
    }
  },
};
