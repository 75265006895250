import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from '@/store/auth'
import student from '@/store/student'
import weightSubject from '@/store/weights.module';

const store = createStore({
    plugins:[
        createPersistedState()
    ],
    modules:{
        auth,
        student,
        weightSubject
        
    }
})
export default store