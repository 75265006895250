<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Avaliação</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Professor</li>
          <li class="breadcrumb-item active">Avaliação</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-xxl-8 col-lg-8">
      <div class="card">
       
        <div class="card-body">
          <div class="pt-3">
            <div class="settings-form">
              <h4 class="text-primary mb-3">Adicionar Nova Avaliação</h4>
              <form @submit.prevent="submitAssessments">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Codigo Estudante</th>
                        <th scope="col">Nome do Estudante</th>
                        <th scope="col" v-for="form in formulas" :key="form">{{ form.assessment_type }}</th>
                        <th scope="col">Media</th>
                      </tr>
                    </thead>
                    <tbody>

                    <tr
                        v-for="(students, index) in assessment"
                        :key="index"
                      >
                        <td>{{ students.student_id }}</td>
                        <td>{{students.fullName}}</td>
                         <!-- <td v-for="form in formulas" :key="form"><input type="text" class="form-control col-lg-6" v-model="form.assessment_type"> </td> -->
                         
                         <td ><input type="text" class="form-control col-lg-6" :disabled="disabled_field.mini_teste_weight"  @input="weight_mini_teste(students.student_id)" v-model="students.mini_teste"> </td> 
                         <td ><input type="text" class="form-control col-lg-6" :disabled="disabled_field.teste_1_weight"   @input="weight_teste_1(students.student_id)" v-model="students.teste_1"> </td>
                         <td ><input type="text" class="form-control col-lg-6" :disabled="disabled_field.teste_2_weight"   @input="weight_teste_2(students.student_id)" v-model="students.teste_2"> </td>
                         <td ><input type="text" class="form-control col-lg-6" :disabled="disabled_field.teste_3_weight"   @input="weight_teste_3(students.student_id)" v-model="students.teste_3"> </td>
                         <td ><input type="text" class="form-control col-lg-6" :disabled="disabled_field.trabalho_1_weight"   @input="weight_trabalho_1(students.student_id)" v-model="students.trabalho_1"> </td>
                         <td ><input type="text" class="form-control col-lg-6" :disabled="disabled_field.trabalho_2_weight"   @input="weight_trabalho_2(students.student_id)" v-model="students.trabalho_2"> </td>
                         <td ><input type="text" class="form-control col-lg-6" :disabled="disabled_field.trabalho_3_weight"   @input="weight_trabalho_3(students.student_id)" v-model="students.trabalho_3"> </td>
                        <td><input type="text" class="form-control col-lg-6" disabled="true" v-model="students.average"></td> 
          
                      </tr>
                   
                    </tbody>
                  </table>

                 </div>

                <button class="btn btn-primary float-right" @click="sendResults">
                  Salvar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from 'vue-router';
import TeacherDashStoreModule from '../TeacherDashStoreModule';
import toastr from 'toastr';
export default {
  setup() {
    const dataStudents = ref([]);
    
    const assessmentResults =ref(
       
          [
            {
              student_id:0,
              assessment_formula_id:null,
              mini_teste:[],
              teste_1:null,
              teste_2:null,
              teste_3:null,
              trabalho_1:null,
              trabalho_2:null,
              trabalho_3:null,
              average:0.0
            }
          ]

        
    );

     const assessment =ref([]);

    const weight = ref({
      mini_teste_weight:0.0,
      teste_1_weight:0.0,
      teste_2_weight:0.0,
      teste_3_weight:0.0,
      trabalho_1_weight:0.0,
      trabalho_2_weight:0.0,
      trabalho_3_weight:0.0,
     });

      const disabled_field = ref({
      mini_teste_weight:false,
      teste_1_weight:false,
      teste_2_weight:false,
      teste_3_weight:false,
      trabalho_1_weight:false,
      trabalho_2_weight:false,
      trabalho_3_weight:false,
     });

    const route = useRoute();
    const formulas = ref([]);

    async function getFormulasSubject(){
      await TeacherDashStoreModule.actions.getFormulaByClass(route.params.classmate_id).then(response => {
        formulas.value = response

         response.forEach(element => {
          if (element.percentage == null || element.percentage == "0.00") {
            
            console.log("dados de formula", element.assessment_type);

            if (assessment.value.length > 0) {
              
               if (element.assessment_type == "MINI-TESTE") {
                disabled_field.value.mini_teste_weight = true
               }
                if (element.assessment_type == "TESTE-1") {
                disabled_field.value.teste_1_weight = true
               }
                if (element.assessment_type == "TESTE-2") {
                disabled_field.value.teste_2_weight = true
               }
                if (element.assessment_type == "TESTE-3") {
                disabled_field.value.teste_3_weight = true
               }
                if (element.assessment_type == "TRABALHO-PESQUISA 1") {
                disabled_field.value.trabalho_1_weight = true
               }
                if (element.assessment_type == "TRABALHO-PESQUISA 2") {
                disabled_field.value.trabalho_2_weight = true
               }
                if (element.assessment_type == "TRABALHO-PESQUISA 3") {
                disabled_field.value.trabalho_3_weight = true
               }
              
            }
          }
        });
      })




    }
    async function sendResults(){
      console.log(formulas)
    }

 
    

    function weight_mini_teste(student_id){
        var sum_percentage = 0;
  
      formulas.value.forEach(element => {
 
            if(element.assessment_type == "MINI-TESTE") {
              
              weight.value.mini_teste_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-1") {
              
              weight.value.teste_1_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-2") {
              
              weight.value.teste_2_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-3") {
              
              weight.value.teste_3_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 1") {
              
              weight.value.trabalho_1_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 2") {
              
              weight.value.trabalho_2_weight = element.percentage;
            }

               if(element.assessment_type == "TRABALHO-PESQUISA 3") {
              
              weight.value.trabalho_3_weight = element.percentage;
            }

          sum_percentage = parseFloat(sum_percentage) + parseFloat(element.percentage);

            
        });
 

        

        var student = assessment.value.find((student)=>{
          return student.student_id == student_id
        });

          
        if (student.mini_teste > 20 ||  student.mini_teste < 0) {
          student.mini_teste = 0;
          return toastr.info("A nota nao pode ser inferior 0 ou superior que 20 valores");
        }

        student.average =  ((( student.mini_teste * weight.value.mini_teste_weight) + (student.teste_1 * weight.value.teste_1_weight) + (student.teste_2 * weight.value.teste_2_weight) 
        + (student.teste_3 * weight.value.teste_3_weight) + (student.trabalho_1 * weight.value.trabalho_1_weight) 
        + (student.trabalho_2 * weight.value.trabalho_2_weight) + (student.trabalho_3 * weight.value.trabalho_3_weight) ) / sum_percentage).toFixed(1);
     }

     function weight_teste_1(student_id){
               var sum_percentage = 0;


      formulas.value.forEach(element => {
 
            if(element.assessment_type == "MINI-TESTE") {
              
              weight.value.mini_teste_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-1") {
              
              weight.value.teste_1_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-2") {
              
              weight.value.teste_2_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-3") {
              
              weight.value.teste_3_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 1") {
              
              weight.value.trabalho_1_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 2") {
              
              weight.value.trabalho_2_weight = element.percentage;
            }

               if(element.assessment_type == "TRABALHO-PESQUISA 3") {
              
              weight.value.trabalho_3_weight = element.percentage;
            }

          sum_percentage = parseFloat(sum_percentage) + parseFloat(element.percentage);

            
        });
 

    
        var student = assessment.value.find((student)=>{
          return student.student_id == student_id
        });

           if (student.teste_1 > 20 ||  student.teste_1 < 0) {
          student.teste_1 = 0;
          return toastr.info("A nota nao pode ser inferior 0 ou superior que 20 valores");
        }

        student.average =  Math.floor((( student.mini_teste * weight.value.mini_teste_weight) + (student.teste_1 * weight.value.teste_1_weight) + (student.teste_2 * weight.value.teste_2_weight) 
        + (student.teste_3 * weight.value.teste_3_weight) + (student.trabalho_1 * weight.value.trabalho_1_weight) 
        + (student.trabalho_2 * weight.value.trabalho_2_weight) + (student.trabalho_3 * weight.value.trabalho_3_weight) ) / sum_percentage);
    }
     function weight_teste_2(student_id){
                var sum_percentage = 0;


      formulas.value.forEach(element => {
 
            if(element.assessment_type == "MINI-TESTE") {
              
              weight.value.mini_teste_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-1") {
              
              weight.value.teste_1_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-2") {
              
              weight.value.teste_2_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-3") {
              
              weight.value.teste_3_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 1") {
              
              weight.value.trabalho_1_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 2") {
              
              weight.value.trabalho_2_weight = element.percentage;
            }

               if(element.assessment_type == "TRABALHO-PESQUISA 3") {
              
              weight.value.trabalho_3_weight = element.percentage;
            }

          sum_percentage = parseFloat(sum_percentage) + parseFloat(element.percentage);

            
        });
 

    
        var student = assessment.value.find((student)=>{
          return student.student_id == student_id
        });

           if (student.teste_2 > 20 ||  student.teste_2 < 0) {
          student.teste_2 = 0;
          return toastr.info("A nota nao pode ser inferior 0 ou superior que 20 valores");
        }

        student.average = Math.floor((( student.mini_teste * weight.value.mini_teste_weight) + (student.teste_1 * weight.value.teste_1_weight) + (student.teste_2 * weight.value.teste_2_weight) 
        + (student.teste_3 * weight.value.teste_3_weight) + (student.trabalho_1 * weight.value.trabalho_1_weight) 
        + (student.trabalho_2 * weight.value.trabalho_2_weight) + (student.trabalho_3 * weight.value.trabalho_3_weight) ) / sum_percentage);
    }
     function weight_teste_3(student_id){
                var sum_percentage = 0;


      formulas.value.forEach(element => {
 
            if(element.assessment_type == "MINI-TESTE") {
              
              weight.value.mini_teste_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-1") {
              
              weight.value.teste_1_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-2") {
              
              weight.value.teste_2_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-3") {
              
              weight.value.teste_3_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 1") {
              
              weight.value.trabalho_1_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 2") {
              
              weight.value.trabalho_2_weight = element.percentage;
            }

               if(element.assessment_type == "TRABALHO-PESQUISA 3") {
              
              weight.value.trabalho_3_weight = element.percentage;
            }

          sum_percentage = parseFloat(sum_percentage) + parseFloat(element.percentage);

            
        });
 

    
        var student = assessment.value.find((student)=>{
          return student.student_id == student_id
        });
        if (student.teste_3 > 20 ||  student.teste_3 < 0) {
          student.teste_3 = 0;
          return toastr.info("A nota nao pode ser inferior 0 ou superior que 20 valores");
        }
        

        student.average = Math.floor((( student.mini_teste * weight.value.mini_teste_weight) + (student.teste_1 * weight.value.teste_1_weight) + (student.teste_2 * weight.value.teste_2_weight) 
        + (student.teste_3 * weight.value.teste_3_weight) + (student.trabalho_1 * weight.value.trabalho_1_weight) 
        + (student.trabalho_2 * weight.value.trabalho_2_weight) + (student.trabalho_3 * weight.value.trabalho_3_weight) ) / sum_percentage);
    }
    

    function weight_trabalho_1(student_id){
                 var sum_percentage = 0;


      formulas.value.forEach(element => {
 
            if(element.assessment_type == "MINI-TESTE") {
              
              weight.value.mini_teste_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-1") {
              
              weight.value.teste_1_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-2") {
              
              weight.value.teste_2_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-3") {
              
              weight.value.teste_3_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 1") {
              
              weight.value.trabalho_1_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 2") {
              
              weight.value.trabalho_2_weight = element.percentage;
            }

               if(element.assessment_type == "TRABALHO-PESQUISA 3") {
              
              weight.value.trabalho_3_weight = element.percentage;
            }

          sum_percentage = parseFloat(sum_percentage) + parseFloat(element.percentage);

            
        });
 

    
        var student = assessment.value.find((student)=>{
          return student.student_id == student_id
        });

        if (student.trabalho_1 > 20 ||  student.trabalho_1 < 0) {
          student.trabalho_1 = 0;
          return toastr.info("A nota nao pode ser inferior 0 ou superior que 20 valores");
        }

         student.average = Math.floor((( student.mini_teste * weight.value.mini_teste_weight) + (student.teste_1 * weight.value.teste_1_weight) + (student.teste_2 * weight.value.teste_2_weight) 
        + (student.teste_3 * weight.value.teste_3_weight) + (student.trabalho_1 * weight.value.trabalho_1_weight) 
        + (student.trabalho_2 * weight.value.trabalho_2_weight) + (student.trabalho_3 * weight.value.trabalho_3_weight) ) / sum_percentage);
    }
    
    function weight_trabalho_2(student_id){
               var sum_percentage = 0;


      formulas.value.forEach(element => {
 
            if(element.assessment_type == "MINI-TESTE") {
              
              weight.value.mini_teste_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-1") {
              
              weight.value.teste_1_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-2") {
              
              weight.value.teste_2_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-3") {
              
              weight.value.teste_3_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 1") {
              
              weight.value.trabalho_1_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 2") {
              
              weight.value.trabalho_2_weight = element.percentage;
            }

               if(element.assessment_type == "TRABALHO-PESQUISA 3") {
              
              weight.value.trabalho_3_weight = element.percentage;
            }

          sum_percentage = parseFloat(sum_percentage) + parseFloat(element.percentage);

            
        });
 

    
        var student = assessment.value.find((student)=>{
          return student.student_id == student_id
        });

         if (student.trabalho_2 > 20 ||  student.trabalho_2 < 0) {
          student.trabalho_2 = 0;
          return toastr.info("A nota nao pode ser inferior 0 ou superior que 20 valores");
        }

        student.average = Math.floor((( student.mini_teste * weight.value.mini_teste_weight) + (student.teste_1 * weight.value.teste_1_weight) + (student.teste_2 * weight.value.teste_2_weight) 
        + (student.teste_3 * weight.value.teste_3_weight) + (student.trabalho_1 * weight.value.trabalho_1_weight) 
        + (student.trabalho_2 * weight.value.trabalho_2_weight) + (student.trabalho_3 * weight.value.trabalho_3_weight) ) / sum_percentage);  
    }
    

    function weight_trabalho_3(student_id){
                 var sum_percentage = 0;


      formulas.value.forEach(element => {
 
            if(element.assessment_type == "MINI-TESTE") {
              
              weight.value.mini_teste_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-1") {
              
              weight.value.teste_1_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-2") {
              
              weight.value.teste_2_weight = element.percentage;
            }

             if(element.assessment_type == "TESTE-3") {
              
              weight.value.teste_3_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 1") {
              
              weight.value.trabalho_1_weight = element.percentage;
            }

             if(element.assessment_type == "TRABALHO-PESQUISA 2") {
              
              weight.value.trabalho_2_weight = element.percentage;
            }

               if(element.assessment_type == "TRABALHO-PESQUISA 3") {
              
              weight.value.trabalho_3_weight = element.percentage;
            }

          sum_percentage = parseFloat(sum_percentage) + parseFloat(element.percentage);

            
        });
 

    
        var student = assessment.value.find((student)=>{
          return student.student_id == student_id
        });
      if (student.trabalho_3 > 20 ||  student.trabalho_3 < 0) {
          student.trabalho_3 = 0;
          return toastr.info("A nota nao pode ser inferior 0 ou superior que 20 valores");
        }
        student.average = Math.floor((( student.mini_teste * weight.value.mini_teste_weight) + (student.teste_1 * weight.value.teste_1_weight) + (student.teste_2 * weight.value.teste_2_weight) 
        + (student.teste_3 * weight.value.teste_3_weight) + (student.trabalho_1 * weight.value.trabalho_1_weight) 
        + (student.trabalho_2 * weight.value.trabalho_2_weight) + (student.trabalho_3 * weight.value.trabalho_3_weight) ) / sum_percentage);
    }
    
 


    onMounted(() => {
      getStudentByClassMates();
      getFormulasSubject()
    });


    async function submitAssessments(){

      const resp = await TeacherDashStoreModule.actions.assessmentResults(assessment.value)
      console.log(resp);
    }

    async function getStudentByClassMates() {
      const resp = await TeacherDashStoreModule.actions.getStudentByClassMates(
        route.params.classmate_id
      );

    
      for (let index = 0; index < resp.data.data.length; index++) {
           assessmentResults.value.push({
              
              student_id:resp.data.data[index].pessoa.id,
              fullName:resp.data.data[index].pessoa.fullName,
              mini_teste:0.0,
              teste_1:0.0,
              teste_2:0.0,
              teste_3:0.0,
              trabalho_1:0.0,
              trabalho_2:0.0,
              trabalho_3:0.0,
              average:0.0,
       
          })
          
        } 

        assessment.value  =   assessmentResults.value.filter((obj)=>obj.student_id != 0);

        console.log(formulas.value, "f");
     

    }

    return {
      sendResults,
      dataStudents,
      getStudentByClassMates,
      route,
      assessmentResults,
      getFormulasSubject,
      formulas,
      assessment,
      submitAssessments,
      weight_mini_teste,
      weight,
      weight_teste_1,
      weight_teste_2,
      weight_teste_3,
      weight_trabalho_1,
      weight_trabalho_2,
      weight_trabalho_3,
      disabled_field
     };
  },
};
</script>
