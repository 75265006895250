<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Empresa</li>
          <li class="breadcrumb-item active">Registro</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <div class="card-title">Empresa</div>
      </div>
      <div class="card-body">
        <form action="#" method="post">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nome da Empresa*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.company_name"
                />
              </div>
              <p v-if="name_message_error" style="color: red">
                {{ name_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Contacto*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.company_contact"
                  @input="validatePhoneNumber"
                  @keypress="numberOnly"
                  maxlength="9"
                />
              </div>
              <p v-if="phoneNumberError" style="color: red">
                {{ phoneNumberError }}
              </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Contacto Alternativo</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.company_optional_contact"
                  @input="validatePhoneNumberAlternative"
                  @keypress="numberOnly"
                  maxlength="9"
                />
              </div>
              <p v-if="phoneNumberErrorAlternative" style="color: red">
                {{ phoneNumberErrorAlternative }}
              </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Email*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.company_email"
                  @input="validateEmail"
                />
              </div>
              <p v-if="emailError" style="color: red">{{ emailError }}</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nuit*</label>
                <input
                  type="email"
                  class="form-control"
                  @keypress="numberOnly"
                  pattern="/^[0-9]+$/"
                  maxlength="7"
                  v-model="form.nuit"
                  @input="validateIdentificationNumber"
                />
              </div>
              <p v-if="nuit_message_error" style="color: red">
                {{ nuit_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione Provincia*</label>
                <select
                  class="form-control"
                  v-model="province_id"
                  @change="ChangeProvince"
                >
                  <option value="Class">Selecione Provincia</option>
                  <option
                    :value="p.id"
                    v-for="(p, index) in provinces"
                    :key="index"
                  >
                    {{ p.province_name }}
                  </option>
                </select>
              </div>
              <p v-if="district_message_error" style="color: red">
                {{ district_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione Distrito*</label>
                <select class="form-control" v-model="form.district_id">
                  <option value="Class">Selecione o Distrito</option>
                  <option
                    :value="d.id"
                    v-for="(d, index) in districtData"
                    :key="index"
                  >
                    {{ d.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-8 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Endereco da Empresa*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.company_address"
                />
              </div>
              <p v-if="address_message_error" style="color: red">
                {{ address_message_error }}
              </p>
            </div>

            <div class="container-fluid mb-2">
              <div class="row justify-content-center">
                <div class="cardImages">
                  <div class="top">
                    <p>Carregar Alvará</p>
                  </div>
                  <div
                    class="drag-area"
                    @dragover.prevent="onDragOver"
                    @dragleave.prevent="onDragLeave"
                    @drop.prevent="onDrop"
                  >
                    <span v-if="!isDragging"
                      >Clique para
                      <span class="select" role="button" @click="selectFiles">
                        carregar o documento
                      </span>
                    </span>
                    <div v-else class="select">Drop images here</div>
                    <input
                      name="file"
                      type="file"
                      class="file"
                      :ref="fileInpu"
                      @change="onChange"
                    />
                  </div>
                </div>
                <p v-if="doc_message_error" style="color: red">
                  {{ doc_message_error }}
                </p>
              </div>
              <div class="card">
                <div class="image">
                  <div v-if="previewURL">
                    <embed
                      :src="previewURL"
                      type="application/pdf"
                      width="25%"
                      height="200px"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <Button
                type="button"
                class="btn btn-primary float-right"
                @click="handleSubmit"
                :loading="loading"
              >
                Criar
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import CompanyStoreModule from "@/pages/Company/CompanyStoreModule";
import toastr from "toastr";
import { ref, defineEmits, defineProps, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import axios from "axios";
import StudentModuleService from "../students/StudentModuleService";

const route = useRoute();
const url_path_static = ref(
  "https://traineesystem.jcompany.tech/assets/icons/attachment.svg"
);
const form = ref({
  company_name: "",
  company_email: "",
  company_contact: "",
  company_optional_contact: "",
  nuit: "",
  company_address: "",
  district_id: "",
  doc_file: "",
  is_teacher: true,
});
const roleUser = ref();
const loading = ref(false);
const provinces = ref([]);
const province_id = ref();
const districtData = ref([]);

const emailError = ref("");
const phoneNumberError = ref("");
const phoneNumberErrorAlternative = ref("");
const name_message_error = ref("");
const nuit_message_error = ref("");
const district_message_error = ref("");
const address_message_error = ref("");
const doc_message_error = ref("");
const previewURL = ref(null);
const myRef = ref();
const fileInput = ref();

// Fazendo a manipulacao do elemento do DOM
const fileInpu = (element) => {
  myRef.value = element;
};

const selectFiles = () => {
  myRef.value.click();
};

function clearInputs() {
  form.value.company_name = "";
  form.value.company_email = "";
  form.value.company_contact = "";
  form.value.company_optional_contact = "";
  form.value.nuit = "";
  form.value.company_address = "";
  form.value.district_id = "";
  form.value.doc_file = "";
}

async function handleSubmit() {
  loading.value = true;
  try {
    if (roleUser.value == "Teacher") {
      if (form.value.doc_file == "" || form.value.doc_file == null) {
        doc_message_error.value = "O campo documento não pode estar vazio!";

        loading.value = false;
        return;
      }
    }
    if (form.value.company_name == "") {
      name_message_error.value =
        "O campo nome da empresa não pode estar vazio!";
      return;
    }

    if (form.value.district_id == "") {
      district_message_error.value =
        "O campo data districto não pode estar vazio!";
      return;
    }

    if (form.value.company_address == "") {
      address_message_error.value = "O campo endereço não pode estar vazio!";
      return;
    }

    const resp = await CompanyStoreModule.actions.createCompany(form.value);

    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      clearInputs();
    } else {
      console.log(resp);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function getProvinces() {
  const resp = await StudentModuleService.actions.getProvinces();
  provinces.value = resp.data;
  console.log(resp.data);
}

async function getDisitrictbyId(province_id) {
  await axios
    .get("/api/district/" + province_id)
    .then((response) => {
      districtData.value = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

async function ChangeProvince(evt) {
  await axios
    .get("/api/district/" + evt.target.value)
    .then((response) => {
      districtData.value = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

function onChange(e) {
  form.value.doc_file = e.target.files[0];
  url_path_static.value = URL.createObjectURL(form.value.doc_file);
  previewDocument();
}

function previewDocument() {
  if (form.value.doc_file) {
    const reader = new FileReader();

    reader.onload = (event) => {
      previewURL.value = event.target.result;
    };

    reader.readAsDataURL(form.value.doc_file);
  }
}

function numberOnly($event) {
  let keycode = $event.keyCode ? $event.keyCode : $event.which;

  if ((keycode < 48 || keycode > 57) && keycode !== 46) {
    $event.preventDefault();
  }
}

function validatePhoneNumberWhatsapp() {
  // Expressão regular para validar o número de telefone
  const phoneNumberRegex = /^(86)\d{8}$/;
  var firstTwoDigits = "";
  if (userData.value.whatsapp.length >= 2) {
    firstTwoDigits = userData.value.whatsapp.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        whatsapp_message_error.value = null;

        break;
      case "85":
        whatsapp_message_error.value = null;

        break;
      case "86":
        whatsapp_message_error.value = null;

        break;
      case "87":
        whatsapp_message_error.value = null;

        break;
      case "82":
        phoneNumberError.value = null;

        break;
      case "83":
        whatsapp_message_error.value = null;

        break;

      default:
        whatsapp_message_error.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}
function validatePhoneNumber() {
  // Expressão regular para validar o número de telefone
  const phoneNumberRegex = /^(86)\d{8}$/;
  var firstTwoDigits = "";
  if (form.value.company_contact.length >= 2) {
    firstTwoDigits = form.value.company_contact.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        phoneNumberError.value = null;

        break;
      case "85":
        phoneNumberError.value = null;

        break;
      case "86":
        phoneNumberError.value = null;

        break;
      case "87":
        phoneNumberError.value = null;

        break;
      case "82":
        phoneNumberError.value = null;

        break;
      case "83":
        phoneNumberError.value = null;

        break;

      default:
        phoneNumberError.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}

function validateIdentificationNumber() {
  const regex = /^[0-9]{7}$/;

  // Testar a expressão regular
  if (!regex.test(form.value.nuit)) {
    nuit_message_error.value = "Número de identificação inválido!";

    return;
  } else {
    nuit_message_error.value = "";
  }
}
function validatePhoneNumberAlternative() {
  // Expressão regular para validar o número de telefone
  var firstTwoDigits = "";

  if (form.value.company_optional_contact.length >= 2) {
    firstTwoDigits = form.value.company_optional_contact.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        phoneNumberErrorAlternative.value = null;

        break;
      case "85":
        phoneNumberErrorAlternative.value = null;

        break;
      case "86":
        phoneNumberErrorAlternative.value = null;

        break;
      case "87":
        phoneNumberErrorAlternative.value = null;

        break;
      case "82":
        phoneNumberErrorAlternative.value = null;

        break;
      case "83":
        phoneNumberErrorAlternative.value = null;

        break;

      default:
        phoneNumberErrorAlternative.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}

function validateEmail() {
  // Expressão regular para validar o formato de um e-mail
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Verifica se o e-mail atende ao formato esperado
  if (!emailRegex.test(form.value.company_email)) {
    emailError.value = "Por favor, insira um endereço de e-mail válido.";
  } else {
    emailError.value = null;
  }
}

onMounted(() => {
  if (store.state.auth.authenticated == true) {
    roleUser.value = store.state.auth.user.data.role[0];
  }
  getProvinces();
});
</script>

<style scoped>
.cardImages {
  width: 100%;
  padding: 10px;
  box-shadow: 0 0 5px #ffdfdf;
  border-radius: 5px;
  overflow: hidden;
}

.cardImages .top {
  text-align: center;
}

.cardImages p {
  font-weight: bold;
  color: #fe0000;
}

.cardImages button {
  outline: 0;
  color: #fff;
  border-radius: 4px;
  font-weight: 400;
  padding: 8px 13px;
  width: 100%;
  background: #fe0000;
}

.cardImages .drag-area {
  height: 150px;
  border-radius: 5px;
  border: 2px dashed #fe0000;
  background: #f4f3f9;
  color: #fe0000;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: center;
  -webkit-user-select: none;
  margin-top: 10px;
}

.cardImages .drag-areaVideo .visible {
  font-size: 18px;
}

.cardImages .drag-areaVideo {
  height: 150px;
  border-radius: 5px;
  border: 2px dashed #5256ad;
  background: #f4f3f9;
  color: #fe0000;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: center;
  -webkit-user-select: none;
  margin-top: 10px;
}

.cardImages .drag-area .visible {
  font-size: 18px;
}

.cardImages .select {
  color: #5256ad;
  cursor: pointer;
  transition: 0.4s;
}

.cardImages .container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  position: relative;
  margin-bottom: 8px;
  padding-top: 15px;
}

.cardImages .container .image {
  width: 75px;
  margin-right: 5px;
  height: 75px;
  position: relative;
  margin-bottom: 8px;
}

.cardImages .container .image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.cardImages .container .image video {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.cardImages .container .image span {
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}

.cardImages input,
.cardImages .drag-area .on-drop,
.cardImages .drag-area.dragover .visible {
  display: none;
}

.delete {
  z-index: 999;
  color: #fe0000;
}
</style>