<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Vaga</li>
          <li class="breadcrumb-item active">Registro</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <div class="card-title">Vaga</div>
      </div>
      <div class="card-body">
        <form action="#" method="post">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione Supervisor*</label>
                <select class="form-control" v-model="form.supervisor_id">
                  <option
                    :value="s.id"
                    v-for="(s, index) in supervisors"
                    :key="index"
                  >
                    {{ s.fullName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <div class="form-group">
                <label class="form-label">Título*</label>
                <input type="text" class="form-control" v-model="form.title" />
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione Curso*</label>
                <select
                  class="form-control"
                  v-model="form.job_course_id"
                  @change="ChangeProvince"
                >
                  <option value="Class">Selecione Provincia</option>
                  <option
                    :value="c.id"
                    v-for="(c, index) in course"
                    :key="index"
                  >
                    {{ c.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Idade Mínima</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Opcional"
                  v-model="form.job_age_min"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Idade máxima</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Opcional"
                  v-model="form.job_age_max"
                />
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione Provincia*</label>
                <select
                  class="form-control"
                  v-model="province_id"
                  @change="ChangeProvince"
                >
                  <option value="Class">Selecione Provincia</option>
                  <option
                    :value="p.id"
                    v-for="(p, index) in provinces"
                    :key="index"
                  >
                    {{ p.province_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione Distrito*</label>
                <select class="form-control" v-model="form.district_id">
                  <option value="Class">Selecione o Distrito</option>
                  <option
                    :value="d.id"
                    v-for="(d, index) in districtData"
                    :key="index"
                  >
                    {{ d.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Data Limite*</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="form.deadline"
                />
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Descrição da vaga*</label>
                <textarea
                  type="text"
                  rows="5"
                  class="form-control"
                  v-model="form.description"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <Button
                type="success"
                class="btn btn-primary float-right"
                @click="handleSubmit"
                :loading="loading"
              >
                Actualizar
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import jobStoreModule from "@/pages/Jobs/jobStoreModule";
import toastr from "toastr";
import { ref, defineEmits, defineProps, onMounted } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import axios from "axios";
import StudentModuleService from "../students/StudentModuleService";
import CourseStoreModule from "../Courses/CourseStoreModule";
import SupervisorStoreModule from "../Supervisors/SupervisorStoreModule";

const route = useRoute();

const form = ref({
  title: "",
  job_course_id: "",
  supervisor_id: "",
  job_age_min: "",
  job_age_max: "",
  description: "",
  deadline: "",
  district_id: "",
});
const loading = ref(false);
const provinces = ref([]);
const province_id = ref();
const district_id = ref();
const districtData = ref([]);
const course = ref([]);
const supervisors = ref([]);
 
async function getJob() {
  try {
    const resp = await jobStoreModule.actions.getJob(route.params.id);
    form.value = resp.data.data[0];
    form.value.supervisor_id = resp.data.data[0].supervisor.id;
    district_id.value = resp.data.data[0].district_id;
    province_id.value = resp.data.data[0].province_id;
    form.value.job_course_id = resp.data.data[0].course_id;
    getDistrictsByprovinceIdd(resp.data.data[0].province_id);
  } catch (error) {
    console.log(error);
  } finally {
  }
}

async function getAllSupervisorByCompany() {
  try {
    const resp =
      await SupervisorStoreModule.actions.getAllSupervisorByCompany();

    supervisors.value = resp.data.data;
    console.log(resp.data.data);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}
async function getCourse() {
  try {
    const resp = await CourseStoreModule.actions.getCourses();
    course.value = resp.data;
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

async function getDistrictsByprovinceIdd(id) {
  const resp = await jobStoreModule.actions.districtsByProvinceId(id);
  districtData.value = resp.data;
}

async function handleSubmit() {
  loading.value = true;
  try {
    const resp = await jobStoreModule.actions.updateJob(form.value, route.params.id);

    if (resp.data.status == true) {
      toastr.success(resp.data.message);
     } else {
      console.log(resp);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function getProvinces() {
  const resp = await StudentModuleService.actions.getProvinces();
  provinces.value = resp.data;
  console.log(resp.data);
}

async function getDisitrictbyId(province_id) {
  await axios
    .get("/api/district/" + province_id)
    .then((response) => {
      districtData.value = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

async function ChangeProvince(evt) {
  await axios
    .get("/api/district/" + evt.target.value)
    .then((response) => {
      districtData.value = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

function clearInputs() {
  form.value.deadline = "";
  form.value.description = "";
  (form.value.district_id = ""), (form.value.job_age_max = "");
  form.value.job_age_min = "";
  form.value.job_course_id = "";
  form.value.job_title = "";
}

onMounted(() => {
  getProvinces();
  getCourse();
  getAllSupervisorByCompany();
  getJob();
});
</script>

