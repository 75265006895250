import axios from "axios";
import authHeader from '@/Api/AuthHeader'
 
const URL_API =  process.env.VUE_APP_API_URL


export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {


        async getMyDatas(ctx) {
            return await new Promise((resolve, reject) => {
                axios
                    .get(URL_API+"student-profile", { headers: authHeader() })
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            })
        },
        async getTurmas() {

            try {
                const resp = await axios.get(URL_API+'teacher-profile', { headers: authHeader() });
                return resp;
            } catch (error) {
                return error;
            }
        },

        async getSubjects() {
            try {
                const resp = await axios.get(URL_API+'teacher-profile', { headers: authHeader() });
                return resp;
            } catch (error) {
                return error;
            }
        },

        async getStudentByClassMates(classmate_id) {
            try {
                const resp = await axios.get(URL_API+'list-students-by-turma/' + classmate_id, { headers: authHeader() });
                return resp;
            } catch (error) {
                return error;
            }
        },

        async getLectures(subject_id) {
            try {
                const resp = await axios.get(URL_API+'get-lectures/' + subject_id, { headers: authHeader() });
                return resp;
            } catch (error) {
                return error;
            }
        },

        async submitData(testData, subject_id) {

            try {
                const resp = await axios.post(URL_API+"createFormula",
                    {
                        testData,
                        "subeject_id": subject_id
                    }
                    , { headers: authHeader() });
                return resp;
            } catch (error) {
                return error;
            }
        },

        async getAllFormulas() {

            try {

                const resp = await axios.get(URL_API+"getAllFormulas", { headers: authHeader() });
                return resp;

            } catch (error) {
                return error;
            }
        },
        async getFormulaByClass(classmate_id) {
            try {
                const response = await axios.get(URL_API+`get-formula-by-classmate/${classmate_id}`, { headers: authHeader() });
                return response.data
            } catch (error) {
                return error;
            }
        },

        async DeleteDataTest(id) {
            try {
                const resp = await axios.delete(URL_API+"DeleteFormula/" + id, { headers: authHeader() });
            } catch (error) {
                return error;
            }
        },
        async updateFormula(payload, id) {
            try {
                const resp = await axios.put(URL_API+"updateFormula/" + id,
                    {
                        "classmate_id": payload.classmate_id,
                        "assessment_type": payload.assessment_type,
                        "percentage": payload.percentage,
                    }, { headers: authHeader() });

                return resp;
            } catch (error) {
                return error;
            }
        },
        
        async assessmentResults(year) {
            try {
                const resp = await axios.get(URL_API+`get-result/${year}`, { headers: authHeader() })
                return resp;
            } catch (error) {

            }
        },

        async addResume(data){

            const DataAppend = new FormData();
            
            DataAppend.append("doc_file", data.doc_file);
            DataAppend.append("is_student", data.is_student);

            return await axios.post(URL_API+'attachments', DataAppend, {headers:authHeader()});
        },
        approve_conclude_trainess(person_id){
            return axios.get(URL_API+`approve_conclude_trainess/${person_id}`, {headers:authHeader()});
        },

        unapprove_conclude_trainess(person_id){
            return axios.get(URL_API+`unapprove_conclude_trainess/${person_id}`, {headers:authHeader()});
        },

        StudentCandidatesjobs(){
            return axios.get(URL_API+`StudentCandidatesjobs`, {headers:authHeader()});
        }

    }

}