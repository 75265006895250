import authHeader from "@/Api/AuthHeader";
import axios from "axios";

// const URL_API = "http://localhost:8000/api/";
// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
const URL_API =  process.env.VUE_APP_API_URL

export default {
  namespaced: true,

  actions: {
    getFirstFiveNotificatioinsByLoggedUser() {
      return axios.get(URL_API + "getNotificatioinsByLoggedUser", {
        headers: authHeader(),
      });
    },
    getAllNotificatioinsByLoggedUser() {
      return axios.get(URL_API + "getAllNotificatioinsByLoggedUser", {
        headers: authHeader(),
      });
    },
    getAllNotificatioinsByUser() {
        return axios.get(URL_API + "notifications", {
          headers: authHeader(),
        });
      }, 
    getTotalNotificatioinsByLoggedUser() {
      return axios.get(URL_API + "getTotalNotificatioinsByLoggedUser", {
        headers: authHeader(),
      });
    },
    getNotificationById(id) {
      return axios.get(URL_API + `notifications/${id}`, {
        headers: authHeader(),
      });
    },

    confirmTrainess(id){

      return axios.get(URL_API+`confirm_trainess/${id}`, {headers:authHeader()});
    }
  },
};
