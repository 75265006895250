<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Jassa</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Detalhes dos Cursos</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="sendData">
            <div class="row">
              <div class="col-lg-12 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome do Curso</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.name"
                  />
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descrição do Curso</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="formData.description"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <Button
                  type="success"
                  :loading="loading"
                  @click="sendData"
                  class="btn btn-primary float-right"
                >
                  Actualiazar
                </Button>
                <!-- <button type="submit" class="btn btn-light">Voltar</button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted } from "vue";
import { mapGetters } from "vuex";
import CoursesStoreModule from "./CourseStoreModule";
import store from "@/store";
import toastr from "toastr";
import { useRoute } from "vue-router";
// import { useRoute } from "vue-router";

// const route = useRoute();
const CURSO_MODULE_NAME = "Curso";
// console.log("ID", route.params.id);
if (!store.hasModule(CURSO_MODULE_NAME)) {
  store.registerModule(CURSO_MODULE_NAME, CoursesStoreModule);
}

onUnmounted(() => {
  if (store.hasModule(CURSO_MODULE_NAME)) {
    store.unregisterModule(CURSO_MODULE_NAME);
  }
});

var formData = ref(
  JSON.parse(
    JSON.stringify({
      name: "",
      description: "",
      department_id: null,
    })
  )
);

const courseData = ref({});

const departments = ref(null);
const loading = ref(false);
const id_course = ref(null);
const route = useRoute();

async function viewCourse() {
  try {
    
    id_course.value = route.params.id_course;
    const resp = await CoursesStoreModule.actions.viewCourse(
      route.params.id_course
    );
    formData.value = resp.data;

  } catch (error) {
    console.log(error);
  }
}

async function sendData() {
  try {
    loading.value = true;

    const resp = await CoursesStoreModule.actions.updateCourse({
      data: formData.value,
      id: id_course.value,
    });

    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      
    } else {
      toastr.error(resp.data.message);
    }
    loading.value = false;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  viewCourse();
});
</script>
