import axios from "axios";
import authHeader from "@/Api/AuthHeader";

// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createJob(data) {
      try {
        return axios.post(URL_API+"jobs", data, { headers: authHeader() });
      } catch (error) {
        console.log(error);
      }
    },

    updateJob(data, id) {
      try {
        return axios.patch(URL_API+`jobs/${id}`, data, { headers: authHeader() });
      } catch (error) {
        console.log(error);
      }
    },


    getJob(job_id){
      return axios.get(URL_API+`jobs/${job_id}`, {headers:authHeader()});
    },

    getJobs(page){
        try {
            return axios.get(URL_API+`jobs?page=${page}`, {headers:authHeader()})
        } catch (error) {
            console.log(error);
        }
    },

    getAlljobs(page){
      try {
          return axios.get(URL_API+`getAllJobsAdmin?page=${page}`, {headers:authHeader()})
      } catch (error) {
          console.log(error);
      }
  },
    getcompanyCandidates(){
      return axios.get(URL_API+`getcompanyCandidates`, {headers:authHeader()});
    },

    apply_job(job_id){

      return axios.post(URL_API+`apply_job`, {job_id:job_id}, {headers:authHeader()}); 
    },
    
    cancel_job(data){
      return axios.post(URL_API+`cancel_job`, data, {headers:authHeader()}); 

    },
    jobsByUser(){
      return axios.get(URL_API+`jobsByUser`, {headers:authHeader()}); 

    },
    approve_candidate(id){
      return axios.get(URL_API+`approve_candidate/${id}`, {headers:authHeader()});
    },
    unapprove_candidate(id){
      return axios.get(URL_API+`unapprove_candidate/${id}`, {headers:authHeader()});
    },
    removeJob(id){
      return axios.delete(URL_API+`jobs/${id}`, {headers:authHeader()});
    },
    async districtsByProvinceId(id) {
      try {
        const resp = await axios.get(URL_API+`district/${id}`, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    confirmTrainess(id){
      return axios.get(URL_API+`confirm_trainess/${id}`, {headers:authHeader()});
    },

    getAllRequestTrain(){
      return axios.get(URL_API+`requestJob`, {headers:authHeader()});
    },
    responseTrain(data){
      return axios.post(URL_API+`responseTrainRequest`, data, {headers:authHeader()});
    },
    getresponseTrainByUser(data){
      return axios.get(URL_API+`responseTrainRequest`, data, {headers:authHeader()});
    }
  },
};
