<template>
  <div id="estudantes">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashbord</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Inicio</a></li>
            <li class="breadcrumb-item">Empresas</li>
            <li class="breadcrumb-item active">Lista Empresa</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="row tab-content">
          <div id="list-view" class="tab-pane fade active show col-lg-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-sm-6">
                    <h4 class="card-title">Empresas</h4>
                  </div>

                  <div class="col-sm-6">
                    <router-link
                    v-if="roleUser == 'Superadmin'"
                      :to="'/add-company'"
                      class="btn btn-primary float-right"
                      >+ Adicionar Empresa
                    </router-link>
                      <router-link
                        v-else
                      :to="'/addcompany'"
                      class="btn btn-primary float-right"
                      >+ Adicionar Empresa
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table id="example3" class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nome</th>
                        <th>Nuit</th>
                        <th>Contacto</th>
                        <th>Contacto Alternativo</th>
                        <th>Email</th>
                        <th>Estado Aprovação</th>
                        <th>Acções</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-show="c.nuit != 10122001992"
                        :class="c.is_active == 1 ? '' : 'disabled'"
                        v-for="(c, index) in companyData"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ c.company_name }}</td>
                        <td>{{ c.nuit }}</td>
                        <td>{{ c.company_contact }}</td>
                        <td>
                          {{
                            c.company_optional_contact == null
                              ? "------"
                              : c.company_optional_contact
                          }}
                        </td>
                        <td>{{ c.company_email }}</td>
                        <td v-if="c.approve_company == 0">
                          <span class="text-warning">Pendente</span>
                        </td>
                        <td v-else-if="c.approve_company == 1">
                          <span class="text-success">Aprovado</span>
                        </td>
                        <td v-else>
                          <span class="text-danger">Desaprovado</span>
                        </td>

                        <td>
                          <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
                            <select
                              class="form-control"
                              style="border-radius: 16px"
                              @change="
                                handleEventsStudent($event, c.company_id, index)
                              "
                            >
                              <option value="0">Selecione</option>
                              <option
                                value="approve"
                                v-if="
                                  c.approve_company == 2 ||
                                  (c.approve_company == 0 &&
                                    roleUser == 'Superadmin')
                                "
                              >
                                Aprovar Empresa
                              </option>
                              <option
                                value="unpprove"
                                v-if="
                                  c.approve_company == 1 || c.doc_file == null &&
                                  roleUser == 'Superadmin'
                                "
                              >
                                Desaprovar Empresa
                              </option>
                              <option
                                value="request"
                                v-if="
                                  c.is_active != 0 && c.approve_company == 1
                                "
                              >
                                Pedido de Vaga
                              </option>
                              <option v-if="c.is_active != 1" value="active">
                                Activar
                              </option>
                              <option v-if="c.is_active != 0" value="disable">
                                Desactivar
                              </option>
                              <option v-if="c.is_active != 0" value="update">
                                Actualizar
                              </option>
                              <option v-if="c.is_active != 0" value="view">
                                Visualizar
                              </option>
                              <option v-if="c.is_active != 0" value="delete">
                                Eliminar
                              </option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                      <td
                        class="text-center"
                        colspan="8"
                        v-if="companyData.length == 0"
                      >
                        <span style="font-style: italic; text-align: center"
                          >Ainda não tem registros</span
                        >
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <Page
                    :total="total"
                    :model-value="current_page"
                    :page-size="parseInt(per_page)"
                    @on-change="getAllCompany"
                    v-if="total > 0"
                    class="float-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isClose" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Pedido de Vaga</h5>
          <button
            type="button"
            @click="closeModal"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Assunto</label>
                  <input
                    type="text"
                    class="form-control"
                    value="Pedido de Vaga"
                    disabled
                  />
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Cursos</label>

                  <select
                    class="form-control"
                    v-model="requestForm.course_name"
                  >
                    <option
                      :value="c.id"
                      v-for="(c, index) in courses"
                      :key="index"
                    >
                      {{ c.name }}
                    </option>
                  </select>
                </div>
              </div>

                  <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Numero de Vagas</label>
                  <input
                    type="text"
                    class="form-control" 
                    v-model="requestForm.numberOfTrain"
                  />
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Empresa*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="company_name"
                    disabled
                    placeholder="Descricao"
                  />
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="company_email"
                    disabled
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descricao*</label>
                  <textarea
                    type="text"
                    rows="5"
                    class="form-control"
                    v-model="requestForm.message"
                    placeholder="Descricao"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModal"
            type="error"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Não
          </Button>
          <Button
            type="info"
            class="btn btn-primary float-right"
            @click="handleSubmit"
            :loading="loading"
          >
            Enviar
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isModalEditCompany" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Actualizar Dados da Empresa</h5>
          <button
            type="button"
            @click="closeModalEditCompany"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#" method="post">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Empresa*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.company_name"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.company_contact"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto Alternativo</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.company_optional_contact"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.company_email"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nuit*</label>
                  <input
                    type="email"
                    class="form-control"
                    v-model="form.nuit"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione Provincia*</label>
                  <select
                    class="form-control"
                    v-model="province_id"
                    @change="ChangeProvince"
                  >
                    <option value="Class">Selecione Provincia</option>
                    <option
                      :value="p.id"
                      v-for="(p, index) in provinces"
                      :key="index"
                    >
                      {{ p.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione Distrito*</label>
                  <select class="form-control" v-model="form.district_id">
                    <option value="Class">Selecione o Distrito</option>
                    <option
                      :value="d.id"
                      v-for="(d, index) in districtData"
                      :key="index"
                    >
                      {{ d.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-8 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Endereco da Empresa*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.company_address"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModalEditCompany"
            type="error"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Nao
          </Button>
          <Button
            type="info"
            class="btn btn-primary float-right"
            @click="handleUpdateCompany"
            :loading="loading"
          >
            Actualizar
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isModalProfileCompany" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Informação da Empresa</h5>
          <button
            type="button"
            @click="closeModalProfileCompany"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="#" method="post">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Empresa</label>
                  <p style="color: #444">{{ form.company_name }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto</label>
                  <p style="color: #444">(+258){{ form.company_contact }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Contacto Alternativo</label>
                  <p style="color: #444">
                    (+258){{
                      form.company_optional_contact == null
                        ? "----------------"
                        : form.company_optional_contact
                    }}
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email</label>
                  <p style="color: #444">{{ form.company_email }}</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nuit</label>
                  <p style="color: #444">{{ form.nuit }}</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione Provincia</label>
                  <p style="color: #444">{{ province_name }}</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione Distrito</label>
                  <p style="color: #444">{{ district_name }}</p>
                </div>
              </div>

              <div class="col-lg-8 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Endereco da Empresa</label>
                  <p style="color: #444">{{ form.company_address }}</p>
                </div>
              </div>

              <div class="col-lg-8 col-md-4 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Pré-visualização do Alvará</label><br>
                  <embed
                    :src="url + form.doc"
                    type="application/pdf"
                    width="50%"
                    height="200px"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModalProfileCompany"
            type="error"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Fechar
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Eliminar</h5>
          <button
            type="button"
            @click="closeModalDelete"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p style="color: #444">
            Tem certeza que deseja eliminar este registro?
          </p>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModalDelete"
            type="light"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Nao
          </Button>
          <Button type="error" @click="handleDelete" :loading="loading">
            Eliminar
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import store from "@/store";
import axios from "axios";
import toastr from "toastr";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import CourseStoreModule from "../Courses/CourseStoreModule";
import StudentModuleService from "../students/StudentModuleService";
import CompanyStoreModule from "./CompanyStoreModule";

const roleUser = ref();

const companyData = ref([]);
const router = useRouter();
const total = ref();
const per_page = ref("");
const current_page = ref("");
const isClose = ref(false);
const isModalEditCompany = ref(false);
const isModalProfileCompany = ref(false);
const isDelete = ref(false);
const loading = ref(false);
const provinces = ref([]);
const province_id = ref();
const district_name = ref("");
const province_name = ref("");
const delete_id = ref();
const districtData = ref([]);
const requestForm = ref({
  title: "Pedido de Vaga",
  message: "",
  course_name: "",
  numberOfTrain:""
});
const company_name = ref("");
const company_email = ref("");
const courses = ref();
const course_message_error = ref("");
const description_message_error = ref("");
const url = ref("https://traineesystem.jcompany.tech/Api/assets/upload_docs/");
const form = ref({
  company_id: "",
  company_name: "",
  company_email: "",
  company_contact: "",
  company_optional_contact: "",
  nuit: "",
  company_address: "",
  district_id: "",
  
}); 
let deletedIndex = ref(0);

async function handleDelete() {
  try {
    loading.value = true;

    companyData.value.splice(deletedIndex.value, 1)
    const resp = await CompanyStoreModule.actions.removeCompany(
      delete_id.value
    );

    if (resp.data.status == 1) {
      isDelete.value = false;
    } else {
      isDelete.value = false;
    }
    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}
async function handleUpdateCompany() {
  try {
    loading.value = true;

    const resp = await CompanyStoreModule.actions.updateCompany(form.value);

    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      getAllCompany();
    } else {
      toastr.info(resp.data.message);
    }
    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function getCourses() {
  try {
    const resp = await CourseStoreModule.actions.getCourses();
    courses.value = resp.data;
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

async function getAllCompany(page = 1) {
  const resp = await CompanyStoreModule.actions.getAllCompany(page);
  companyData.value = resp.data.data;
  total.value = resp.data.meta.total;
  per_page.value = resp.data.meta.per_page;
  current_page.value = resp.data.meta.current_page;
  console.log(resp);
}

async function handleSubmit() {
  try {
    loading.value = true;
    const resp = await CompanyStoreModule.actions.requestVaga(
      requestForm.value,
      company_email.value,
      company_name.value
    );

    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function getRequestCompany(id) {
  try {
    const resp = await CompanyStoreModule.actions.show(id);

    // requestForm.value = resp.data;
    console.log(resp);
  } catch (error) {
    console.log(error);
  }
}

async function getCompany(id) {
  try {
    const resp = await CompanyStoreModule.actions.show(id);
    console.log(resp.data.data[0]);
    getDistrictsByprovinceIdd(resp.data.data[0].district.province_id);
    province_id.value = resp.data.data[0].district.province_id;
    district_name.value = resp.data.data[0].district.name;
    province_name.value = resp.data.data[0].province;
    form.value = resp.data.data[0];
  } catch (error) {
    console.log(error);
  }
}

function closeModal() {
  isClose.value = false;
}

function closeModalEditCompany() {
  isModalEditCompany.value = false;
}

function closeModalProfileCompany() {
  isModalProfileCompany.value = false;
}

function closeModalDelete() {
  isDelete.value = false;
}

async function approve_company(company_id) {
  try {
    const resp = await CompanyStoreModule.actions.approve_company(company_id);
  } catch (error) {
    console.log(error);
  } finally {
  }
}

async function unapprove_company(company_id) {
  try {
    const resp = await CompanyStoreModule.actions.unapprove_company(company_id);
  } catch (error) {
    console.log(error);
  } finally {
  }
}

async function handleEventsStudent(event, company_id, index) {
  switch (event.target.value) {
    case "approve":
      event.target.value = 0;

      const id = companyData.value.findIndex(
        (obj) => obj.company_id == company_id
      );

      if (id !== -1) {
        companyData.value[id].approve_company = 1;
      }
      CompanyStoreModule.actions.approve_company(company_id);
      break;
    case "unpprove":
      event.target.value = 0;

      const idd = companyData.value.findIndex(
        (obj) => obj.company_id == company_id
      );

      if (idd !== -1) {
        companyData.value[idd].approve_company = 2;
      }
      CompanyStoreModule.actions.unapprove_company(company_id);

      break;
    case "request":
      event.target.value = 0;
      getRequestCompany(company_id);
      getCourses();

      companyData.value.forEach((element) => {
        if (element.company_id == company_id) {
          company_email.value = element.company_email;
          company_name.value = element.company_name;
        }
      });

      //   router.push({ name: "pedido", params: { id: company_id } });
      isClose.value = true;
      break;
    case "update":
      event.target.value = 0;
      isModalEditCompany.value = true;
      getCompany(company_id);
      //   router.push({ name: "editCompany", params: { id: company_id } });
      break;
    case "active":
      event.target.value = 0;
      const resp = await CompanyStoreModule.actions.activeUser(company_id);

      companyData.value.forEach((element) => {
        if (element.company_id == company_id) {
          element.is_active = 1;
        }
      });

      if (resp.data.status == true) {
        toastr.success(resp.data.message);
      } else {
        toastr.info(resp.data.message);
      }

      break;
    case "disable":
      event.target.value = 0;

      companyData.value.forEach((element) => {
        if (element.company_id == company_id) {
          element.is_active = 0;
        }
      });
      const respDisable = await CompanyStoreModule.actions.disableUser(
        company_id
      );

      if (respDisable.data.status == true) {
        toastr.success(respDisable.data.message);
      } else {
        toastr.info(respDisable.data.message);
      }

      break;

    case "view":
      event.target.value = 0;
      isModalProfileCompany.value = true;
      getCompany(company_id);
      break;

    case "delete":
      event.target.value = 0;
       isDelete.value = true;
        deletedIndex.value  =  index
      delete_id.value = company_id
      // handleDelete(index, company_id)

      break;

    default:
      console.log("opcao nao encontrada");
      break;
  }
}

async function getProvinces() {
  const resp = await StudentModuleService.actions.getProvinces();
  provinces.value = resp.data;
}

async function getDisitrictbyId(province_id) {
  await axios
    .get("/api/district/" + province_id)
    .then((response) => {
      districtData.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

async function ChangeProvince(evt) {
  await axios
    .get("/api/district/" + evt.target.value)
    .then((response) => {
      districtData.value = response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

async function getDistrictsByprovinceIdd(id) {
  const resp = await CompanyStoreModule.actions.districtsByProvinceId(id);
  districtData.value = resp.data;
}

onMounted(() => {
  if (store.state.auth.authenticated == true) {
    roleUser.value = store.state.auth.user.data.role[0];
  }
  getAllCompany();
  getProvinces();
});
</script>


<style scoped>
.disabled {
  background: #ccc;
}
</style>