import authHeader from "@/Api/AuthHeader";
import axios from "axios"

const URL_API =  process.env.VUE_APP_API_URL 
export default{

    namespaced:true,

    actions:{

        getTotalJobs(){
            return axios.get(URL_API+'getAllJobs', {headers:authHeader()});
        },

        getTotalTreiness(){
            return axios.get(URL_API+'getTotalTreiness', {headers:authHeader()});
        },
        getTotalWithoutTreiness(){
            return axios.get(URL_API+'getTotalWithoutTreiness', {headers:authHeader()});
        },
        getTotalCompany(){
            return axios.get(URL_API+'getTotalCompany', {headers:authHeader()});
        },
        
    }
}