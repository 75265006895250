<template>
  <div class="row" v-show="stepFourInfo">
    <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="form-group">
        <!-- <h1 class="mb-3 text-center text-white">Dados da inscricao</h1> -->

        <label class="form-label">Selecione o Curso*</label>
        <select :disabled="roleUser == 'Teacher'? true : false" class="form-control" v-model="form.course_id">
          <option value="Class">Selecione o Curso</option>
          <option :value="c.id" v-for="(c, index) in courses" :key="index">
            {{ c.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="form-group">
        <label class="form-label">Selecione Nivel*</label>
        <select :disabled="roleUser == 'Teacher'? true : false" class="form-control" v-model="form.level_id">
          <option value="Class">Selecione o Curso</option>
          <option :value="l.id" v-for="(l, index) in levels" :key="index">
            {{ l.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label class="form-label">Semestre*</label>
        <select :disabled="roleUser == 'Teacher'? true : false" class="form-control" v-model="form.semester_id">
          <option value="Class">Selecione o semestre</option>
          <option :value="s.id" v-for="(s, index) in semesters" :key="index">
            {{ s.semester_name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label class="form-label">Ano Academico*</label>
        <select :disabled="roleUser == 'Teacher'? true : false" class="form-control" v-model="form.academic_year_id">
          <option value="">Regime</option>
          <option :value="a.id" v-for="(a, index) in academicYear" :key="index">
            {{ a.designation }}&#176;
          </option>
        </select>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label class="form-label">Turma*</label>
        <select :disabled="roleUser == 'Teacher'? true : false" class="form-control" v-model="form.classmate_id">
          <option value="">Selecionar turma</option>
          <option :value="c.id" v-for="(c, index) in classmate" :key="index">
            {{ c.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="form-group">
        <label class="form-label">Ano de Entrada*</label>
        <select :disabled="roleUser == 'Teacher'? true : false" class="form-control" v-model="form.entry_year">
          <option value="Gender">Ano de Entrada*</option>
          <option :value="y" v-for="y in years" :key="y">{{ y }}</option>
        </select>
      </div>
    </div>

     <!-- <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="form-group">
        <label class="form-label">Tipo de inscricao*</label>
        <select :disabled="roleUser == 'Teacher'? true : false" class="form-control" v-model="form.inscriptionType">
          <option value="Gender">Ano de Entrada*</option>
          <option value="candidatura">Candidatura</option>
         </select>
      </div>
    </div> -->

    <!-- <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="form-group">
        <input
        :disabled="roleUser == 'Teacher'? true : false"
          type="checkbox"
          class="custom-checkbox"
          v-model="form.isActive"
        />
        <label class="form-label">Estudante Activo*</label>
      </div>
    </div> -->

    <div class="col-lg-12 col-md-12 col-sm-12">
      <Button
        type="button"
       
        @click="submitEstudante"
        class="btn btn-primary float-right"
        :disabled="roleUser == 'Teacher' ? true : false"
        :loading="statuServer"
        >
        Criar  
      </Button>
        <!-- <button
        type="button"
         class="btn btn-primary float-right"
        v-else
      >
        Processando... 
      </button> -->
      <button type="button" class="btn btn-light" @click="prevStep">
        Voltar  {{clearInputs}}
      </button>
    </div>
  </div>
</template>

<script setup>
import StudentModuleService from "@/pages/students/StudentModuleService";
import toastr from "toastr";
import { ref, defineProps, defineEmits, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";
import student from "@/store/student";
 

const props = defineProps(["stepFourInfo", "courses", "statuServer", "clearInputs"]);
const emits = defineEmits(["schoolDataPrev", "submitData"]);

const form = ref({
  level_id: "",
  course_id: "",
  entry_year: "",
  inscriptionType: "",
  academic_year_id: "",
  semester_id: "",
  classmate_id: "",
  isActive: false,
});
const route = useRoute();

    


const years = ref([]);
const semesters = ref([]);
const levels = ref([]);
const academicYear = ref([]);
const classmate = ref([]);
const loading = ref(false);
const roleUser = ref(null);


function clearInputFields(){

    form.value.course_id = null
    form.value.entry_year = null
    form.value.academic_year_id = null
    form.value.semester_id = null
    form.value.classmate_id = null
 
  
}

 
 

async function getStudent() {
  const student = await StudentModuleService.actions.viewEstudante(
    route.params.id
  );
  // console.log("Contas", student.data.contas_estudante[0].classmate.course_id);
  
  form.value.course_id = student.data.contas_estudante[0].classmate.course_id;
  // form.value.level_id = student.data.inscricoes[0].level_id;
  form.value.entry_year = student.data.contas_estudante[0].classmate.entry_year;
  // form.value.inscriptionType = student.data.inscricoes[0].inscriptionType;
  form.value.academic_year_id = student.data.contas_estudante[0].classmate.academic_year_id;
  form.value.level_id = student.data.contas_estudante[0].classmate.level_id;
  form.value.semester_id = student.data.contas_estudante[0].classmate.semester_id;
  form.value.classmate_id = student.data.contas_estudante[0].classmate.id;
 
 }


function prevStep() {
  emits("schoolDataPrev");
}

function submitEstudante() {
 
 
  emits("submitData", form.value);

 }

onMounted(() => {
  getYearTilCurrent();
  getSemester();
  getLevels();
  academicYears();
  getClassmate();
  if (store.state.auth.authenticated == true) {
    roleUser.value = store.state.auth.user.data.role[0];
  }
    if (route.params.id !=undefined) {

      getStudent()
    }
//  if(store.state.student.student.inscricoes != undefined){
  //   form.value = store.state.student.student.inscricoes[0];
  //   form.value.isActive = store.state.student.student.inscricoes[0].isActive == 0 ? false : true;
  //   form.value.classmate_id = store.state.student.student.contas_estudante[0].classmate_id;
  //  }

});

function getYearTilCurrent() {
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year >= 1900; year--) {
    years.value.push(year);
  }
}

async function getSemester() {
  const resp = await StudentModuleService.actions.getSemesters();
  semesters.value = resp.data;
}

async function getLevels() {
  const resp = await StudentModuleService.actions.getLevels();
  levels.value = resp.data;
}

async function academicYears() {
  const resp = await StudentModuleService.actions.academicYears();
  academicYear.value = resp.data;
}

async function getClassmate() {
  const resp = await StudentModuleService.actions.getClassmate();
  classmate.value = resp.data.data;
  console.log(resp.data.data);
}
</script>