import axios from "axios";
import authHeader from "@/Api/AuthHeader";


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getSemester(){
        try {
          const response = await axios.get('/api/semesters', { headers: authHeader() });
          return response;

        } catch (error) {
          return error;
        }
    },

    async getAcademicYear() {
      try {
        const response = await axios.get('/api/academic_year', { headers: authHeader() });
        return response;

      } catch (error) {
        return error;
      }
    },
    async viewSubject(id_subject){

      try {
        const response = await axios.get('/api/disciplinas/'+id_subject, {headers:authHeader()});
        return response;
      } catch (error) {
        return error;
      }
    },
    async getSemesters(){
      try {
      const response = await axios.get('/api/get-semesters', {headers:authHeader()});
      return response;
    } catch (error) {
      return error;
    }
    },

    async createDisciplina(TurmaData) {
       
        try {
            
         const response = await axios.post("api/disciplinas", TurmaData, { headers: authHeader() })
         
         return response;
        
        } catch (error) {

            return error;
        }
   
    },

    fetchDisciplinas(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/disciplinas?page='+queryParams.page+'&search='+queryParams.search, { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    
    getAllCourrse() {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/getCourse", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getSubjects(){
      return new Promise((resolve, reject) => {
        axios
          .get("/api/getSubjects", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async updateSubject(formData){

      try {
        const resp = await axios.put('/api/disciplinas/'+formData.id, formData.data, {headers:authHeader()} );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async deleteSubject(id_subject){

      try {
        const resp = await axios.delete('/api/disciplinas/'+id_subject, {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
      }

    }

  },
};