<template>
  <div class="authincation h-100 my-auto">
    <div class="container h-100">
      <div class="car col-lg-8 mx-auto" style="margin-top: 10em">
        <div class="row justify-content-center h-100 p-5">
          <div class="card col-md-6">
            <div class="authincation-content align-items-center">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <div class="text-center mb-5 mt-4">
                      <img
                        src="assets/images/up.png"
                        class="img-fluid"
                        style="width: 100px; height: 100px"
                        alt="logo"
                      />
                    </div>
                    <!-- <h4 class="text-center mb-4">Acesse a sua conta</h4> -->
                    <h4 class="text-center mb-4">
                      {{ $t("FormLogin.recoverEmail") }}
                    </h4>

                    <form>
                      <div class="form-group">
                        <label><strong>Email</strong></label>
                        <input
                          type="email"
                          class="form-control"
                          v-model="data.email"
                          @input="validateEmail"
                        />
                      </div>
                      <p v-if="data.emailError" style="color: red">
                        {{ data.emailError }}
                      </p>

                      <div class="text-center">
                        <Button
                          type="button"
                          id="btn_reset"
                          class="btn-block"
                          style="
                            background-color: #1a73e8;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 3px;
                          "
                          :loading="loading"
                          @click.prevent="handleSubmitButton"
                        >
                          Enviar
                        </Button>
                      </div>
                    </form>
                    <div class="new-account mt-3">
                      <p>
                        Acesse a sua conta?
                        <router-link style="color: #1a73e8" to="/login"
                          >Clique aqui</router-link
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import { mapActions } from "vuex";
import toastr from "toastr";
import axios from "axios";
export default {
  data() {
    return {
      data: {
        email: "",
        emailError: "",
      },
      loading: false,
       URL_API : process.env.VUE_APP_API_URL

    };
  },

  methods: {
    
    async handleSubmitButton() {
      this.loading = true;

      if (this.data.email == "") {
        this.loading = false;

        return toastr.info("Por favor preencha o campo");
      }

      axios
        // .post("https://terrenos.co.mz/Api/api/recover_password", this.data)
        .post(this.URL_API+"recover_password", this.data)
        .then(({ data }) => {
          console.log(data);

          if (data.email_not_found) {
            toastr.error(
              "E-mail não encontrado. Certifique-se de inserir um endereço de e-mail válido."
            );
          } else {
            toastr.success(
              "Por favor verifique a sua caixa de mensagens, enviamos um link para recuperação da senha!"
            );
            this.email = "";
          }
        })
        .catch((response) => {
          toastr.error(
            "Erro de autenticação  verifique suas credenciais ou contacte o administrador" +
              response.data
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    validateEmail() {
      // Expressão regular para validar o formato de um e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Verifica se o e-mail atende ao formato esperado
      if (!emailRegex.test(this.data.email)) {
        this.data.emailError =
          "Por favor, insira um endereço de e-mail válido.";
      } else {
        this.data.emailError = null;
      }
    },
  },
};
</script>
