<template>
  <div id="estudantes">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Situação de Estagiários</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Inicio</a></li>
            <li class="breadcrumb-item">Estagiários</li>
            <li class="breadcrumb-item active">Buscar Estagiários</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>

    <!-- START ROW -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body">
            <form action="" role="search" class="app-searh">
              <div class="row">
                <div class="col-lg-2">
                  <div class="form-group">
                    <select
                      class="form-control"
                      v-model="searchData.level_id"
                      style="border-radius: 16px"
                    >
                      <option value="">Selecionar o nivel</option>
                      <option
                        :value="l.id"
                        v-for="(l, index) in levels"
                        :key="index"
                      >
                        {{ l.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <select
                      class="form-control"
                      v-model="searchData.entry_year"
                      style="border-radius: 16px"
                    >
                      <option value="">Selecionar Ano de Admissao</option>
                      <option
                        :value="y"
                        v-for="(y, index) in years"
                        :key="index"
                      >
                        {{ y }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <select
                      class="form-control"
                      v-model="searchData.period"
                      style="border-radius: 16px"
                    >
                      <option value="">Selecionar o Turno</option>
                      <option value="Manhã">Manhã</option>
                      <option value="Tarde">Tarde</option>
                      <option value="Noturno">Noturno</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <select
                      class="form-control"
                      v-model="searchData.course_id"
                      style="border-radius: 16px"
                    >
                      <option value="Todas">Selecionar Curso</option>
                      <option
                        :value="c.id"
                        v-for="(c, index) in courses"
                        :key="index"
                      >
                        {{ c.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <select
                      class="form-control"
                      v-model="searchData.academic_year"
                      style="border-radius: 16px"
                    >
                      <option value="Todas">Selecionar Ano Academico</option>
                      <option
                        :value="a.id"
                        v-for="(a, index) in academicYear"
                        :key="index"
                      >
                        {{ a.designation }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-2">
                  <div class="form-group">
                    <select
                      class="form-control"
                      style="border-radius: 16px"
                      v-model="searchData.status"
                    >
                      <option value="Todas">Selecionar Estado</option>
                      <!-- <option value="Todos">Todos</option> -->
                      <option value="Estagiando">Estagiando</option>
                      <option value="Sem Estagio">Sem Estagio</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <Button :loading="loading" @click="submitData" type="success" class="float-right"
                  >Pesquisar <i class="fa fa-search"></i
                ></Button>
              </div>
            </form>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Turma</th>
                  <th scope="col">Email</th>
                  <th scope="col">Estado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(i, index) in dataTrainess" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ i.fullName }}</td>
                  <td>{{ i.classmate_name }}</td>
                  <td>{{ i.email }}</td>
                  <td v-if="i.accepted == 0"><span class="text-danger">Sem estagio</span></td>
                  <td v-else><span class="text-success">Estagiando</span></td>
              
                </tr>
              </tbody>
            </table>
               <Page :total="total" :model-value="current_page"  :page-size="parseInt(per_page)" @on-change="getTrainess" v-if="total"   class="float-right" />

          </div>
          <div  v-if="dataTrainess.length == 0" class="text-center text-muted">
            <p style="font-style:italic; font-size:12px">Ainda sem resultado...</p>
          </div>
          <!-- <RouterView></RouterView> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { LoadingBar } from "view-ui-plus";
import { onMounted, ref } from "vue";
import CourseStoreModule from "../Courses/CourseStoreModule";
import StudentModuleService from "../students/StudentModuleService";
import TrainessStoreModule from "./TrainessStoreModule";

const searchData = ref({
  level_id: null,
  academic_year: null,
  period: null,
  course_id: null,
  entry_year: null,
  status: "Todos",
});
const courses = ref([]);
const academicYear = ref([]);
const levels = ref([]);
const years = ref([]);
const dataTrainess = ref([]);
const total = ref(null);
const per_page = ref(null);
const current_page = ref(null);
const loading = ref(false);
 

async function submitData() {
  try {
     loading.value = true;
    const resp = await TrainessStoreModule.actions.getTrainess(
      searchData.value
    );

    dataTrainess.value = resp.data.data
    total.value = resp.data.total;
    per_page.value = resp.data.per_page;
    current_page.value = resp.data.current_page;
    console.log(resp.data);
  } catch (error) {
    console.log(error);
  } finally {
     loading.value = false
  }
}
async function getCourses() {
  try {
    const resp = await CourseStoreModule.actions.getCourses();
    courses.value = resp.data;
    console.log(resp.data);
  } catch (error) {
    console.log(error);
  }
}

function getYearTilCurrent() {
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year >= 1900; year--) {
    years.value.push(year);
  }
}

async function getLevels() {
  const resp = await StudentModuleService.actions.getLevels();
  levels.value = resp.data;
}

async function academicYears() {
  const resp = await StudentModuleService.actions.academicYears();
  academicYear.value = resp.data;
}
onMounted(() => {
  getYearTilCurrent();
  getLevels();
  academicYears();
  getCourses();
});
</script>