import axios from "axios";
import authHeader from '@/Api/AuthHeader'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDepartamentos(ctx, queryParams){
            return new Promise((resolve, reject) => {
                 axios.get('/api/department?page='+queryParams.page+'&search='+queryParams.search, { headers: authHeader() })
                 .then(response => resolve(response))
                 .catch(error => reject(error))
            })
        },

        getAlldepartment(){

            return new Promise((resolve, reject)=>{
                axios.get("/api/getAlldepartment", {headers:authHeader()})
                .then(response=>resolve(response))
                .catch(error=>reject(error))
            })
        },

    //    async createDepartamento(ctx, DepartmentDatas){
    //          return new Promise((resolve, reject) => {
    //              axios.post('/api/department', DepartmentDatas,{ headers: authHeader() })
    //             .then(response => resolve(response))
    //             .catch(error => reject(error))
    //         })
    //     },

        async createDepartamento(DepartmentDatas){
            try {
                const response = await axios.post('/api/department', DepartmentDatas, {
                    headers:authHeader()
                })

                return response.data
            } catch (error) {
                console.log(error);
            }
        },

        async viewDepartamento( id ){

            try {
              const response = await axios.get('/api/department/'+id,{ headers: authHeader() })
            return response;
            } catch (error) {
                return error;
            }
            // return new Promise((resolve, reject) => {
            //     axios.get('/api/department/'+id,{ headers: authHeader() })
            //     .then(response => resolve(response))
            //     .catch(error => reject(error))
            // })
        },
        
        async getEmployee(){
            try {
                const response = await axios.get('/api/getEmployee', { headers: authHeader() });
                return response;
            } catch (error) {
                return error;
            }
            // return new Promise((resolve, reject) => {
            //     axios.get('/api/getEmployee', { headers: authHeader() })
            //     .then(response => resolve(response))
            //     .catch(error => reject(error))
            // })
        },

        // Departamento(ctx, DepartmentDatas){
        //     return new Promise((resolve, reject) => {
        //         axios.post('/api/department', DepartmentDatas,{ headers: authHeader() })
        //         .then(response => resolve(response))
        //         .catch(error => reject(error))
        //     })
        // },

       async updateDepartment(ctx, dataDepartment){


            try {
                const response = await  axios.put('/api/department/'+dataDepartment.id, dataDepartment.data, {headers:authHeader()})
                return response;
            } catch (error) {
                return error;
            }

            // return new Promise((resolve, reject)=>{
            //     axios.put('/api/department/'+dataDepartment.id, dataDepartment.data, {headers:authHeader()})
            //     .then(response=>resolve(response))
            //     .catch(error=>reject(error))
            // })
        },

        async deleteDepartment(id){

            try {
                const response = await axios.delete("/api/department/" + id, {
                    headers: authHeader(),
                  });
                  return response;
                
            } catch (error) {
                
            }
        }

        

     }
}