import axios from "axios";
import authHeader from '@/Api/AuthHeader'


// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL


export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {


        async getMyDatas(ctx){
            return await new Promise((resolve, reject) =>  {
                axios
            .get(URL_API+"teacher-profile", { headers: authHeader() })
            .then((response) => resolve(response.data))
            .catch((error) => reject(error));
        })
        },
        async getTurmas(){

            try {
                const resp = await axios.get(URL_API+'teacher-profile', {headers:authHeader()});
                return resp;
            } catch (error) {
                return error;
            }
        },

        async getSubjects(){
            try {
                const resp = await axios.get(URL_API+'teacher-profile', {headers:authHeader()});
                return resp;
            } catch (error) {
                return error;
            }
        },

        async getStudentByClassMates(classmate_id){
            try {
                const resp = await axios.get(URL_API+'list-students-by-turma/'+classmate_id, {headers:authHeader()});
                return resp;
            } catch (error) {
                return error;
            }
        }, 

        async getLectures(subject_id){
            try {
                const resp = await axios.get(URL_API+'get-lectures/'+subject_id, {headers:authHeader()});
                return resp;
            } catch (error) {
                return error;
            }
        },

        async submitData(weights, classmate,subject){

            try {
                const resp = await axios.post(URL_API+"createFormula", 
                { 
                    weights,
                    "subject": subject,
                    "classemate": classmate
                    
                }
            , {headers:authHeader()});
                return resp;
            } catch (error) {
                return error;
            }
        },

        async getAllFormulas(){

            try {

                const resp = await axios.get(URL_API+"getAllFormulas", {headers:authHeader()});
                return resp;

            } catch (error) {
                return error;
            }
        },
        async getFormulaByClass(classmate_id, subject_id){
            try {
                const response = await axios.get(URL_API+`get-formula-by-classmate?classmate_id=${classmate_id}&subject_id=${subject_id}`, {headers: authHeader()});
                return response.data
            } catch (error) {
               return error; 
            }
        },

        async DeleteDataTest(id){
            try {
                const resp = await axios.delete(URL_API+"DeleteFormula/"+id, {headers:authHeader()});
            } catch (error) {
                return error;
            }
        },
        async updateFormula(payload, id){
            try {
                const resp = await axios.put(URL_API+"updateFormula/"+id, 
                {
                    "classmate_id":payload.classmate_id,
                    "assessment_type":payload.assessment_type,
                    "percentage":payload.percentage,
            }, {headers:authHeader()});
             
            return resp;
            } catch (error) {
                return error;
            }
        },
        async assessmentResults(data){
            try {
                const resp = await axios.post(URL_API+"give-result-to-many", {data}, {headers:authHeader()})
                return resp;
            } catch (error) {
                
            }
        }

    }

}