import axios from "axios";
import authHeader from "@/Api/AuthHeader";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAllPermissions(){

      return new Promise((resolve, reject)=>{
        axios.get('/api/getAllPermission', {headers:authHeader()})
        .then(response=>resolve(response))
        .catch(error=>reject(error))
      })
    },

    fetchRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get("api/roles", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
