<template>
 

  <div class="authincation h-100">
    <div class="container h-100">
      <div class="car col-lg-8 mx-auto m-2">
        <div class="row justify-content-center h-100 p-5 align-items-center">
          <div class="card col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <h4 class="text-center mb-4">Actualizar a senha</h4>
                    <form @submit.prevent="handleSubmitButton">
                      <div class="form-group">
                        <label class="ml-3"
                          ><strong>Senha Actual</strong></label
                        >
                        <input
                          type="password"
                          class="form-control col-lg-11 mx-auto"
                          v-model="senha_actual"
                        />
                      </div>
                      <div class="form-group">
                        <label class="ml-3"><strong>Nova Senha</strong></label>
                        <input
                          type="password"
                          class="form-control col-lg-11 mx-auto"
                          v-model="nova_senha"
                        />
                      </div>
                      <div class="form-group">
                        <label class="ml-3"
                          ><strong>Confirmar Senha</strong></label
                        >
                        <input
                          type="password"
                          class="form-control col-lg-11 mx-auto"
                          v-model="confirmar_senha"
                        />
                      </div>
                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2 col-lg-11"
                      ></div>
                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn-block"
                          style="
                            background-color: #1a73e8;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                          v-if="loading == false"
                        >
                          Actualizar
                        </button>

                        <button
                          v-else
                          type="button mx-auto"
                          class="btn-block"
                          style="
                            background-color: #1a73e8;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                        >
                          Actualizando...
                        </button>
                      </div>
                    </form>
                    <div class="new-account mt-3">
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
 
import { mapActions, useStore } from "vuex";
import toastr from "toastr";
import axios from "axios";
import store from "@/store";
import authHeader from "@/Api/AuthHeader";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const senha_actual = ref("");
const nova_senha = ref("");
const confirmar_senha = ref("");
const fullName = ref("");
const loading = ref(false);
const URL_API =  process.env.VUE_APP_API_URL

 

  const { t, locale} = useI18n()
    const storeData = useStore();
    const lang = ref(storeData.state.auth.lang)

  locale.value = lang.value
  function changeLanguage(local) {
    storeData.dispatch("auth/localLang", local);
    locale.value = local; // Altera o idioma
    //   console.log(t.locale);
}

function updateLocalData(){
     
    locale.value = storeData.state.auth.lang; // Altera o idioma
}

watch(()=>storeData.state.auth.lang, (newValue, oldValue)=>{
    console.log(newValue);
    updateLocalData();
})


async function handleSubmitButton() {
  loading.value = true;

  if (
    nova_senha.value == "" ||
    confirmar_senha.value == "" ||
    senha_actual.value == ""
  ) {
    this.loading = false;

    return toastr.info("Por favor preencha todos os campos");
  }

  if (nova_senha.value != confirmar_senha.value) {
    loading.value = false;

    return toastr.info("A senha de confirmação fornecida não está correta");
  }
  await axios
    .post(URL_API+"update_password", {"nova_senha":nova_senha.value, "confirmar_senha":confirmar_senha.value, "senha_actual":senha_actual.value}, { headers: authHeader() })
    .then(({ data }) => {
      if (data.current_password == 0) {
        toastr.error(
          "Desculpe-nos mas a sua senha actual não  foi confirmada..."
        );
      } else if (data == 1) {
        toastr.success("A sua senha foi alterada com sucesso...");

        senha_actual.value = "";
        nova_senha.value = "";
        confirmar_senha.value = "";
      } else if (data == 0) {
        toastr.error(
          "Desculpe-nos ocorreu um erro ao tentar actualizar  a sua senha tente mais tarde!"
        );
      }
    })
    .catch((response) => {
      toastr.error(
        "Erro de autenticação  verifique suas credenciais ou contacte o administrador" +
          response.data
      );
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
