import authHeader from "@/Api/AuthHeader";
import axios from "axios";

// const  URL_API = "http://localhost:8000/api/";
// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
const URL_API =  process.env.VUE_APP_API_URL


export default{

    namespaced:true,

    actions:{

        getUserProfile(){
            return axios.get(URL_API+`user`, {headers:authHeader()});
        },
        getDistrict(id){
            return axios.get(URL_API+`getDistrictByDistrict_id/${id}`, {headers:authHeader()});
        },
        updateUser(userData, id){

            return axios.patch(URL_API+`users/${id}`, userData, {headers:authHeader()});
        }
    }
}