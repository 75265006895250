<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Todas Professores</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Professores</li>
          <li class="breadcrumb-item active">Lista Professores</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="row tab-content">
        <div id="list-view" class="tab-pane fade active show col-lg-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-sm">
                  <h4 class="card-title">Todos Professores</h4>
                </div>

                <div class="col-sm-6">
                  <router-link
                    to="/teachers"
                    class="btn btn-primary float-right"
                    >+ Adicionar Professor</router-link
                  >
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table id="example3" class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome</th>
                      <th>Sexo</th>
                      <th>Contacto</th>
                      <th>Email</th>
                      <th>Data Entrada</th>
                      <th>Açcões</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(teach, index) in dataTeachers" :key="teach">
                      <td>{{ index }}</td>
                      <td>{{ teach.person.fullName }}</td>
                      <td>{{ teach.person.gender }}</td>
                      <!-- <td>{{ teach.person.level_type }}</td> -->
                      <td>-----------</td>
                      <td>{{ teach.person.email }}</td>
                      <td>{{ teach.person.created_at }}</td>
                      <td>
                        <router-link
                          :to="{
                            name: 'alocate',
                            params: { id_teacher: teach.id },
                          }"
                          class="btn btn-sm btn-success"
                        >
                          <i class="fas fa-network-wired text-white"></i>
                        </router-link>

                        <router-link
                          :to="{
                            name: 'editTeacher',
                            params: { id_teacher: teach.id },
                          }"
                          class="btn btn-sm btn-primary"
                          ><i class="fas fa-edit"></i
                        ></router-link>
                        <a
                          @click="removeTeacher(teach.person.id, index)"
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger ml-1"
                          ><i class="fas fa-trash-alt"></i
                        ></a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="text-center"
                        colspan="7"
                        v-if="dataTeachers.length == 0"
                      >
                        <span style="font-style: italic; text-align: center"
                          >Ainda não tem registros</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Page
                :total="total"
                :model-value="current_page"
                :page-size="per_page"
                @on-change="getAllTeachers"
                class="float-right"
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import TeacherStoreModule from "./TeacherStoreModule";

const dataTeachers = ref([]);
const loading = ref(false);
const total = ref();
const per_page = ref();
const current_page = ref();

async function getAllTeachers() {
  const resp = await TeacherStoreModule.actions.getAllTeachers();
  dataTeachers.value = resp.data.data;
  total.value = resp.data.meta.total;
  per_page.value = resp.data.meta.per_page;
  current_page.value = resp.data.meta.current_page;


  console.log(resp.data);
}

async function removeTeacher(teacher_id, index) {
  try {
    loading.value = false;

    dataTeachers.value.data.splice(index, 1);

    const resp = await TeacherStoreModule.actions.deleteTeacher(teacher_id);
    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  getAllTeachers();
});
</script>
