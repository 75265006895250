<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashboard</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Admin</a></li>
            <li class="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end page-title -->
    <div class="row">
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-briefcase-check bg-success text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Total de Vagas</h5>
            </div>
            <h3 class="mt-4">{{ totalJobs }}</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                style="width: 75%"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previsualização periódica<span class="float-right"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-cube-outline bg-primary text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Total Empresas</h5>
            </div>
            <h3 class="mt-4">{{ totalCompanies }}</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                style="width: 88%"
                aria-valuenow="88"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previsualização periódica<span class="float-right"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="fas fa-graduation-cap bg-warning text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Total Estudantents sem Estagio</h5>
            </div>
            <h3 class="mt-4">{{totalWithoutTrainess}}</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                style="width: 68%"
                aria-valuenow="68"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previsualização periódica<span class="float-right"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="fas fa-graduation-cap bg-danger text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Total de Estudantes Estagiando</h5>
            </div>
            <h3 class="mt-4">{{ totalTrainess }}</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-danger"
                role="progressbar"
                style="width: 82%"
                aria-valuenow="82"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previsualização periódica<span class="float-right"></span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- end row -->
    <!-- <div class="row">
  
   
      <div class="col-xl-4">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Actividades Recentes</h4>
            <ol class="activity-feed mb-0">
              <li class="feed-item">
                <div class="feed-item-list">
                  <p class="text-muted mb-1">Now</p>
                  <p class="font-15 mt-0 mb-0">
                    Jassa magna Jassa, risus posted a new article:
                    <b class="text-primary">Forget UX Rowland</b>
                  </p>
                </div>
              </li>
              <li class="feed-item">
                <p class="text-muted mb-1">Yesterday</p>
                <p class="font-15 mt-0 mb-0">
                  Jassa posted a new article:
                  <b class="text-primary">Designer Alex</b>
                </p>
              </li>
              <li class="feed-item">
                <p class="text-muted mb-1">2:30PM</p>
                <p class="font-15 mt-0 mb-0">
                  Jassa, Jassa, Jassa Commented
                  <b class="text-primary"> Developer Moreno</b>
                </p>
              </li>
              <li class="feed-item pb-1">
                <p class="text-muted mb-1">12:48 PM</p>
                <p class="font-15 mt-0 mb-2">
                  Jassa, Jassa Commented <b class="text-primary">UX Murphy</b>
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import service from "./service";

const totalJobs = ref(0);
const totalCompanies = ref(0);
const totalTrainess = ref(0);
const totalWithoutTrainess = ref(0); 

async function getTotalTrainess() {
  try {
    const resp = await service.actions.getTotalTreiness();
    totalTrainess.value = resp.data;
  } catch (error) {
    console.log(error);
  }
}
async function getTotalWithoutTreiness() {
  try {
    const resp = await service.actions.getTotalWithoutTreiness();
    totalWithoutTrainess.value = resp.data;
  } catch (error) {
    console.log(error);
  }
}
async function getTotalJobs() {
  try {
    const resp = await service.actions.getTotalJobs();
    totalJobs.value = resp.data;
  } catch (error) {
    console.log(error);
  }
}

async function getTotalCompanies() {
  try {
    const resp = await service.actions.getTotalCompany();
    totalCompanies.value = resp.data;
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getTotalJobs();
  getTotalCompanies();
  getTotalTrainess();
  getTotalWithoutTreiness();
});
</script>
