<template>
  <div class="left side-menu">
    <div class="slimscroll-menu" id="remove-scroll">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu" id="side-menu">
          <li class="menu-title">Menu</li>

          <li>
            <router-link to="/company" class="waves-effect"
              ><i class="fas fa-user-circle"></i
              ><span> Perfil </span></router-link
            >
          </li>
          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-city"></i>
              <span>
                Portal do Empresa
                <span class="float-right menu-arrow">
                  <i class="mdi mdi-chevron-right"></i>
                </span>
              </span>
            </a>
            <ul class="submenu">
              <li>
                <router-link to="/add-new-job"
                  ><i class="mdi mdi-keyboard"></i> Adicionar Vaga</router-link
                >
              </li>
              <li>
                <router-link to="/myJobs"
                  ><i class="mdi mdi-keyboard"></i> Minhas Vagas</router-link
                >
              </li>
                <li>
                <router-link to="/candidates"
                  ><i class="mdi mdi-keyboard"></i> Candidaturas</router-link
                >
              </li>
            
            </ul>
          </li>
              <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-user"></i>
              <span>
                Supervisor
                <span class="float-right menu-arrow">
                  <i class="mdi mdi-chevron-right"></i>
                </span>
              </span>
            </a>
            <ul class="submenu">
              <li>
                <router-link to="/add-supervisor"
                  ><i class="mdi mdi-keyboard"></i> Adicionar</router-link
                >
              </li>
              <li>
                <router-link to="/list-supervisor"
                  ><i class="mdi mdi-keyboard"></i> listar</router-link
                >
              </li>
           
            </ul>
          </li>

          <!-- <li>
            <router-link to="/update-password" class="waves-effect"
              ><i class="fas fa-key"></i>
              <span> Alterar Senha </span>
            </router-link>
          </li> -->
        </ul>
      </div>
      <!-- Sidebar -->
      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
</template>

<script>
export default {
  // let user = store.state.user.auth.page
};
</script>
