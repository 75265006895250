<template>
  <div class="row" v-show="firstInfoShow">
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Nome Completo*</label>
        <input type="text" class="form-control" v-model="form.fullName" />
      </div>
      <p v-if="fullname_message_error" style="color: red">
        {{ fullname_message_error }}
      </p>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Ano de Nascimento*</label>
        <input
          type="date"
          name="datepicker"
          class="datepicker-default form-control"
          id="datepicker1"
          v-model="form.birthDate"
        />
      </div>
      <p v-if="birthDate_message_error" style="color: red">
        {{ birthDate_message_error }}
      </p>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Genero*</label>
        <select class="form-control" v-model="form.gender">
          <option value="">Genero</option>
          <option value="Masculino">Masculino</option>
          <option value="Feminino">Feminino</option>
        </select>
      </div>
      <p v-if="gender_message_error" style="color: red">
        {{ gender_message_error }}
      </p>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Email*</label>
        <input
          type="email"
          class="form-control"
          v-model="form.email"
          @input="validateEmail"
        />
      </div>
      <p v-if="emailError" style="color: red">{{ emailError }}</p>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Contacto*</label>
        <input
          v-model="form.contact"
          type="text"
          @keypress="numberOnly"
          pattern="/^[0-9]+$/"
          maxlength="9"
          class="form-control"
          @input="validatePhoneNumber"
        />
      </div>
      <p v-if="phoneNumberError" style="color: red">
        {{ phoneNumberError }}
      </p>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Contacto Alternativo</label>
        <input
          type="text"
          class="form-control"
          @keypress="numberOnly"
          pattern="/^[0-9]+$/"
          maxlength="9"
          v-model="form.alternative_contact"
          @input="validatePhoneNumberAlternative"
        />
      </div>
      <p v-if="phoneNumberErrorAlternative" style="color: red">
        {{ phoneNumberErrorAlternative }}
      </p>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Tem alugma deficiencia?*</label>
        <select
          class="form-control"
          v-model="form.is_deficient"
          @change="is_deficientChange($event)"
        >
          <option value="">Selecione</option>
          <option value="0">Nao</option>
          <option value="1">Sim</option>
        </select>
      </div>
      <p v-if="isDeficient_message_error" style="color: red">
        {{ isDeficient_message_error }}
        
      </p>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Selecione o tipo de deficiencia</label>
        <select
          class="form-control"
          v-model="form.deficient_type"
          :disabled="deficientTypeDisabled"
        >
          <option value="" selected>Selecione o tipo</option>
          <option value="Deficiencia Visual">Deficiencia Visual</option>
          <option value="Cegueira">Cegueira</option>
          <option value="Paraplegia">Paraplegia</option>
          <option value="Paraparesia">Paraparesia</option>
          <option value="Monoplegia">Monoplegia</option>
          <option value="Baixa Visao">Baixa visao</option>
          <option value="HipoMiopia">HipoMiopia</option>
          <option value="HiperMiopia">HiperMiopia</option>
          <option value="Surdez Condutiva">Surdez Condutiva</option>
          <option value="Surdez Sensorioneural">Surdez Sensorioneural</option>
          <option value="Surdez Mista">Surdez Mista</option>
          <option value="Surdez Central">Surdez Central</option>
        </select>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Nome do Pai*</label>
        <input
          type="text"
          class="form-control"
          v-model="form.fathers_filliation"
        />
      </div>
      <p v-if="father_name_message_error" style="color: red">
        {{ father_name_message_error }}
      </p>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12">
      <div class="form-group">
        <label class="form-label">Nome da Mãe*</label>
        <input
          type="text"
          class="form-control"
          v-model="form.mothers_filliation"
        />
      </div>
      <p v-if="mother_name_message_error" style="color: red">
        {{ mother_name_message_error }}
      </p>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12">
      <button
        type="button"
        class="btn btn-primary float-right"
        @click="handleNextInfo"
      >
        Proximo
      </button>
    </div>
  </div>
</template>

<script setup>
import StudentModuleService from "@/pages/students/StudentModuleService";
import toastr from "toastr";
import { ref, defineEmits, defineProps, onMounted } from "vue";
import { useRoute } from "vue-router";
import store from "@/store";

const props = defineProps(["firstInfoShow", "userData"]);
const emits = defineEmits(["personData", "form"]);
const route = useRoute();

const emailError = ref("");
const phoneNumberError = ref("");
const phoneNumberErrorAlternative = ref("");
const name_message_error = ref("");
const birthDate_message_error = ref("");
const district_message_error = ref("");
const address_message_error = ref("");
const doc_message_error = ref("");
const gender_message_error = ref("");
const isDeficient_message_error = ref("");
const father_name_message_error = ref("");
const mother_name_message_error = ref("");
const country_message_error = ref("");
const province_message_error = ref("");
const fullname_message_error = ref("");
const nationality_message_error = ref("");
const endereco_message_error = ref("");
const number_message_error = ref("");
const doc_number_message_error = ref("");

const form = ref({
  fullName: "",
  email: "",
  birthDate: "",
  fathers_filliation: "",
  mothers_filliation: "",
  gender: "",
  is_deficient: "",
  deficient_type: "",
  doc_type: "",
  contact: "",
  alternative_contact: "",
  user_id:''
});

const deficientTypeDisabled = ref(true);

async function getStudent() {
  const student = await StudentModuleService.actions.viewEstudante(
    route.params.id
  );
  form.value.fullName = student.data.dados_pessoais.fullName; 
  form.value.email = student.data.dados_pessoais.email; 
  form.value.birthDate = student.data.dados_pessoais.birthDate; 
  form.value.fathers_filliation = student.data.dados_pessoais.fathers_filliation; 
  form.value.mothers_filliation = student.data.dados_pessoais.mothers_filliation; 
  form.value.gender = student.data.dados_pessoais.gender; 
  form.value.is_deficient = student.data.dados_pessoais.is_deficient; 
  form.value.deficient_type = student.data.dados_pessoais.deficient_type; 
  form.value.doc_type = student.data.dados_pessoais.doc_type; 
  form.value.contact = student.data.dados_pessoais.contact; 
  form.value.alternative_contact = student.data.dados_pessoais.alternative_contact; 
  form.value.user_id = student.data.dados_pessoais.user_id; 
 
 }
 
function validatePhoneNumber() {
  // Expressão regular para validar o número de telefone
  const phoneNumberRegex = /^(86)\d{8}$/;
  var firstTwoDigits = "";
  if (form.value.contact.length >= 2) {
    firstTwoDigits = form.value.contact.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        phoneNumberError.value = null;

        break;
      case "85":
        phoneNumberError.value = null;

        break;
      case "86":
        phoneNumberError.value = null;

        break;
      case "87":
        phoneNumberError.value = null;

        break;
      case "82":
        phoneNumberError.value = null;

        break;
      case "83":
        phoneNumberError.value = null;

        break;

      default:
        phoneNumberError.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}

function validateIdentificationNumber() {
  const regex = /^[0-9]{12}[a-zA-Z]$/;

  // Testar a expressão regular
  if (!regex.test(form.value.doc_number)) {
    doc_number_message_error.value = "Número de identificação inválido!";

    return;
  } else {
    doc_number_message_error.value = "";
  }
}
function validatePhoneNumberAlternative() {
  // Expressão regular para validar o número de telefone
  var firstTwoDigits = "";

  if (form.value.alternative_contact.length >= 2) {
    firstTwoDigits = form.value.alternative_contact.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        phoneNumberErrorAlternative.value = null;

        break;
      case "85":
        phoneNumberErrorAlternative.value = null;

        break;
      case "86":
        phoneNumberErrorAlternative.value = null;

        break;
      case "87":
        phoneNumberErrorAlternative.value = null;

        break;
      case "82":
        phoneNumberErrorAlternative.value = null;

        break;
      case "83":
        phoneNumberErrorAlternative.value = null;

        break;

      default:
        phoneNumberErrorAlternative.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}
function validateEmail() {
  // Expressão regular para validar o formato de um e-mail
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Verifica se o e-mail atende ao formato esperado
  if (!emailRegex.test(form.value.email)) {
    emailError.value = "Por favor, insira um endereço de e-mail válido.";
  } else {
    emailError.value = null;
  }
}

function handleNextInfo() {
  if (form.value.fullName == "") {
    fullname_message_error.value = "O campo nome nao pode estar vazio!";
    return;
  }

  if (form.value.birthDate == "") {
    birthDate_message_error.value =
      "O campo data de nascimento nao pode estar vazio!";
    return;
  }

  if (form.value.gender == "") {
    gender_message_error.value = "O campo genero nao pode estar vazio!";
    return;
  }

  if (form.value.contact == "") {
    phoneNumberError.value = "O campo contacto nao pode estar vazio!";
    return;
  }

  // if (form.value.is_deficient == "") {
  //   isDeficient_message_error.value =
  //     "O campo deficiencia nao pode estar vazio!";
  //   return;
  // }

  if (form.value.fathers_filliation == "") {
    father_name_message_error.value =
      "O campo nome do pai nao pode estar vazio!";
    return;
  }

  if (form.value.mothers_filliation == "") {
    mother_name_message_error.value =
      "O campo nome da mae nao pode estar vazio!";
    return;
  }

  emits("personData", form.value);
}

function is_deficientChange(evt) {
  if (evt.target.value == 0) {
    deficientTypeDisabled.value = true;
  } else {
    deficientTypeDisabled.value = false;
  }
}
function numberOnly($event) {
  let keycode = $event.keyCode ? $event.keyCode : $event.which;

  if ((keycode < 48 || keycode > 57) && keycode !== 46) {
    $event.preventDefault();
  }
}
onMounted(() => {
  if (store.state.student.student.dados_pessoais != undefined) {
    // form.value = store.state.student.student.dados_pessoais;
  }
    if (route.params.id !=undefined) {

      getStudent();
    }
});
</script>

