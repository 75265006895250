<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><router-link to="/">Inicio</router-link></li>
          <li class="breadcrumb-item">Professor</li>
          <li class="breadcrumb-item active">Perfil</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8 col-lg-12 col-md-12">
      <div class="card">
        <div class="row">
          <div class="col-md-4">
            <div class="card-header">
              <h3 class="card-title">
                Perfil pessoal
              </h3>
            </div>
            <div class="card-body">
              <!-- <img src="" class="img-card img-fluid" alt="">
              <button class="btn btn-md btn-primary btn-block">carreagar foto de perfil</button> -->
           
              <br>
              <ul class="list-unstyled" v-if="myProfileData.person !== null">
                <li class="mb-2">
                  <h4 >{{ person.fullName}}</h4>
                  <small class="text-center">{{ user.email }}</small>
                </li>
                <li class="mb-2">
                  <strong >Data de Nascimento: </strong><span >{{ person.birthDate }}</span>
                </li>
                <li class="mb-2">
                  <strong >Endere&ccedil;o: </strong><span >{{ person.address_location }}</span>
                </li>
                <li class="mb-2">
                  <strong >G&eacute;nero: </strong><span >{{ person.gender }}</span>
                </li>
                <li class="mb-2">
                  <strong >Formado na (o): </strong><span >{{ person.last_school_frequency }}</span>
                </li>
              </ul>
              <button class="btn btn-warning btn-md">Actualizar dados do perfil</button>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card-header">
              <h3 class="card-title">
                Informa&ccedil;&otilde;es adicionais
              </h3>
            </div>
            <div class="card-body">
              <table class="table">
                <tr>
                  <th style="width:20%">Disciplinas que leciona</th>
                  <td>
                    <span class="justify-content-between">
                      <span class="badge badge-primary badge-lg m-1" v-for="(disc, index) in disciplinas" :key="index">{{ disc.subject.name }} - {{ disc.subject.description }}</span>
                    </span> 
                  </td>
                </tr>
                <tr>
                  <th>Turmas alocadas</th>
                  <td>
                    <span class="justify-content-between">
                      <span class="badge badge-primary badge-lg" v-for="(tur, index) in turmas" :key="index">
                        {{ tur.classmate.name }} - {{ tur.classmate.description }}
                      </span>
                    </span>
                  </td>
                </tr>
               
                
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-xl-12 col-xxl-8 col-lg-8">
      <div class="card">
        <div class="card-header justify-content-center">
          <h2 class="card-title">Actividades Internas e arquivos anexados</h2> 
        </div>
        <div class="card-body">
         <table class="table table-bordeless table-stripped">
          <thead>
            <tr>
              <th>Ultimas Aulas dada</th>
              <th>Avaliacoes</th>
              <th>Nr total de Alunos</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Anexos</td>
              <td>Anexos</td>
              <td>3400</td>
            </tr>
          </tbody>
         </table>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { onMounted, onUnmounted, ref } from "vue";
import store from "@/store";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";
import TeacherDashStoreModule from "./TeacherDashStoreModule";
export default {
  setup() {

    const PROFILE_MODULE_PROFILE = 'Perfil'
    if (!store.hasModule(PROFILE_MODULE_PROFILE)) {
      store.registerModule(PROFILE_MODULE_PROFILE, TeacherDashStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(PROFILE_MODULE_PROFILE)) {
        store.unregisterModule(PROFILE_MODULE_PROFILE);
      }
    });

    const person = ref({});
    const departamento = ref({});
    const user = ref({})
    const disciplinas = ref({})
    const turmas = ref({})
    const myProfileData = ref({})

     function getTeacherProfile(){
      store.dispatch('Perfil/getMyDatas')
      .then((response) => {
        myProfileData.value = response.data
        person.value = response.data.person
        departamento.value = response.data.department,
        user.value = response.data.user,
        disciplinas.value =  response.data.classmate_subject
        turmas.value = response.data.classmate_subject

        console.log("teste", response.data);
      })

      
    }


    return {
      person,
      user,
      departamento,
      disciplinas,
      turmas,
      myProfileData,
      getTeacherProfile
    };
  },
  created(){
    this.getTeacherProfile()
  }
};
</script>
