<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Todas Disciplinas</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Disciplinas</li>
          <li class="breadcrumb-item active">Lista Disciplinas</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">

          <div class="row">

            <div class="col-sm-6">
              <h4 class="card-title">Lista de Disciplinas</h4>

            </div>

            <div class="col-sm-6">

              <router-link to="/subject" class="btn btn-primary float-right">
                + Adicionar Disciplina</router-link>

            </div>

          </div>
        </div>
        <div class="card-body">
           <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
                v-model="searchQuery"
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table id="example5" class="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Cadastrado por</th>
                  <th>Acção</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(disc, index) in disciplinas.data" :key="index">
                  <td>{{1+index}}</td>
                  <td>{{ disc.name }}</td>
                  <td>{{ disc.description }}</td>
                  <td>{{ disc.creator }}</td>
                  <td>
                    <router-link :to="{name:'editSubject', params:{id_subject:disc.id}}" class="btn btn-sm btn-primary"><i class="fas fa-edit"></i></router-link> &nbsp;
                    <!-- <a href="javascript:void(0);" class="btn btn-sm btn-primary">Alocar Curso</a> |  -->
                    <a href="javascript:void(0);"
                    @click="isdeleteSubject(disc.id, index)"
                    class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></a> 
                  </td>
                </tr>
              </tbody>
            </table>

            <Page :total="disciplinas.total" :model-value="disciplinas.current_page" :page-size="parseInt(disciplinas.per_page)" @on-change="getDisciplinas" v-if="disciplinas.total" class="float-right"/>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Eliminar</h5>
            <button
              type="button"
              @click="closeModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Tem certeza que deseja eliminar a disciplina?</p>
           </div>
          <div class="modal-footer">
            <button type="button" @click="deleteSubject()" class="btn btn-danger">
              {{ isDeleting == false ? "Eliminar" : "Eliminando..." }}
            </button>
            <button
              @click="closeModal"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Nao
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted, watch } from 'vue'
import SubjectStoreModule from './SubjectStoreModule';
import store from '@/store';
import toastr from 'toastr'
export default {
  setup() {
    const DISCIPLINA_MODULE_NAME = "Disciplina";

    if (!store.hasModule(DISCIPLINA_MODULE_NAME)) {
      store.registerModule(DISCIPLINA_MODULE_NAME, SubjectStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(DISCIPLINA_MODULE_NAME)) {
        store.unregisterModule(DISCIPLINA_MODULE_NAME)
      }
    });

    const disciplinas = ref([]);
    const totalDisciplinas = ref(null)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(15)
    const searchQuery = ref('')
    const isDelete = ref(false);
    const index = ref(null);
    const subject_id = ref();
    const isDeleting = ref(false);
    const search = ref('');


     const  getDisciplinas = async (page=1) => {
       await SubjectStoreModule.actions.fetchDisciplinas({
        search: searchQuery.value,
        page: page,
      })
      .then(response => {
        disciplinas.value = response.data
        // callback(disciplinas.value)
        totalDisciplinas.value = disciplinas.value.total
      })
       .catch((error) => {
        console.log(error)
        toastr.error('Erro Na busca de dados do departamento ou contacte o administrador')
        })
    }

    watch(searchQuery, (newValue, oldValue)=>{

      if(newValue.length >=4 || oldValue.length >= 4){

        getDisciplinas()
      }else{
        if (newValue.length == 0 || oldValue.length == 0) {
          getDisciplinas();
        }
      }
    });

    function isdeleteSubject(id, i) {
       isDelete.value = true;
      index.value = i;
      subject_id.value = id;
    }
    function closeModal() {
      isDelete.value = false;
    }
    async function deleteSubject() {
      isDeleting.value = true;
 
      const response = await SubjectStoreModule.actions.deleteSubject(subject_id.value)
       if (response) {
        disciplinas.value.data.splice(index.value, 1);
          isDeleting.value = false;
          isDelete.value = false;
        
      }
   
    }


    return {
      disciplinas,
      totalDisciplinas,
      getDisciplinas,
      currentPage,
      perPageOptions,
      perPage,
      searchQuery,
      isDelete,
      index,
      subject_id,
      isDeleting,
      search,
      isdeleteSubject,
      deleteSubject,
      closeModal
    }
  },
  created() {
    this.getDisciplinas()
  },
};
</script>
