<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Credenciais</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Professor</li>
          <li class="breadcrumb-item active">Credenciais</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-xxl-8 col-lg-8">
      <div class="card">
        <div class="card-body">
             <div class="pt-3">
              <div class="settings-form">
                <h4 class="text-primary">Actualizar a Senha</h4>
                <form>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label>Senha Actual</label>
                      <input
                        type="password"
                        placeholder="Senha Actual"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label>Nova Senha</label>
                      <input
                        type="password"
                        placeholder="Nova Senha"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Confirmar Senha</label>
                    <input
                      type="password"
                      placeholder="Confirmar Senha"
                      class="form-control"
                    />
                  </div>
                  
              
              
                  <button class="btn btn-primary float-right" type="submit">Actualizar</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 </template>

<script>
export default {};
</script>
