<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Jassa</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Detalhes das Turma</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="sendData">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.name"
                  />
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Capacidade</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.capacity"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione o Curso</label>
                  <select class="form-control" v-model="formData.course_id">
                    <option :value="c.id" v-for="c in courses" :key="c.id">
                      {{ c.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nivel Superior*</label>
                  <select class="form-control" v-model="formData.level_id">
                    <option value="">Selecione o nivel</option>
                    <option
                      :value="l.id"
                      v-for="(l, index) in levels"
                      :key="index"
                    >
                      {{ l.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Ano Academico*</label>
                  <select
                    class="form-control"
                    v-model="formData.academic_year_id"
                  >
                    <option value="">Selecione o ano academico</option>
                    <option
                      :value="a.id"
                      v-for="(a, index) in academic_year"
                      :key="index"
                    >
                      {{ a.designation }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Ano de Entrada*</label>
                  <select class="form-control" v-model="formData.entry_year">
                    <option value="">Ano de Frequencia*</option>
                    <option :value="y" v-for="y in years" :key="y">
                      {{ y }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Turno*</label>
                  <select class="form-control" v-model="formData.period">
                    <option value="">Ano de Frequencia*</option>
                    <option value="Manhã">Manhã</option>
                    <option value="Tarde">Tarde</option>
                    <option value="Noturno">Noturno</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecionar semestre</label>
                  <select
                    v-model="formData.semester_id"
                    class="form-control custom-select"
                  >
                    <option v-for="s in semesters" :key="s.id" :value="s.id">
                      {{ s.semester_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descrição da Turma</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="formData.description"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <Button
                  :loading="loading"
                  type="success"
                  class="float-right"
                  @click="sendData"
                >
                  Adicionar
                </Button>
              </div>
            </div>
          </form>
          <!-- <form @submit.prevent="sendData">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.name"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Capacidade </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.capacity"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Departamentos</label>
                  <select class="form-control" v-model="formData.department_id">
                    <option
                      :value="dept.id"
                      v-for="dept in departmentData"
                      :key="dept.id"
                    >
                      {{ dept.dep_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label class="form-label">Descriçao da Turma</label>
                    <textarea
                    class="form-control"
                    rows="5"
                    v-model="formData.description"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <button type="submit" class="btn btn-primary float-right" v-if="loading == false">Actualizar</button>
                <button type="button" class="btn btn-primary float-right" v-else>Actualizando...</button>
               </div>
            </div>
          </form> -->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, onUnmounted, onMounted } from "vue";
import { mapGetters } from "vuex";
import ClassMateStoreModule from "./ClassMateStoreModule";
import store from "@/store";
import toastr from "toastr";
import { useRoute } from "vue-router";
import StudentModuleService from "../students/StudentModuleService";
import CourseAndSubjectModule from "../CourseAndSubject/CourseAndSubjectModule";
import SubjectStoreModule from "../subject/SubjectStoreModule";

const TURMA_MODULE_NAME = "Turma";

if (!store.hasModule(TURMA_MODULE_NAME)) {
  store.registerModule(TURMA_MODULE_NAME, ClassMateStoreModule);
}

onUnmounted(() => {
  if (store.hasModule(TURMA_MODULE_NAME)) {
    store.unregisterModule(TURMA_MODULE_NAME);
  }
});

const formData = ref({
  name: "",
  capacity: "",
  description: "",
  semester_id: null,
  academic_year_id: "",
  level_id: "",
});
const loading = ref(false);
const departmentData = ref([]);
const idTurma = ref();
const route = useRoute();
const academic_year = ref([]);
const levels = ref([]);
const courses = ref([]);
const semesters = ref([]);

async function sendData() {
  try {
    loading.value = true;
    if (
      formData.name == "" ||
      formData.description == "" ||
      formData.department_id == "" ||
      formData.capacity == ""
    ) {
      toastr.warning("Por favor preencha os campos obrigatorios");
      loading.value = false;

      return;
    }

    const resp = await ClassMateStoreModule.actions.updateTurma({
      id: route.params.id_class,
      data: formData.value,
    });

    if (resp.data.status == true) {
      toastr.success(resp.data.message);
    } else {
      toastr.error(resp.data.message);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}
const years = ref([]);

function getYearTilCurrent() {
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year >= 1900; year--) {
    years.value.push(year);
  }
}

async function viewClassMates() {
  const resp = await ClassMateStoreModule.actions.viewClassMates(
    route.params.id_class
  );
  formData.value = resp.data[0];
  console.log(resp.data);
}

async function getLevels() {
  const resp = await StudentModuleService.actions.getLevels();
  levels.value = resp.data;
}

async function getCourses() {
  const resp = await CourseAndSubjectModule.actions.getCourses();
  courses.value = resp.data;
  console.log(resp.data);
}

async function getAcademicYear() {
  const resp = await ClassMateStoreModule.actions.getAcademicYear();
  academic_year.value = resp.data.data;
  console.log(resp);
}
function clearFields() {
  formData.name = "";
  formData.description = "";
}

async function getSemester() {
  const resp = await SubjectStoreModule.actions.getSemester();

  semesters.value = resp.data;
  console.log("semetres", resp.data);
}
function backHistory() {
  history.back();
}

onMounted(() => {
  getYearTilCurrent();
  getLevels();
  getCourses();
  getAcademicYear();
  viewClassMates();
  getSemester();
});
</script>
  