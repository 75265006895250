import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";


// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getAllInscriptions() {
        try {
            const resp = await axios.get(URL_API+'inscriptions', {headers:authHeader()});
            return resp;
        } catch (error) {
            return error;
        }
    },

    async getPendentsInscriptions(){

      try {
        const resp = await axios.get(URL_API+'inscriptions?tipo_inscricao=pendentes', {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getPayedInscriptions(){

      try {
        const resp = await axios.get(URL_API+'inscriptions?tipo_inscricao=pagas', {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getCandidateInscriptions(){
 
      try { 
        const resp = await axios.get(URL_API+'inscriptions?tipo_inscricao=candidaturas', {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
      }

    },

    async getConcludedInscriptions(){
 
      try { 
        const resp = await axios.get(URL_API+'inscriptions?tipo_inscricao=renovacoes-concluidas', {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
      }

    } ,


    async getRenewPendentsInscriptions(){
 
      try { 
        const resp = await axios.get(URL_API+'inscriptions?tipo_inscricao=renovacoes-pendentes', {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
      }

    } 
  }


}
