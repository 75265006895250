<template>
  <div id="estudantes">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashboard</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Inicio</a></li>
            <li class="breadcrumb-item">Pedidos</li>
            <li class="breadcrumb-item active">Lista Pedidos</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="row tab-content">
          <div id="list-view" class="tab-pane fade active show col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table id="example3" class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Para Empresa</th>
                        <th>Curso</th>
                        <th>Assunto</th>
                        <th>Número de Vagas</th>
                        <th>Descrição</th>
                        <th>Enviado por</th>
                        <th>Email</th>
                        <th>Data de Envio</th>
                        <th>Acções</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(r, index) in requestTrain" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ r.company_name }}</td>
                        <td>{{ r.course_name }}</td>
                        <td>Pedido de vagas de Estagio</td>
                        <td>{{ r.number_of_train }}</td>
                        <td>
                          {{ r.description }}
                        </td>
                        <td>
                          {{ r.fullName }}
                        </td>
                        <td v-if="r.teacher_email == null">
                          --------------------
                        </td>
                        <td v-else>
                          {{ r.teacher_email }}
                        </td>
                        <td>
                          {{ r.date }}
                        </td>
                        <td>
                          <select
                            class="form-control"
                            style="border-radius: 16px"
                            @change="handleEventsStudent($event, index, r.id)"
                          >
                            <option value="0">Selecione</option>
                            <option value="response">Responder</option>
                          </select>
                        </td>
                      </tr>
                      <tr v-if="requestTrain.length == 0">
                        <td
                          colspan="10"
                          class="text-center"
                          style="font-style: italic"
                        >
                          Ainda não tem registros
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <Page :total="course.total" :model-value="course.current_page"  :page-size="parseInt(course.per_page)" @on-change="getAllCourse" v-if="course.total"   class="float-right" /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isClose" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Resposta do Pedido de Vaga</h5>
          <button
            type="button"
            @click="closeModal"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Assunto</label>
                  <input
                    type="text"
                    class="form-control"
                    value="Resposta do Pedido de Vaga"
                    disabled
                  />
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Numero de Vagas disponveis</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="requestForm.number_of_train_available"
                    placeholder="Opcional"
                  />
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome do Professor*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="requestForm.fullName"
                    disabled
                  />
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Email*</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="requestForm.email"
                    disabled
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descricao*</label>
                  <textarea
                    type="text"
                    rows="5"
                    class="form-control"
                    v-model="requestForm.message"
                    placeholder="Descricao"
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <Button
            @click="closeModal"
            type="error"
            class="btn btn-danger"
            data-dismiss="modal"
          >
            Não
          </Button>
          <Button
            type="info"
            class="btn btn-primary float-right"
            @click="handleSubmit"
            :loading="loading"
          >
            Enviar
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import toastr from "toastr";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import jobStoreModule from "./jobStoreModule";

const requestTrain = ref([]);
const router = useRouter();
const isClose = ref(false);
const loading = ref(false);

const requestForm = ref({
  request_id: "",
  fullName: "",
  email: "",
  number_of_train_available: null,
  user_id: null,
  message: null,
});

function closeModal() {
  isClose.value = false;
}

async function getAllRequestTrain() {
  try {
    loading.value = true;

    const resp = await jobStoreModule.actions.getAllRequestTrain();
    requestTrain.value = resp.data.data;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function handleSubmit() {
  try {
    loading.value = true;
    const resp = await jobStoreModule.actions.responseTrain(requestForm.value);

    if (resp.data.status == true) {
      toastr.success(resp.data.message);

      requestForm.value.message = "";
      requestForm.value.fullName = "";
      requestForm.value.email = "";
      requestForm.value.number_of_train_available = "";
      requestForm.value.request_id = "";
      requestForm.value.user_id = "";
      isClose.value = false
    }
    console.log(resp.data.message);

    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function handleEventsStudent(event, index, company_id) {
  switch (event.target.value) {
    case "response":
      event.target.value = 0;

      console.log(requestTrain.value[index]);
      requestForm.value.fullName = requestTrain.value[index].fullName;
      requestForm.value.email = requestTrain.value[index].teacher_email;
      requestForm.value.request_id = requestTrain.value[index].id;
      requestForm.value.user_id = requestTrain.value[index].user_id;

      isClose.value = true;
      break;

    default:
      console.log("opcao nao encontrada");
      break;
  }
}

onMounted(() => {
  getAllRequestTrain();
});
</script>
