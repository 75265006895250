import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { MainLayout } from "@/layouts";
import MyHome from "@/components/MyHome.vue";
import MyDepartment from "@/pages/department/MyDepartment.vue";
import AllDepartments from "@/pages/department/AllDepartments.vue";
import MyCourses from "@/pages/Courses/MyCourses.vue";
import AllCourses from "@/pages/Courses/AllCourses.vue";
import MyLogin from "@/pages/login/MyLogin.vue";
import MyInscriptions from "@/pages/inscriptions/MyInscriptions.vue";
import MyProfile from "@/pages/profile/MyProfile.vue";
import MySubject from "@/pages/subject/MySubject.vue";
import EditDepartment from "@/pages/department/EditDepartment.vue";
import EditCourse from "@/pages/Courses/EditCourse.vue";
import AllSubject from "@/pages/subject/AllSubject.vue";
import AllClassMates from "@/pages/Classmates/AllClassMates.vue";
import MyClassMates from "@/pages/Classmates/MyClassMates.vue";
import MyTeachers from "@/pages/teachers/MyTeachers.vue";
import AllTeachers from "@/pages/teachers/AllTeachers.vue";
import MyStudents from "@/pages/students/MyStudents.vue";
import AllStudents from "@/pages/students/AllStudents.vue";
import DashboardStudent from "@/pages/studentDashboard/DashboardStudent.vue";
import TeacherDashboard from "@/pages/TeacherDashboard/TeacherDashboard.vue";
import EditSubject from "@/pages/subject/EditSubject.vue";
 import editClassMates from "@/pages/Classmates/editClassMates.vue";
import permissions from "@/pages/permissions/permissions.vue";
import MyRoles from "@/pages/Roles/MyRoles.vue";
import AllRoles from "@/pages/Roles/AllRoles.vue";
import EditTeacher from "@/pages/teachers/EditTeacher.vue";
import AlocateTeacher from "@/pages/teachers/AlocateTeacher.vue";
import MyProfileT from "@/pages/TeacherDashboard/MyProfile.vue";
import UpdatePassword from "@/pages/TeacherDashboard/UpdatePassword.vue";
import MyClassMatesTeacher from "@/pages/TeacherDashboard/MyClassMatesTeacher.vue";
import ClassMatesDetails from "@/pages/TeacherDashboard/ClassMatesDetails.vue";
import MySubjects from "@/pages/TeacherDashboard/MySubjects.vue";
import SubjetctDateils from "@/pages/TeacherDashboard/SubjetctDateils.vue";
import CreateLecture from "@/pages/TeacherDashboard/ClassManagements/CreateLecture.vue";
import Employes from "@/pages/Employes/Employes.vue";
import AlocateUser from "@/pages/Employes/AlocateUser.vue";
import CreateNewAssements from "@/pages/TeacherDashboard/AssessmentsManagments/CreateNewAssements.vue";
import ShowLectures from "@/pages/TeacherDashboard/ClassManagements/ShowLectures.vue";
import AllInscriptions from "@/pages/inscriptions/AllInscriptions.vue";
import InscriptionRender from "@/pages/inscriptions/InscriptionRender.vue";
import PayedInscription from "@/pages/inscriptions/PayedInscription.vue";
import PendentInscriptions from "@/pages/inscriptions/PendentInscriptions.vue";
import Candidate from "@/pages/inscriptions/Candidate.vue";
import ConcluededRenew from "@/pages/inscriptions/ConcluededRenew.vue";
import RenewPendents from "@/pages/inscriptions/RenewPendents.vue";
import AddActivities from "@/pages/Activities/AddActivities.vue";
import ListActivities from "@/pages/Activities/ListActivities.vue";
import MyProfileStudent from "@/pages/studentDashboard/MyProfileStudent.vue";
import Company from "@/pages/Company/Company.vue";
import ListCompanies from "@/pages/Company/ListCompanies.vue";
import editCompany from "@/pages/Company/editCompany.vue"; 
import Candidates from "@/pages/companyDashboard/Candidates.vue";
import Job from "@/pages/Jobs/Job.vue";
import Myjobs from "@/pages/Jobs/Myjobs.vue"; 
import ListJobs from "@/pages/Jobs/ListJobs.vue";  
import requestTrain from "@/pages/Jobs/requestTrain.vue"; 
import ListCandidats from "@/pages/studentDashboard/ListCandidats.vue";
import DashboardCompany from "@/pages/companyDashboard/DashboardCompany.vue"; 
import MyProfileCompany from "@/pages/companyDashboard/MyProfileCompany.vue";  
import supervisors from "@/pages/Supervisors/supervisors.vue";   
import ListSupervisors from "@/pages/Supervisors/ListSupervisors.vue";    
import EditSupervisor from "@/pages/Supervisors/EditSupervisor.vue";     
import mainTrainess from "@/pages/Trainess/mainTrainess.vue";     
import ListTrainess from "@/pages/Trainess/ListTrainess.vue";     
import Notifications from "@/pages/Notifications/Notifications.vue";     
import AllNotifications from "@/pages/Notifications/AllNotifications.vue";      
import ShowResult from "@/pages/studentDashboard/ShowResult.vue";      
import EditJob from "@/pages/Jobs/EditJob.vue";       
import EditMyProfileAdmin from "@/pages/profile/EditMyProfileAdmin.vue";        
import update_password from "@/pages/recover_password/update_password.vue";  
import Recover_password from "@/pages/recover_password/Recover_password.vue"; 
import NewPassword from "@/pages/inscriptions/NewPassword.vue"; 
import ListResponseTrain from "@/pages/ResponseTrain/ListResponseTrain.vue"; 
   

const routes = [
  {
    path: "/login",
    name: "login",
    component: MyLogin,
    meta: {
      middleware: "guest",
      title: `Login`,
    },
  },

  {
    path: "/inscription",
    name: "inscription",
    component: MyInscriptions,
    meta: {
      middleware: "guest",
      title: "Inscription",
    },
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: Recover_password,
    meta: {
      middleware: "guest",
     },
  },

  {
    path: "/reset_password/:token",
    name: "recover-password",
    component: NewPassword,
    meta: {
      middleware: "guest",
     },
  },

  // {
  //   path:"/Home",
  //   name:"Home",
  //   component: DashboardStudent,
  //   meta:{
  //     title:"Student",
  //     requiresAuth:true,
  //     role:'Superadmin'
  //   }
  // },

  {
    path:"/company",
    name:"company",
    component:DashboardCompany,
    meta:{
      title:"Company",
      requiresAuth:true,
      role:"Company"
    },
    children:[
      {
        path:"/company",
        component:MyProfileCompany
      },
      {
        path: "/published",
        name: "published",
        component: ListJobs,
        meta: {
          title: "Vagas",
        },
      },

      {
        path:"/add-supervisor",
        name:"addSupervisor",
        component:supervisors,
      },

      {
        path:"/list-supervisor",
        name:"ListSupervisor",
        component:ListSupervisors,
      },

      {
        path:"/update-supervisor/:id",
        name:"updateSupervisor",
        component:EditSupervisor,
        
      },
      {
        path:"/add-new-job",
        name:"addNewJob",
        component:Job,
      },

      {
        path: "/myjobs",
        name: "myjobs",
        component: ListJobs,
        meta: {
          title: "Vagas",
        },
      },

      {
        path:'/edit-job/:id',
         component:EditJob,
         name:'edit-job',
        meta: {
          title: "Vagas",
        },
      },

      {
        path: "/candidates",
        name: "candidates",
        component: Candidates,
        meta: {
          title: "Vagas",
        },
      },
      {
        path:'/update-password',
        component:update_password
      },
      {
        path:"/AllNotificationCompany",
        name:"AllNotificationCompany",
        component:AllNotifications,
        meta:{
          title:"Notifications"
        }
      },

    ]
   
  },
  // Student Routes
  {
    path: "/Student",
    name: "Student",
    component: DashboardStudent,
    meta: {
      title: "Student",
      requiresAuth: true,
      role: "Student",
    },
    children: [
      {
        path: "/Student",
        component: MyProfileStudent,
      },
      {
        path: "/vagas-publicadas",
        meta: {
          title: "Student",
          requiresAuth: true,
          role:'Student'
        },
        component: ListJobs,
      },

      {
        path: "/candidaturas",
        meta: {
          title: "Student",
          requiresAuth: true,
          role:'Student'
        },
        component: ListCandidats,
      },

      {
        path:"/notifications/:id",
        name:"notification",
        mata:{
          title:"Notifications"
        },
        component:Notifications
      },
      {
        path:"/AllNotification",
        name:"AllNotification",
        component:AllNotifications,
        meta:{
          title:"Notifications"
        }
      },
      {
        path:"/showAssessments-result",
        name:"result",
        component:ShowResult,
        meta:{
          title:"Result"
        }
      },
      {
        path:'/updatepassword',
        component:update_password
      },
    ],
  },

  // Techaer Routes

  {
    path: "/Teacher",
    name: "Teacher",
    component: TeacherDashboard,
    meta: {
      title: "Teacher",
      requiresAuth: true,
      role: "Teacher",
    },

    children: [
      {
        path: "",
        component: MyProfileT,
        meta: {
          title: "Teacher",
          requiresAuth: true,
          role: "Teacher",
        },
      },
      {
        path: "/myClassmates",
        name: "myClassmates",
        component: MyClassMatesTeacher,
        meta: {
          title: "My ClassMates",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "classmateDateils/:id_classmate",
        name: "classmateDateils",
        component: ClassMatesDetails,
        meta: {
          title: "Detalhes da Turma",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/mySubjects",
        name: "mySubjects",
        component: MySubjects,
        meta: {
          title: "Minhas Disciplinas",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/subjetctDateils/:id_subject",
        name: "subjetctDateils",
        component: SubjetctDateils,
        meta: {
          title: "Detalhes da Disciplinas",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/create-new-lecture",
        name: "create-new-lecture",
        component: CreateLecture,
        meta: {
          title: "Create Lecture",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/update-password",
        name: "password",
        component: UpdatePassword,
        meta: {
          title: "Update Password",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/AddAssessments/:subject_id/:classmate_id",
        name: "assessment_studant",
        component: CreateNewAssements,
        meta: {
          title: "Create Lecture",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/addcompany",
        name: "companyT",
        component: Company,
        meta: {
          title: "Company",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/all-company",
        component: ListCompanies,
        meta: {
          title: "All Companies",
        },
      },

      {
        path: "/all-jobs",
        name: "AllJob",
        component: ListJobs,
        meta: {
          title: "Vagas",
        },
      },
      {
        path: "/add-student/:classmate_id",
        name: "AddStudent",
        component: MyStudents,
      },

      {
        path: "/editStudent/:id",
        name: "editStudent",
        component: () => import("../pages/students/EditStudent.vue"),
      },
      {
        path:'/update_password',
        component:update_password
      },
      {
        path:"/responseTrain",
        component:ListResponseTrain
      },
      {
        path:"/notificationTeacher/:id",
        name:"notificationTeacher",
        mata:{
          title:"Notifications"
        },
        component:Notifications
      },
      {
        path:"/AllNotificationTeacher",
        name:"AllNotificationTeacher",
        component:AllNotifications,
        meta:{
          title:"Notifications"
        }
      },
    ],
  },

  // Admin Routes

  {
    path: "/",
    component: MainLayout,
    meta: {
      title:"Dashboard",
      middleware: "auth",
      requiresAuth: true,
      role: "Superadmin",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: MyHome,
        requiresAuth: true,
        role: "Superadmin",
      },
      {
        path: "/profile",
        component: MyProfile,
      },
      {
        path: "/updateProfileAdmin",
        name:"editAdmin",
        component: EditMyProfileAdmin,
      },
      {
        path: "/add-department",
        component: MyDepartment,
      },
      {
        path: "/all-departments",
        component: AllDepartments,
      },
      {
        path: "/edit-department/:id_department",
        name: "editDepart",
        component: EditDepartment,
      },
      {
        path: "/add-course",
        component: MyCourses,
      },
      {
        path: "/all-courses",
        component: AllCourses,
      },
      {
        path: "/edit-course/:id_course",
        name: "editCourse",
        component: EditCourse,
      },

      {
        path: "/subject",
        component: MySubject,
      },
      {
        path: "/all-subject",
        component: AllSubject,
      },
      {
        path: "edit-subject/:id_subject",
        name: "editSubject",
        component: EditSubject,
      },
    
      {
        path: "/classmates",
        component: MyClassMates,
      },
      {
        path: "/edit-classMates/:id_class",
        name: "editClassMates",
        component: editClassMates,
      },
      {
        path: "/newcandidates",
        name: "newcandidates",
        component: Candidates,
        meta: {
          title: "Vagas",
        },
      },
      {
        path: "/showjobs",
        name: "showjobs",
        component: Myjobs,
        meta: {
          title: "Vagas",
        },
      },
      {
        path: "/all-classmates",
        component: AllClassMates,
      },
      {
        path: "/teachers",
        component: MyTeachers,
      },
      {
        path: "/all-teachers",
        component: AllTeachers,
      },
      {
        path: "/edit-teacher/:id_teacher",
        name: "editTeacher",
        component: EditTeacher,
      },
      {
        path: "/alocate/:id_teacher",
        name: "alocate",
        component: AlocateTeacher,
      },
      {
        path: "/students",
        name: "novo-estudante",
        component: () => import("../pages/students/MyStudents.vue"),
      },
      {
        path: "/all-students",
        name: "estudantes",
        component: () => import("../pages/students/AllStudents.vue"),
      },
      {
        path: "/updateStudent/:id",
        name: "updateStudent",
        component: () => import("../pages/students/EditStudent.vue"),
      },

      {
        path: "/add-permissions",
        component: permissions,
      },

      {
        path: "/add-roles",
        component: MyRoles,
      },
      {
        path: "/all-roles",
        component: AllRoles,
      },

      {
        path: "/give-permissions",
        name: "give-permissions",
        component: Employes,
      },
      {
        path: "/AlocateUser/:user_id",
        name: "AlocateUser",
        component: AlocateUser,
      },
      {
        path: "/add-activities",
        component: AddActivities,
        meta: {
          title: "Activities",
        },
      },
      {
        path: "/all-activities",
        component: ListActivities,
        meta: {
          title: "All Activities",
        },
      },
      {
        path: "/add-company",
        component: Company,
        meta: {
          title: "Company",
        },
      },
      {
        path: "/allcompany",
        component: ListCompanies,
        meta: {
          title: "All Companies",
        },
      },
      {
        path: "/request/:id",
        name: "editCompany",
        component: editCompany,
        meta: {
          title: "Actualizar Dados",
        },
      },
      {
        path: "/add-job",
        name: "job",
        component: Job,
        meta: {
          title: "Add Job",
        },
      },
      {
        path: "/alljobs",
        name: "AllJobs",
        component: ListJobs,
        meta: {
          title: "Vagas",
        },
      },
      {
        path:'/update_credetials',
        component:update_password
      },
      {
        path:"/requestTrain",
        component:requestTrain
      },
      {
        path: "/all-inscriprions",
        name: "Inscriptions",
        component: InscriptionRender,
        // component:AllInscriptions,

        children: [
          {
            path: "",
            component: AllInscriptions,
          },
          {
            path: "/payedInscriptions",
            component: PayedInscription,
          },

          {
            path: "/pendentsInscriptions",
            component: PendentInscriptions,
          },
          {
            path: "/Candidate",
            component: Candidate,
          },
          {
            path: "/concludedRenew",
            component: ConcluededRenew,
          },
          {
            path: "/RenewPendents",
            component: RenewPendents,
          },
        ],
      },

      {
        path:"/newsupervisor",
        component:supervisors,
        meta:{
          title:"Supervisores"
        }
      },
      {
        path:"/all-supervisors",
        component:ListSupervisors,
        meta:{
          title:"Supervisores"
        }
      },
      {
        path:"/edit-supervisor/:id",
        name:"editSupervisor",
        component:EditSupervisor,
        
      },

      {
        path:"/all-trainess",
        name:"trainess",
        component:mainTrainess,
        children:[
          {
            path:"/all-trainess",
            component:ListTrainess
          }
        ]
      },

      {
        path:"/editjob/:id",
        name:"editJob",
        component:EditJob,
       
      },
      {
        path:"/notificationAdmin/:id",
        name:"notificationAdmin",
        mata:{
          title:"Notifications"
        },
        component:Notifications
      },
      {
        path:"/AllNotificationAdmin",
        name:"AllNotificationAdmin",
        component:AllNotifications,
        meta:{
          title:"Notifications"
        }
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.requiresAuth && !isAuthenticated()) {
    // redirect user to the login page
    next("/login");
  } else if (to.meta.role && !hasUserRole(to.meta.role)) {
    // reditrect user to unauthorized page
    next("/unauthorized");
  } else {
    //allow user to access user his role
    next();
  }

  // if (to.meta.middleware == "guest") {
  //   if (store.state.auth.authenticated ) {
  //     next("dashboard");
  //   }
  //   next();
  // } else {
  //   if (store.state.auth.authenticated) {
  //     next();
  //   } else {
  //     next({ name: "login" });
  //   }
  // }
});

function isAuthenticated() {
  if (store.state.auth.authenticated) {
    return true;
  } else {
    return false;
  }
  // return true;
}

function hasUserRole(role) {
  if (isAuthenticated()) {
    if (store.state.auth.user.data.role[0] == role) {
      return true;
    } else {
      return false;
    }
  }
  // console.log(store.state.auth.user.data.role[0]);
  // return true;
}
export default router;
