<template>
  <footer class="footer">
    2023 © Jcompany
  
  </footer>
</template>

<script>
export default {};
</script>
