import axios from "axios";
import authHeader from "@/Api/AuthHeader";


// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    async createTeacher(TeacherData) {
      return  await  axios.post(URL_API+"professor", TeacherData, { headers: authHeader() })
          
    },

    async fetchProvinces() {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get("/api/provinces", { headers: authHeader() })
      //     .then((response) => resolve(response))
      //     .catch((error) => reject(error));
      // });

      try {
        const response = await axios.get(URL_API+"provinces/", {
          headers: authHeader(),
        });

        return response;
      } catch (error) {
        return error;
      }
    },

    getAllCourses() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+"getCourse", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async fetchDistricts(province_id) {

      try {

        const resp = await axios.get(URL_API+'district/'+province_id, {headers:authHeader()});
        return resp; 
      } catch (error) {
        return error;
      }
      //  return new Promise((resolve, reject) => {
      //   axios
      //     .get("api/district/" + province_id, {
      //       headers: authHeader(),
      //     })
      //     .then((response) => resolve(response))
      //     .catch((error) => reject(error));
      // });
    },

    async fetchRoles() {

      try {
        const resp = await axios.get(URL_API+'roles', {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
      }
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get("api/roles", { headers: authHeader() })
      //     .then((response) => resolve(response))
      //     .catch((error) => reject(error));
      // });
    }, 

    async alocateRole(roleData, teacher_id){

      try {
        const resp = await axios.post(URL_API+'addTeacherRole',{'role':roleData, "teacher_id":teacher_id}, {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
      }
    },

    getAllTeachers() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+"professor", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async ShowTeacher(teacher_id){
      try {
        const response = await axios.get(URL_API+"professor/" + teacher_id, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    async updateTeacher(teacher_id, formData){

      console.log(formData);
      try {
        const response = await axios.put(URL_API+"professor/"+teacher_id, formData, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }

    },

    async alocateData(dataAlocate){

      try {
          const resp = await axios.post(URL_API+'alocateDataProfessor', dataAlocate, {
            headers:authHeader()
          });

          return resp;
      } catch (error) {
        return error;
      }
    }, 

    async alocateTeacherToClassmate(dataAlocate, teacher_id){

      try {
          const resp = await axios.post(URL_API+`assign-classmate-subject/${teacher_id}`, {'subject_id':dataAlocate.subject_id, 'classmate_id':dataAlocate.classmate_id}, {
            headers:authHeader()
          });

          return resp;
      } catch (error) {
        return error;
      }
    },

    async getSubjectsByTeacherId(teacher_id){
      try {
        const resp = await axios.get(URL_API+'professor/'+teacher_id, {headers:authHeader()});
        
        return resp;
      } catch (error) {
        return error;
      }
    },

    async removeTeacherFromClassmate(classmates_id, teacher_id ){
      try {
        const resp = await axios.post(URL_API+'removeTeacherFromClassmate', {'teacher_id': teacher_id, 'classmates_id':classmates_id}, {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
        
      }
    },

    async removeSubjectFromTeacher(subject_id, teacher_id){
      try {
        const resp = await axios.post(URL_API+'removeSubjectFromTeacher', {'teacher_id': teacher_id, 'subject_id':subject_id}, {headers:authHeader()});
        return resp;
      } catch (error) {
        return error;
        
      }
    },

    async deleteTeacher(person_id){

      return axios.delete(URL_API+`professor/${person_id}`, {headers:authHeader()})
    }


 
  },
};
