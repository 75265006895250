<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item">
            <router-link to="/">Inicio</router-link>
          </li>
          <li class="breadcrumb-item">Professor</li>
          <li class="breadcrumb-item active">Perfil</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8 col-lg-12 col-md-12">
      <div class="card">
        <div class="row">
          <div class="col-md-12">
            <div class="card-header">
              <h3 class="card-title">Perfil pessoal</h3>
            </div>
            <div class="card-body">
              <!-- <img src="" class="img-card img-fluid" alt="">
              <button class="btn btn-md btn-primary btn-block">carreagar foto de perfil</button> -->

              <br />
              <ul class="list-unstyled">
                <li class="mb-2">
                  <h4>{{ companyProfile.company_name }}</h4>
                  <small class="text-center">{{companyProfile.company_email   }}</small>
                </li>
                <li class="mb-2">
                  <strong>Nuit: </strong
                  ><span>{{ companyProfile.nuit  }}</span>
                </li>
                <li class="mb-2">
                  <strong>Endere&ccedil;o: </strong
                  ><span>{{ companyProfile.company_address }}</span>
                </li>
                <li class="mb-2">
                  <strong>Contactos: </strong
                  ><span>{{ companyProfile.company_contact  }}</span>
                </li>
                <li class="mb-2">
                  <strong>Contacto Alternativo: </strong
                  ><span>{{ companyProfile.company_optional_contact == null ? "--------------": companyProfile.company_optional_contact }}</span>
                </li>
              </ul>
              <button class="btn btn-warning btn-md">
                Actualizar dados do perfil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import CompanyStoreModule from "./CompanyStoreModule";

const companyProfile = ref({});

async function getMyProfile() {
  try {
    const resp = await CompanyStoreModule.actions.getProfile();
    companyProfile.value = resp.data
    console.log(resp.data);

  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  getMyProfile();
});
</script>