import axios from "axios";
import authHeader from "@/Api/AuthHeader";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createCourseDisciplina(ctx, DataSubject) {
      return new Promise((resolve, reject) => {
        axios
          .post("api/disciplinas", DataSubject, { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getSubjects() {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/getAllSubject", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getCourses() {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/getAllCourses", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },


    viewSubject(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/disciplinas/" + id, { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    updateSubject(ctx, dataSubject) {
      return new Promise((resolve, reject) => {
        axios
          .put("/api/disciplinas/" + dataSubject.id, dataSubject.data, {
            headers: authHeader(),
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    deleteSubject(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/api/disciplinas/" + id, { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
