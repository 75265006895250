<template>
  <div class="authincation h-100">
    <div class="container h-100">
      <div class="car col-lg-8 mx-auto m-2">
        <div class="row justify-content-center h-100 p-5 align-items-center">
          <div class="card col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="text-center mb-4 mt-4">
                    <img
                      src="assets/images/up.png"
                      width="180"
                      height="180"
                      alt=""
                    />
                  </div>
                  <div class="auth-form">
                    <!-- <h4 class="text-center mb-4">Acesse a sua conta</h4> -->
                    <form @submit.prevent="handleSubmitButton">
                      <div class="form-group">
                        <label class="ml-3"><strong>Email</strong></label>
                        <input
                          type="text"
                          class="form-control col-lg-11 mx-auto"
                          v-model="data.email"
                        />
                      </div>
                      <div class="form-group">
                        <label class="ml-3"><strong>Senha</strong></label>
                        <input
                          type="password"
                          class="form-control col-lg-11 mx-auto"
                          v-model="data.password"
                        />
                      </div>
                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2 col-lg-11"
                      >
                        <div class="form-group">
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              class="custom-control-label"
                              for="basic_checkbox_1"
                              >Lembrar-me</label
                            >
                          </div>
                        </div>
                        <div class="form-group">
                          <router-link to="/forgot-password"
                            >Esqueceu a senha?
                            {{ this.$store.state.userLogged }}</router-link
                          >
                        </div>
                      </div>
                      <div class="text-center">
                        <button
                          type="button mx-auto"
                          class="btn-block"
                          style="
                            background-color: #1a73e8;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                          v-on:click="handleSubmitButton"
                        >
                          {{ loading == false ? "Acessar" : "Acessando..." }}
                        </button>
                      </div>
                    </form>
                    <div class="new-account mt-3">
                      <p>
                        Ainda não fez a sua inscrição?
                        <router-link style="color: #0093dd" to="/inscription"
                          >Clique aqui</router-link
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import toastr from "toastr";
import axios from "axios";
export default {
  data() {
    return {
      data: {
        email: "estagiario@estagiario.ac.mz",
        // email: 'Gerson@gmail.com',
        password: "adm1n@1234",
        // password: 'uZU65e1O'
      },
      loading: false,
    };
  },

  methods: {
    ...mapActions({
      signIn: "auth/login",
    }),

    async handleSubmitButton() {
 
    const URL_API =  process.env.VUE_APP_API_URL  
    const VUE_APP_API_URL_SANCTUM =  process.env.VUE_APP_API_URL_SANCTUM 

      this.loading = true;
      await axios
        .get(VUE_APP_API_URL_SANCTUM+"sanctum/csrf-cookie")
        .then((response) => {
          axios
            .post(URL_API+"login", this.data)
            .then(({ data }) => {
              if (data.status == false) {
                toastr.error("Email ou senha incorrecto!");
                return;
              } else {
                localStorage.setItem("user", JSON.stringify(data));
                 this.signIn(data);
              }
            })
            .catch((response) => {
              toastr.error(
                "Erro de autenticacao verifique suas credenciais ou contacte o administrador" +
                  response.data.message
              );
            })
            .finally(() => {
              this.loading = false;
            });
        });
    },
  },
};
</script>
