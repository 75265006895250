
import axios from "axios";
import authHeader from "@/Api/AuthHeader";
// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL


export default{

    namespaced:true,
    actions:{
        getProfile(){
            return axios.get(URL_API+'company-profile', {headers:authHeader()});
        },

        addsupervisor(){

        },
        listSupervisor(){

        },
        updateSupervisor(){

        },
        getSupervisorById(){

        },

        evaluateStudentServer(dataEvaluate, student_id){
            return axios.post(URL_API+`give-trainess-evaluation/${student_id}`, dataEvaluate, {headers:authHeader()});
        },
        checkEvaluationStatus(student_id, year){
            return axios.get(URL_API+`checkEvaluationStatus/${student_id}/${year}`, {headers:authHeader()});

        },
        checkEvaluationStatusTeacherClassmate(student_id, year){
            return axios.get(URL_API+`checkEvaluationStatusTeacherClassmate/${student_id}/${year}`, {headers:authHeader()});

        } 

    }
}