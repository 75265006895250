<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashbord</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Notificações</li>
         </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="container">
    <div class="col-lg-12">
      <div class="tab-content mx-auto">
        <div id="list-view" class="tab-pane fade active show col-lg-12">
          <div
           
            class="pb-5 slimscroll-menu"
            id="remove-scroll"
          >
            <div
              class="card p-2 col-lg-8 mx-auto"
              style="background-color: #dae4ea"
              
            >
              <div class="card-title"></div>

              <div class="card-body">
                <div class="post clearfix">
                  <div class="user-block">
                    
                    <span class="username">
                      <a href="#">A empresa {{notifications.company_name}}</a>
                      <a href="#" class="float-right btn-tool"
                        ><i class="fas fa-check-double "></i
                      ></a>
                      <br>
                    </span>
                    <span class="description"
                      >Envio-te uma mensagem - {{notifications.created_at}}</span
                    >
                  </div>
                  <!-- /.user-block -->
                  <p>
                  {{notifications.description}}
                  </p>
 
                </div>
                <!-- <div v-if="notifications.description" class="card-footer pb-5">
                  <Button :disabled="notifications.accepted_trainess == '1' " @click="confirmTrainess(notifications.id)" type="success" class="float-right">Confirmar Estagio</Button>
                </div> -->
              </div>
 
 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import NotificationsStoreModule from "./NotificationsStoreModule";


const notifications=ref([]);
const BASE_URL = "http://localhost:8080/assets/images/";
const route = useRoute();
const loading = ref(false);

async function getNotificationById(){
    try {
        const resp = await NotificationsStoreModule.actions.getNotificationById(route.params.id);
        notifications.value =resp.data.data[0];
        console.log(resp.data.data[0]);
    } catch (error) {
        console.log(error);
    }finally{
        loading.value = true;
    }
}

async function confirmTrainess(id){

  try {
    const resp = await NotificationsStoreModule.actions.confirmTrainess(id);
  } catch (error) {
    console.log(error);
  }
}

onMounted(()=>{
    getNotificationById();
})
</script>