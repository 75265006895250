import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";

// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";
// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL




export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getAllStudents() {
      await axios
        .get(URL_API+"estudantes", { headers: authHeader() })
        .then((response) => {
          console.log(response);
        });
    },

    async viewEstudante(id) {
       const resp = await axios.get(URL_API+`estudantes/${id}`, { headers: authHeader() });

        return resp;
    },

    async createInscritpion(datas) {
       try {
        const resp = await axios.post(URL_API+"estudantes", datas, { headers: authHeader() });
        return resp;
      } catch (err) {
        return err;
      }
    },

    async TeacherRegisterStudent(data){
      try {
        const resp = await axios.post(URL_API+"TeacherRegisterStudent", data, { headers: authHeader() });
        return resp;
      } catch (err) {
        return err;
      }
    },

    async updateEstudante(estudante, id) {
      let errors = "";
      try {
        const resp = await axios.patch(URL_API+`estudantes/${id}`, estudante, {
          headers: authHeader(),
        });

        return resp;
        // await route.push({ name: "estudantes" });
      } catch (err) {
        console.log(err);
        // toastr.error(
        //   "Erro na insercao de dados, verifique seus dados ou contacte o administrador."
        // );
        // for (const key in err.response.data.errors) {
        //   errors = err.response.data.errors;
        // }
      }
    },

    async deleteStudent(id) {
      await axios.delete(URL_API+`estudantes/${id}`, {headers:authHeader()});
    },

    async getProvinces() {

      try {
       const resp = await axios
          .get(URL_API+"provinces");
      
          return resp;
        
      } catch (error) {
        return error;
      }
 
    },

    async ChangeProvince(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);

      await axios
        .get(URL_API+"district/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.districtData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getAllCourses() {
      try {
        const resp = await axios.get(URL_API+"getAllCourses");

        return resp;
      } catch (error) {
        return error;
      }
    },
    async getSemesters() {
      try {
        const resp = await axios.get(URL_API+"semesters", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getLevels() {
      try {
        const resp = await axios.get(URL_API+"levels", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async academicYears(){
      try {
        const resp = await axios.get(URL_API+"academic-years", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }

    },

    async getClassmate(){
      try {
        const resp = await axios.get(URL_API+"getTurmas", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }

    },
    
    async getAllStudent(){
      try {
          const resp = await axios.get(URL_API+"estudantes",{
            headers: authHeader(),
          })

          return resp.data;
      } catch (error) {
        return error;
        
      }
    },
 

  },


};
