import authHeader from "@/Api/AuthHeader";
import auth from "@/store/auth";
import axios from "axios";
// const URL_API = "https://traineesystem.jcompany.tech/Api/api/";

// const URL_API = "http://localhost:8000/api/";
const URL_API =  process.env.VUE_APP_API_URL

export default {
  namespaced: true,
  state: {},
  actions: {
    createSupervisor(data) {
      let formData = new FormData();

      formData.append("fullName", data.fullname);
      formData.append("address_location", data.address_location);
      formData.append("email", data.email);
      formData.append("gender", data.gender);
      formData.append("doc_type", data.doc_type);
      formData.append("doc_number", data.doc_number);
      formData.append("nactionality", data.nactionality);
      formData.append("birthDate", data.birthDate);
      formData.append("fathers_filliation", data.fathers_filliation);
      formData.append("mothers_filliation", data.mothers_filliation);
      formData.append("childhood_location", data.childhood_location);
      formData.append("is_deficient", data.is_deficient);
      formData.append("deficient_type", data.deficient_type);
      formData.append("contact", data.contact);
      formData.append("alternative_contact", data.alternative_contact);
      formData.append("province_id", data.province_id);
      formData.append("district_id", data.district_id);
      formData.append("doc_file", data.doc_file);
      formData.append("last_school_frequency", null);
      formData.append("last_school_frequency_year", "0000");

      return axios.post(URL_API + "supervisor", formData, {
        headers: authHeader(),
      });
    },

    getsupervisors(queryParams) {
      return axios.get(
        URL_API +
          `supervisor?page=${queryParams.page}&search=${queryParams.search}`,
        { headers: authHeader() }
      );
    },
    getsupervisorById(id) {
      return axios.get(URL_API + `supervisor/${id}`, { headers: authHeader() });
    },

    update(data, id) {
      let formData = new FormData();

      formData.append("fullName", data.fullName);
      formData.append("address_location", data.address_location);
      formData.append("email", data.email);
      formData.append("gender", data.gender);
      formData.append("doc_type", data.doc_type);
      formData.append("doc_number", data.doc_number);
      formData.append("nactionality", data.nactionality);
      formData.append("birthDate", data.birthDate);
      formData.append("fathers_filliation", data.fathers_filliation);
      formData.append("mothers_filliation", data.mothers_filliation);
      formData.append("childhood_location", data.childhood_location);
      formData.append("is_deficient", data.is_deficient);
      formData.append("deficient_type", data.deficient_type);
      formData.append("contact", data.contact);
      formData.append("alternative_contact", data.alternative_contact);
      formData.append("province_id", data.province_id);
      formData.append("district_id", data.district_id);
      formData.append("doc_file", data.doc_file);
      formData.append("last_school_frequency", null);
      formData.append("last_school_frequency_year", "0000");
      formData.append("id", id);

      return axios.post(URL_API + `updateSupervisor`, formData, {
        headers: authHeader(),
      });
    },

    deleteSupervisor(supervisor_id) {
      return axios.delete(
        URL_API + `supervisor/${supervisor_id}`,
        { headers: authHeader() }
      );
    },
    getProvinceByDistricId(district_id){

      return axios.get(URL_API+`getProvinceBydistrict_id/${district_id}`, {headers:authHeader()} );
    },

    getAllSupervisorByCompany(){
      return axios.get(URL_API+'getAllSupervisorByCompany', {headers:authHeader()});
    }
  },
};
